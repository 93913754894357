import { useFlags } from 'launchdarkly-react-client-sdk'
import { createContext, ReactNode, useContext, useMemo } from 'react'

export const ORGANIZATIONS = {
  pcoms: 'org_G2pgB4ADsuWZ2aNK',
  medgeo: 'org_OM4vswifgUpIYcu1',
  adventist: 'org_GR1VqYoVzjaaiJns',
}

type TOrganizationSettingsProviderProps = { children: ReactNode }

type TOrganizationContext = {
  physiciansTitle: string
  showPhysiciansMobile: boolean
  showPhysiciansSpeciality: boolean
  showScrubTechnician: boolean
  enableSmsToReferringPhysicians: boolean
  enableConsumablesAutopopulate: boolean
}

const OrganizationSettingsContext = createContext<
  TOrganizationContext | undefined
>(undefined)

const OrganizationSettingsProvider = ({
  children,
}: TOrganizationSettingsProviderProps) => {
  const physiciansTitle = useFlags().physiciansTitle || 'Referring Physicians'
  const showPhysiciansSpeciality = useFlags().showPhysiciansSpeciality || false
  const showPhysiciansMobile = useFlags().showPhysiciansMobile || false
  const showScrubTechnician = useFlags().showScrubTechnician || false
  const enableSmsToReferringPhysicians =
    useFlags().enableSmsToReferringPhysicians || false
  const enableConsumablesAutopopulate =
    useFlags().enableConsumablesAutopopulate || false

  const value = useMemo(
    () => ({
      showPhysiciansSpeciality,
      physiciansTitle,
      showPhysiciansMobile,
      showScrubTechnician,
      enableSmsToReferringPhysicians,
      enableConsumablesAutopopulate,
    }),
    [
      physiciansTitle,
      showPhysiciansSpeciality,
      showPhysiciansMobile,
      showScrubTechnician,
      enableSmsToReferringPhysicians,
      enableConsumablesAutopopulate,
    ]
  )

  return (
    <OrganizationSettingsContext.Provider value={value}>
      {children}
    </OrganizationSettingsContext.Provider>
  )
}

const useOrganizationSettings = () => {
  const context = useContext(OrganizationSettingsContext)

  if (context === undefined)
    throw new Error(
      'useOrganizationSettings must be used within OrganizationSettingsProvider'
    )

  return context
}

export { OrganizationSettingsProvider, useOrganizationSettings }
