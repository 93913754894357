const BellIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_102_6)">
      <path
        d="M2.36941 14.3141L7 12.0272L8.88778 6.00861C9.63196 3.14596 12.5544 1.42946 15.417 2.17364C18.2797 2.91782 19.9962 5.84022 19.252 8.70287L18 15L20.9359 19.1431C21.2238 19.9416 20.4959 20.7401 19.6703 20.5228L2.7931 16.1338C1.96744 15.922 1.72301 14.8682 2.36398 14.3087L2.36941 14.3141Z"
        fill="#5035ED"
      />
      <path
        d="M8.00618 18L13.9379 19.5427C13.3078 20.8681 11.8086 21.6014 10.3365 21.2212C8.86443 20.8409 7.91384 19.4666 8.00618 18V18Z"
        fill="#5035ED"
      />
      <path
        d="M5.1338 3.80196C5.7288 3.15803 6.46838 2.69578 7.27237 2.44125L7.31523 3.52635C6.78231 3.73893 6.29574 4.07045 5.89242 4.50693C4.83021 5.65649 4.54634 7.32495 5.16755 8.7682C5.24994 8.95649 5.21075 9.17665 5.07498 9.32358C4.95518 9.45323 4.7827 9.51418 4.60661 9.48365C4.43468 9.45297 4.28873 9.34214 4.2198 9.17831C3.43397 7.36264 3.78991 5.25204 5.12964 3.80212L5.1338 3.80196Z"
        fill="#5035ED"
      />
      <path
        d="M2.68276 1.69263C3.57185 0.730428 4.67698 0.0396869 5.87838 -0.340643L5.94241 1.28079C5.14609 1.59845 4.41901 2.09382 3.81634 2.74606C2.22911 4.46381 1.80493 6.95695 2.73319 9.11356C2.8563 9.39492 2.79774 9.72389 2.59486 9.94345C2.41585 10.1372 2.15812 10.2283 1.89499 10.1826C1.63808 10.1368 1.41999 9.97119 1.31699 9.72637C0.142747 7.01326 0.674614 3.85944 2.67655 1.69287L2.68276 1.69263Z"
        fill="#5035ED"
      />
    </g>
    <defs>
      <clipPath id="clip0_102_6">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="matrix(-1 0 0 1 24 0)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default BellIcon
