import {
  CircularProgress,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'

import SPDCortexScanner from '../SPDCortexScanner/SPDCortexScanner'
import ConfirmSPDWorkflowModal from '../../../../components/organisms/ConfirmSPDWorkflowModal/ConfirmSPDWorkflowModal'

import { useSPDScan } from './Scan.context'
import { useSPD } from '../SPD.context'

import './Scan.scss'
import { ChangeEvent } from 'react'
import { CaptureTrayDialogProps } from 'components/organisms/CaptureTrayDialog/CaptureTrayDailog.types'
import useScanLogic from './Scan.logic'

const Scan = ({ onNext }: CaptureTrayDialogProps) => {
  const {
    scannedCode,
    setScannedCode,
    lookupQuery,
    manualCode,
    setManualCode,
  } = useSPDScan()
  const { newSubTrayImg, subTrayDetails } = useSPD()
  useScanLogic({ onNext })

  const { confirmDurationModalOpen, setConfirmDurationModalOpen } = useSPD()

  return (
    <div className="spd-scan">
      <div className="mode-banner scan-mode">
        <Typography variant="body1">
          <strong>Scan Instructions:</strong> Scan product UDIs or enter them manually to load
          items into the tray.
        </Typography>
      </div>

      <div className="content-container">
        <div className="reference-section">
          <Typography variant="h6" className="section-title">
            Sub-Tray Image
          </Typography>
          <div className="tray-image">
            {newSubTrayImg || subTrayDetails?.trayImg ? (
              <img
                src={newSubTrayImg || subTrayDetails?.trayImg}
                alt="Sub-tray reference"
              />
            ) : (
              <Skeleton
                variant="rounded"
                width="100%"
                height="100%"
                animation="wave"
              />
            )}
          </div>
        </div>

        <div className="scanner-section">
          <Typography variant="h6" className="section-title">
            Scanner
          </Typography>
          <div className="scanner">
            <SPDCortexScanner
              canScan={true}
              onScan={(code) => {
                setScannedCode(code)
              }}
            />

            <Skeleton
              className="skeleton"
              variant="rounded"
              width="100%"
              height="100%"
              animation="wave"
            />

            {(scannedCode.length > 0 || manualCode.length > 0) &&
              lookupQuery.loading && (
                <div className="scanned-code_wrapper">
                  <span className="scanned-text_wrapper">
                    <CircularProgress size="0.8em" className="spinner" />
                    <p className="scanned-text">Searching for product</p>
                  </span>
                  <p className="scanned-code">{scannedCode || manualCode}</p>
                </div>
              )}
          </div>

          <div className="input-section">
            <Typography variant="subtitle2" color="textSecondary">
              Manual Entry
            </Typography>
            <TextField
              fullWidth
              autoComplete="off"
              placeholder="Enter Product UDI manually"
              variant="outlined"
              type="text"
              className="manual-input"
              value={manualCode}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setManualCode(e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ mr: 1 }}
                  >
                    UDI:
                  </Typography>
                ),
              }}
            />
          </div>
        </div>
      </div>

      <ConfirmSPDWorkflowModal
        open={confirmDurationModalOpen}
        onClose={() => {
          setConfirmDurationModalOpen(false)
        }}
      />
    </div>
  )
}

export default Scan
