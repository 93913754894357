import { genFormValidationAlertMsg } from 'lib/utils/genFormValidationAlertMsg'
import { z } from 'zod'

export const sendForRepApprovalValidationSchema = (
  data: Record<string, any>
) => {
  const validateSchema = z
    .record(z.string(), z.any())
    .refine(
      () =>
        data.numberOfExpiredHardware !== undefined &&
        data.numberOfExpiredHardware === '0',
      {
        message: genFormValidationAlertMsg({
          path: 'numberOfExpiredHardware',
          context: { numberOfExpiredHardware: data.numberOfExpiredHardware },
        }),
        path: ['numberOfExpiredHardware'],
      }
    )

  return validateSchema.safeParse(data)
}
