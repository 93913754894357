import { AssetData, Surgery } from 'common/types'

import { genFormValidationAlertMsg } from 'lib/utils/genFormValidationAlertMsg'
import { z } from 'zod'

export type SubmitToEMRProps = {
  surgery: Surgery
  isGroupedAssetsEmpty: boolean
  dtmHardwareAssetData: AssetData[]
}

export const submitToEmrValidationSchema = (
  data: Record<string, any>,
  orgId?: string
) => {
  const validateSchema = z
    .record(z.string(), z.any())
    .refine(
      // best for known key values
      () => data.numberOfExpired === '0' || data.numberOfExpired === undefined,
      {
        message: genFormValidationAlertMsg({
          path: 'numberOfExpired',
          context: { numberOfExpired: data.numberOfExpired },
        }),
        path: ['numberOfExpired'],
      }
    )
    .refine(
      () => data.undocumentedScansCount === '0', // should trigger when undocumentedScansCount is not 0
      {
        message: genFormValidationAlertMsg({
          path: 'remainingUndocumentedScans',
        }),
        path: ['remainingUndocumentedScans'],
      }
    )
    .refine(
      () => data.submitToEmrConfirm === 'true', // should always trigger
      {
        message: genFormValidationAlertMsg({
          orgId,
          path: 'submitToEmrConfirm',
        }),
        path: ['submitToEmrConfirm'],
      }
    )

  return validateSchema.safeParse(data)
}
