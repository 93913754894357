export const TRAY_TYPES = {
  STRYKER_UPPER_FACE: 'stryker upper face',
  STRYKER_MIDDLE_FACE: 'stryker middle face',
  ZIMMER_CURVED_RODS: 'zimmer curved rods',
  STRYKER_SCREW_CADDY: 'stryker screw caddy',
  ZIMMER_ADJUSTABLE: 'zimmer adjustable transverse connector'
} as const

export const DEFAULT_DISPLAY_WIDTH = 1200

export const BASE_CIRCLE_SIZES = {
  screw: { size: 20, font: 12 },
  plate: { size: 130, font: 14 },
  drill: { size: 130, font: 14 },
} as const 