import { Box, Typography, Grid } from '@mui/material'
import { AssetData } from 'common/types'
import StoredAsset from 'components/organisms/StoredAsset/StoredAsset'
import { GroupedAssetData } from 'lib/utils/grouped-asset-data'

export enum AssetTypeOptions {
  HARDWARE = 'Implantable Hardware/Associated Product',
  BIOLOGIC = 'Implantable Biologic',
  OTHER = 'Implantable Other',
  CONSUMABLE = 'Consumables',
  EXPLANTED = 'Explanted',
}

type NestedObjectPaths = {
  repScans?: AssetData[]
  nurseScans?: AssetData[]
  approved?: AssetData[]
}

interface TotalAssetCountPanelProps {
  groupedAssetData: GroupedAssetData
  procedureStatus: boolean
  surgeryId: string
  label: AssetTypeOptions
  assetTypeOption: keyof GroupedAssetData
  scanType: keyof NestedObjectPaths
  totalCount: number
  totalLabel: string
  printMode?: boolean
}

export const TotalAssetCountPanel = ({
  groupedAssetData,
  procedureStatus,
  surgeryId,
  label,
  assetTypeOption,
  scanType,
  totalCount,
  totalLabel,
  printMode,
}: TotalAssetCountPanelProps) => {
  const scan = groupedAssetData[
    assetTypeOption as keyof GroupedAssetData
  ] as any

  if (scan[scanType as keyof NestedObjectPaths].length > 0) {
    return (
      <Box>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            my: 1.75,
            pb: 1,
            borderBottom: '2px solid #eee',
          }}
        >
          <Typography
            variant="h2"
            sx={{ mr: 1 }}
            data-testid="asset-type-header"
          >
            {label}
          </Typography>
          <Typography variant="h3" data-testid="asset-type-header-count">
            {`${totalLabel} Total${
              label === 'Consumables' ? ` Used` : ''
            }: ${totalCount}`}
          </Typography>
        </Grid>

        <Box my={3.5}>
          {scan[scanType as keyof NestedObjectPaths]?.map(
            (asset: AssetData) => (
              <StoredAsset
                printMode={printMode}
                key={asset._id}
                isProcedureSubmitted={procedureStatus}
                surgeryId={surgeryId}
                assetData={asset}
                groupedAssetData={groupedAssetData}
              />
            )
          )}
        </Box>
      </Box>
    )
  } else {
    return null
  }
}
