import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Typography, Button } from '@mui/material'
import { CameraAlt } from '@mui/icons-material'

import { useAssignedDigitalTrays } from '../AssignedDigitalTrays.context'

import { TrayComponentProps, TrayData } from './TrayComponent.types'
import TrayIcon from 'assets/icons/svgs/Tray.svg'
import './TrayComponent.scss'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import { SubItem } from 'common/types'

const TrayComponent = ({
  trayName,
  trayID,
  status,
  assetCount,
  contents,
  vendor,
  procedureID,
  products,
  trayData: tray,
  subTrayData,
  details,
}: TrayComponentProps) => {
  const navigate = useNavigate()
  const { setSelectedTray, setSelectedSubTray } = useAssignedDigitalTrays()

  const [viewDetails, setViewDetails] = useState<boolean>(false)
  const [viewPreviousTrayMap, setViewPreviousTrayMap] = useState<boolean>(false)
  const [isPreviousTrayLoading, setIsPreviousTrayLoading] = useState(true)

  const trayProductDetails = extractTrayJSONData(subTrayData)
  const subItemProductDetails = extractSubTrayJSONData({
    ...subTrayData,
    description: subTrayData.description || '',
  } as SubItem)

  const trayData: TrayData[] = [
    {
      fieldName: 'Type',
      data: subItemProductDetails?.trayType || 'Unknown',
    },
    { fieldName: 'Status', data: status },
    {
      fieldName: 'Total Product Count',
      data:
        (subItemProductDetails?.screws?.length || 0) +
        (subItemProductDetails?.plates?.length || 0),
    },
    { fieldName: 'Content', data: contents },
    { fieldName: 'Vendor', data: vendor },
  ]

  const handleViewDetailsClick = () => {
    setViewPreviousTrayMap(false)
    setViewDetails((prevValue) => !prevValue)
  }

  const handleViewPreviousTrayMapClick = () => {
    setViewPreviousTrayMap((prevValue) => !prevValue)
  }

  const handlePreviousTrayLoading = () => {
    setIsPreviousTrayLoading(false)
  }

  const handleSelectManuallyClick = () => {
    setTimeout(() => navigate('../tray-visualization'), 0)
  }

  const handleCaptureClick = () => {
    navigate(`../capture-tray/${trayID}`, {
      state: { trayId: tray.id, subTrayId: subTrayData.id },
    })
  }

  const handleSelectCurrentTray = () => {
    const updatedProductDetails = JSON.stringify({
      ...subItemProductDetails,
      screws: subItemProductDetails?.screws || [],
      plates: subItemProductDetails?.plates || [],
    })

    setSelectedSubTray({
      ...subTrayData,
      description: subTrayData.description || '',
      productDetails: updatedProductDetails,
    } as SubItem)
    setSelectedTray(tray)
  }

  const currentRoute = window.location.pathname
  const lastSlashIndex = currentRoute.lastIndexOf('/')
  const updatedRoute = currentRoute.substring(0, lastSlashIndex)

  return (
    <Box className="tray-component">
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} width="100%">
        <Box sx={{ flex: 1 }}>
          <Stack className="tray-data_container">
            <img src={TrayIcon} alt="Tray" />
            <Box className="tray-data">
              <Typography className="tray-data_name">{trayName}</Typography>
              {trayData.map((dataField: TrayData) => (
                <Typography
                  key={dataField.data}
                  variant="body2"
                  data-field={dataField.fieldName}
                  sx={{
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-line',
                    width: '100%',
                  }}
                >
                  {dataField.fieldName}:{' '}
                  <span className="field-value">{dataField.data}</span>
                </Typography>
              ))}
            </Box>
          </Stack>
        </Box>

        <Stack spacing={1} className="button-container">
          <Button
            variant="outlined"
            onClick={() => {
              handleSelectCurrentTray()
              handleSelectManuallyClick()
            }}
            fullWidth
          >
            Select Manually
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              handleSelectCurrentTray()
              navigate(`${updatedRoute}/capture-tray/${tray.id}`)
            }}
            startIcon={<CameraAlt />}
            fullWidth
          >
            Capture
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default TrayComponent
