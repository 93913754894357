import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import { TextField, Box, Switch, Alert } from '@mui/material'
import { CompanySearchBar } from '../../CompanySearchBar'
import { FC } from 'react'
import {
  Title,
  Header,
  Subtitle,
  StyledAlert,
  FormContainer,
  MainContainer,
  AlertContainer,
  StyledTypography,
  StyledAlertContainer,
} from './repSignupForm.styles'
import { TRepSignUpFormProps } from './repSignupForm.types'
import { useRepSignupFormLogic } from './repSignupForm.logic'

const RepSignupForm: FC<TRepSignUpFormProps> = (props) => {
  const { surgeryId } = props
  const {
    formData,
    formErrors,
    canAddAsset,
    showSuccessContainer,
    productRepInviteMutationError,
    productRepInviteMutationIsLoading,
    handleSubmit,
    updateFormData,
    setCanAddAsset,
    handleMobileInputChange,
    closeSuccessContainer,
    handleSetCompanyData,
  } = useRepSignupFormLogic(props)

  return (
    <MainContainer>
      {showSuccessContainer ? (
        <StyledAlertContainer>
          <StyledAlert severity="success" variant="outlined">
            <AlertContainer>
              <StyledTypography>
                {`Rep Invite sent successfully to ${
                  formData.email || "the rep's email"
                } you may go back to your dashboard`}
              </StyledTypography>
              <LoadingButton mode="default" onClick={closeSuccessContainer}>
                Back To Dashboard
              </LoadingButton>
            </AlertContainer>
          </StyledAlert>
        </StyledAlertContainer>
      ) : (
        <>
          <Header>
            <Title>Invite Sales Rep</Title>

            <Subtitle>
              Enter the sales rep's email address and select the company that
              they work for.
            </Subtitle>
          </Header>

          <FormContainer onSubmit={handleSubmit} noValidate autoComplete="off">
            <TextField
              id="email"
              label="Email Address"
              name="email"
              inputMode="text"
              value={formData.email}
              onChange={(e) =>
                updateFormData(e.target.name, e.target.value.toLowerCase())
              }
              disabled={productRepInviteMutationIsLoading}
              error={!!formErrors?.email}
              helperText={formErrors?.email}
              required
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
            />

            <TextField
              id="emailValidation"
              label="Confirm Email Address"
              name="emailValidation"
              inputMode="text"
              value={formData.emailValidation}
              onChange={(e) =>
                updateFormData(e.target.name, e.target.value.toLowerCase())
              }
              disabled={productRepInviteMutationIsLoading}
              error={!!formErrors?.emailValidation}
              helperText={formErrors?.emailValidation}
              required
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
            />

            <CompanySearchBar
              mandatory
              handleChangeFormData={handleSetCompanyData}
              manualAssetInputFormData={formData}
              variant="outlined"
              disabled={productRepInviteMutationIsLoading}
              required={false}
              error={!!formErrors?.companyName}
              helperText={formErrors?.companyName}
            />

            <TextField
              id="firstName"
              label="First Name"
              name="firstName"
              inputMode="text"
              value={formData.firstName}
              onChange={(e) => updateFormData(e.target.name, e.target.value)}
              disabled={productRepInviteMutationIsLoading}
              error={!!formErrors?.firstName}
              helperText={formErrors?.firstName}
              required
            />

            <TextField
              id="lastName"
              label="Last Name"
              name="lastName"
              inputMode="text"
              value={formData.lastName}
              onChange={(e) => updateFormData(e.target.name, e.target.value)}
              disabled={productRepInviteMutationIsLoading}
              error={!!formErrors?.lastName}
              helperText={formErrors?.lastName}
              required
            />

            <TextField
              id="mobile"
              label="Mobile"
              name="mobile"
              inputMode="text"
              value={formData.mobile}
              onChange={handleMobileInputChange}
              disabled={productRepInviteMutationIsLoading}
              error={!!formErrors?.mobile}
              helperText={formErrors?.mobile}
              required
            />

            {surgeryId ? (
              <Box
                sx={{
                  mx: 'auto',
                  backgroundColor: '#ebf3fb',
                  border: '1px solid #e0e0e0',
                  borderRadius: '4px',
                  padding: '0.3em 1em',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                Allow sales rep to add assets?
                <Switch
                  checked={canAddAsset}
                  onChange={() => setCanAddAsset(!canAddAsset)}
                  disabled={productRepInviteMutationIsLoading}
                />
              </Box>
            ) : null}

            {productRepInviteMutationError && (
              <Alert severity="error" variant="filled" sx={{ mb: 3 }}>
                {productRepInviteMutationError instanceof Error
                  ? productRepInviteMutationError.message
                  : 'Failed to send invite'}
              </Alert>
            )}

            <LoadingButton
              sx={{ height: '50px' }}
              type="submit"
              mode="default"
              loadingText="Sending invite..."
              loading={productRepInviteMutationIsLoading}
            >
              Sign Up
            </LoadingButton>
          </FormContainer>
        </>
      )}
    </MainContainer>
  )
}

export default RepSignupForm
