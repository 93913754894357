// Types
import { Buttons, MainIconLandingProps } from './MainIconLanding.types'

// Icons
import Implantable from 'assets/icons/svgs/Implantable.svg'
import PreferenceCardIcon from 'assets/icons/svgs/prefCardInactive_new.svg'
import ConsumableProducts from 'assets/icons/svgs/ConsumableProducts.svg'
import BiologicIcon from 'assets/icons/svgs/ImplantableBiological.svg'
import SalesRepIcon from 'assets/icons/svgs/SalesRepIcon.svg'
import TrayIcon from 'assets/icons/svgs/Tray.svg'
import ImplantableOtherIcon from 'assets/icons/svgs/ImplantableOther.svg'

// Other
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useIsMobile } from 'lib/utils/mediaQueries'
import { useUser } from 'app/User'
import { useAssetType } from 'lib/context/AssetTypeContext/AssetTypeContext'
import { useEffect, useState } from 'react'
import { useOrganizationSettings } from 'lib/context/OrganizationSettingsContext'
import { useUpdateSurgeryAutopopulateMutation } from 'lib/apollo/hooks'

export const useMainIconLanding = ({ surgery }: MainIconLandingProps) => {
  const flags = useFlags()
  const { user } = useUser()
  const isMobile = useIsMobile()
  const isRepUser = user?.roles?.includes('MGAT_REP')
  const { handleChangeAssetType } = useAssetType()

  const [twoColumnsClass, setTwoColumnsClass] = useState<string>('')

  const { enableConsumablesAutopopulate } = useOrganizationSettings()
  const [showConsumablesAutopopulate, setShowConsumablesAutopopulate] =
    useState(false)

  const [updateSurgeryAutopopulateSelection, { loading }] =
    useUpdateSurgeryAutopopulateMutation()

  const repButtons: Buttons = {
    hardware: {
      IconComponent: Implantable,
      label: 'Implantable Hardware / Associated Product',
      dataTestId: 'implantable-hardware-button',
    },
    implantableOther: {
      IconComponent: ImplantableOtherIcon,
      label: 'Implantable Other',
      dataTestId: 'implantable-other-button',
    },
  }

  const nurseButtons: Buttons = {
    hardware: {
      IconComponent: Implantable,
      label: 'Implantable Hardware / Associated Product',
      dataTestId: 'implantable-hardware-button',
    },
    implantableOther: {
      IconComponent: ImplantableOtherIcon,
      label: 'Implantable Other',
      dataTestId: 'implantable-other-button',
    },
    biologic: {
      IconComponent: BiologicIcon,
      label: 'Implantable Biologic',
      dataTestId: 'implantable-biologic-button',
    },
    consumables: {
      IconComponent: ConsumableProducts,
      label: 'Consumable Product',
      dataTestId: 'consumable-product-button',
    },
    trays: {
      IconComponent: TrayIcon,
      label: 'Trays',
      dataTestId: 'tray-button',
      isVisible: flags.assetTypeTraysButton,
    },
    preferenceCard: {
      IconComponent: PreferenceCardIcon,
      label: 'Preference Card',
      dataTestId: 'preference-card-button',
      isVisible: flags.assetTypePreferenceCardButton,
    },
    salesRep: {
      IconComponent: SalesRepIcon,
      label: 'Add Sales Rep',
      dataTestId: 'add-sales-rep-button',
      isVisible: flags.assetTypeAddSalesRepButton,
    },
  }

  const icons = isRepUser ? repButtons : nurseButtons

  const visibleIconsNumber =
    Object.keys(icons).length -
    Object.values(icons).filter((icon) => icon.isVisible === false).length

  useEffect(() => {
    switch (visibleIconsNumber) {
      case 4:
        setTwoColumnsClass('two-columns')
        break
      default:
        setTwoColumnsClass('')
        break
    }
  }, [visibleIconsNumber])

  useEffect(() => {
    if (
      enableConsumablesAutopopulate &&
      surgery.hasPreferenceCardConsumables &&
      !surgery.isAutopopulateConsumableOptionSelected
    ) {
      setShowConsumablesAutopopulate(true)
    }
  }, [
    enableConsumablesAutopopulate,
    surgery.hasPreferenceCardConsumables,
    surgery.isAutopopulateConsumableOptionSelected,
  ])

  const populateConsumables = (autoPopulateConsumables: boolean) => {
    updateSurgeryAutopopulateSelection({
      variables: {
        input: {
          surgeryId: surgery._id,
          autoPopulateConsumables,
        },
      },
    }).then((response) => {
      if (response?.data?.updateSurgeryAutopopulate.success) {
        setShowConsumablesAutopopulate(false)
      }
    })
  }

  return {
    icons,
    loading,
    isMobile,
    twoColumnsClass,
    showConsumablesAutopopulate,
    populateConsumables,
    handleChangeAssetType,
    setShowConsumablesAutopopulate,
  }
}
