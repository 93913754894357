import { Link } from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material'
import { ArrowBack, ExpandMore } from '@mui/icons-material'

import TrayComponent from './TrayComponent/TrayComponent'
import useAssignedDigitalTraysLogic from './AssignedDigitalTrays.logic'
import { ItemResponse, Surgery } from 'common/types'
import SelectTrayIDModal from 'components/organisms/SelectTrayIDModal/SelectTrayIDModal'
import InventoryProvider from 'lib/apollo/inventory-config'
import './AssignedDigitalTrays.scss'

interface AssignedDigitalTraysProps {
  surgery: Surgery | undefined
}

const AssignedDigitalTrays = ({ surgery }: AssignedDigitalTraysProps) => {
  const {
    error,
    loading,
    modalKey,
    surgeryId,
    filteredTrays,
    selectTrayOpen,
    isAssigningTray,
    setSelectTrayOpen,
    handleAssignDigitalTray,
  } = useAssignedDigitalTraysLogic({ surgery })

  return (
    <div className="assigned-digital-trays-container">
      <Button
        className="header-button"
        startIcon={<ArrowBack />}
        variant="text"
        size="small"
        to=".."
        component={Link}
      >
        BACK
      </Button>

      <div className="header-content">
        <Typography variant="h2">Assigned Digital Trays</Typography>
        <Button variant="contained" onClick={() => setSelectTrayOpen(true)}>
          Assign trays
        </Button>
      </div>

      <div className="trays-content">
        {loading && <CircularProgress />}
        {error && <Typography color="error">{error.message}</Typography>}
        {filteredTrays.length === 0 && !loading && (
          <Typography>No trays assigned to this surgery</Typography>
        )}

        {filteredTrays.map((tray: ItemResponse) => (
          <Accordion key={tray.id} defaultExpanded className="tray-accordion">
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className="accordion-summary"
            >
              <div className="summary-content">
                <Stack className="tray-info">
                  <Typography className="tray-title">
                    {tray.description}
                  </Typography>
                  <Typography className="tray-barcode">
                    {tray.barcode}
                  </Typography>
                </Stack>
                <Typography className="tray-count">
                  {tray.subItems?.length || 0}
                </Typography>
              </div>
            </AccordionSummary>

            <AccordionDetails className="accordion-details">
              {(tray.subItems as ItemResponse[])?.map(
                (subTray: ItemResponse) => (
                  <TrayComponent
                    key={subTray.id}
                    trayName={subTray.description as string}
                    trayID={subTray.barcode as string}
                    status="Loaded from SPD"
                    assetCount={
                      JSON.parse(subTray.productDetails as string)?.screws
                        ?.length ?? 0
                    }
                    contents="Screws"
                    vendor={subTray.companyName as string}
                    procedureID={surgeryId as string}
                    products={
                      JSON.parse(subTray.productDetails as string)?.screws
                    }
                    details={JSON.parse(subTray.productDetails as string)}
                    trayData={tray}
                    subTrayData={subTray}
                  />
                )
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>

      <InventoryProvider>
        <SelectTrayIDModal
          key={modalKey}
          open={selectTrayOpen}
          onClose={() => setSelectTrayOpen(false)}
          isAssigningFromSurgery
          handleAssignDigitalTray={handleAssignDigitalTray}
          isAssigningTray={isAssigningTray}
        />
      </InventoryProvider>
    </div>
  )
}

export default AssignedDigitalTrays
