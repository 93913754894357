import { InputBase, Paper } from '@mui/material'
import { Search } from '@mui/icons-material'

interface SearchBarProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const SearchBar = ({ value, onChange, placeholder }: SearchBarProps) => {
  return (
    <Paper className="search-bar">
      <Search className="search-icon" />
      <InputBase
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
      />
    </Paper>
  )
}

export default SearchBar
