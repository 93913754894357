import React, { ChangeEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useAuth0 } from 'app/Auth'
import MedGEOlogo from 'assets/MedGEOlogo.svg'
import PageDisplay from 'components/PageDisplay/PageDisplay'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box/Box'
import styles from './login.module.css'
import Typography from '@mui/material/Typography/Typography'
import { LocationState } from 'common/types'
import StatusIndicator from 'views/Status/StatusIndicator'
import { Grid } from '@mui/material'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { getSubdomainName } from 'lib/utils/getSubdomainName'

function Login() {
  const { loginWithRedirect, isSessionExpired, orgDomains } = useAuth0()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoggedout, setIsLoggedOut] = useState<boolean>(false)
  const location = useLocation()
  const locationState = location.state as LocationState
  const queryParams = searchParams.get('status')

  useEffect(() => {
    if (
      locationState &&
      locationState.from &&
      locationState.from.pathname &&
      locationState.from.pathname.startsWith('/invite/')
    ) {
      localStorage.setItem('inviteUrl', `${locationState.from.pathname}`)
      loginWithRedirect({
        appState: {
          returnTo: `${window.location.origin}/home/`,
        },
      })
    }
  }, [locationState, loginWithRedirect])

  useEffect(() => {
    if (queryParams === 'loggedout') {
      setIsLoggedOut(true)
      searchParams.delete('status')
      setSearchParams(searchParams, { replace: true })
    } else if (searchParams.get('email')) {
      handleLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState, queryParams, searchParams, setSearchParams])

  const handleLogin = () => {
    if (locationState?.from.pathname) {
      loginWithRedirect({
        appState: {
          returnTo: `${window.location.origin}${locationState.from.pathname}`,
        },
      })
    } else {
      loginWithRedirect()
    }
  }

  let defaultSubdomain
  const envOrgDomains: string[] = []
  if (process.env.REACT_APP_ENV === 'production') {
    defaultSubdomain = 'at'
    for (const subdomain of orgDomains) {
      if (!subdomain.endsWith('-s') && !subdomain.endsWith('-qa')) {
        envOrgDomains.push(subdomain)
      }
    }
  } else if (process.env.REACT_APP_ENV === 'qa') {
    defaultSubdomain = 'at-qa'
    for (const subdomain of orgDomains) {
      if (subdomain.endsWith('-qa')) {
        envOrgDomains.push(subdomain)
      }
    }
  } else {
    defaultSubdomain = 'at-s'
    for (const subdomain of orgDomains) {
      if (subdomain.endsWith('-s')) {
        envOrgDomains.push(subdomain)
      }
    }
  }

  // subdomain selection
  const [domainState, setDomainState] = useState<string>('')
  const { inDevelopment, subdomainName } = getSubdomainName()
  const renderDomainMenu = subdomainName === defaultSubdomain || !subdomainName

  const handleDomainSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDomainState(event.target.value)
  }

  React.useMemo(() => {
    if (inDevelopment && domainState !== '') {
      window.location.href = `${window.location.origin}?subdomain=${domainState}`
    } else if (!inDevelopment && domainState !== '') {
      window.location.href = `https://${domainState}.medgeo.com`
    }
  }, [domainState, inDevelopment])

  return (
    <Grid container direction="column">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Grid item>
        <Box className={styles.root} sx={{ backgroundColor: 'primary.main' }}>
          <PageDisplay>
            {isSessionExpired && (
              <Alert variant="filled" severity="success" color="success">
                Your session has expired, please login.
              </Alert>
            )}
            {isLoggedout && (
              <Alert variant="filled" severity="success" color="success">
                You have been logged out!
              </Alert>
            )}
            <img src={MedGEOlogo} alt="MedGEO logo" className={styles.logo} />
            <Typography
              variant="h1"
              className={styles.typography}
              sx={{ mt: 6, mx: 'auto' }}
            >
              Welcome to the MedGEO Platform!
            </Typography>
            <Typography
              className={styles.typography}
              sx={{ my: 1, mx: 'auto' }}
            >
              Please click to login and enter your credentials
            </Typography>
            {renderDomainMenu && (
              <AutocompleteInput
                id="subdomain"
                handleChangeFormData={(e) => {
                  handleDomainSelect(e as ChangeEvent<HTMLInputElement>)
                }}
                value={subdomainName}
                placeholder="Choose domain"
                name="subdomain"
                options={envOrgDomains}
                required
                variant="outlined"
                sx={{
                  mt: 5,
                  color: 'primary.main',
                  backgroundColor: 'grayscale.lightest',
                  borderRadius: '5px',
                }}
                disableClearable={true}
              />
            )}

            <Button
              data-testid="login-button"
              variant="contained"
              disableElevation
              onClick={handleLogin}
              sx={{
                my: 5,
                color: 'primary.main',
                backgroundColor: 'grayscale.lightest',
                '&:hover': {
                  backgroundColor: 'primary.hover',
                },
              }}
            >
              Log in
            </Button>
          </PageDisplay>
        </Box>
      </Grid>
      <Grid item sx={{ backgroundColor: 'primary.main' }}>
        <StatusIndicator />
      </Grid>
    </Grid>
  )
}

export default Login
