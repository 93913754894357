import { Typography } from '@mui/material'
import { Warning } from '@mui/icons-material'
import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'

import './ConfirmNavigationModal.scss'

interface ConfirmNavigationModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const ConfirmNavigationModal = ({
  open,
  onClose,
  onConfirm,
}: ConfirmNavigationModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="confirm-navigation-modal">
        <div className="header-box">
          <Warning />
          <Typography variant="h3">Unsaved Changes</Typography>
        </div>

        <Typography variant="body1" className="description">
          You have unsaved changes. Are you sure you want to leave this page?
        </Typography>

        <div className="button-container">
          <LoadingButton variant="outlined" onClick={onClose}>
            Cancel
          </LoadingButton>
          <LoadingButton onClick={onConfirm}>Leave Page</LoadingButton>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmNavigationModal
