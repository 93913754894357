import { useEffect, useState } from 'react'

// Services
import useGetLocationsTree from 'lib/services/api/product-service/getLocationsTree'
import useCreateLocation from 'lib/services/api/locations-service/createLocation'
import useUpdateLocationsTree from 'lib/services/api/locations-service/updateLocationsTree'

// Types
import {
  AddLocationModal,
  InventoryLocation,
  MapName,
} from './LocationManagement.types'
import { CreateLocationParams } from 'lib/services/api/locations-service/createLocation/types'
import { DropResult } from '@hello-pangea/dnd'

// Other
import { uniqueId } from 'lodash'
import { enqueueSnackbar } from 'notistack'

const useLogic = () => {
  const [updateLocationsTree, { loading: isLoadingUpdateLocations }] =
    useUpdateLocationsTree()
  const [createLocation, { loading: isLoadingCreateLocations }] =
    useCreateLocation()

  const { data, refetch, loading: isLoadingLocations } = useGetLocationsTree()
  const [selectedLocations, setSelectedLocations] = useState<number[]>([])
  const [isDragging, setIsDragging] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [locations, setLocations] = useState<InventoryLocation[]>([])
  const [addLocationModalOpen, setAddLocationModalOpen] =
    useState<AddLocationModal>()
  const [locationModalId, setLocationModalId] = useState<string>(uniqueId())

  const mapName: MapName = {
    0: 'Departments',
    1: 'Rooms',
    2: 'Locations',
    3: 'Shelves',
  }

  const handleLocationClick = (columnIndex: number, locationId: number) => {
    setSelectedLocations((prev) => {
      const newSelectedLocations = [...prev].splice(0, columnIndex)
      newSelectedLocations[columnIndex] = locationId
      return newSelectedLocations
    })
  }

  const getMaxDepth = (
    locations: InventoryLocation[],
    parentId: number | null,
    currentDepth: number = 1
  ): number => {
    if (currentDepth >= 4) {
      return currentDepth
    }

    let maxDepth = currentDepth

    locations.forEach((location) => {
      if (location.parentLocationId === parentId) {
        const depth = getMaxDepth(locations, location.id, currentDepth + 1)
        if (depth > maxDepth) {
          maxDepth = depth
        }
      }
    })
    return maxDepth
  }

  const handleCloseAddLocation = () => {
    setAddLocationModalOpen({ open: false })
  }

  const handleAddLocationClick = (
    locationName: string,
    parent: InventoryLocation
  ) => {
    const parentName =
      locationName === 'Shelves' ? 'Shelf' : locationName.slice(0, -1)
    setAddLocationModalOpen({ open: true, locationName: parentName, parent })
  }

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result
    setIsDragging(false)

    const destinationId = destination?.droppableId

    // Prevents adding a location to departments
    if (destinationId === '0') return

    const parentIndex = selectedLocations[Number(destinationId) - 1]
    const draggedItem = locations.find(
      (location) => location.id === Number(draggableId)
    )
    if (!destination) return
    if (!draggedItem) return
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return
    if (selectedLocations.includes(draggedItem.id)) return

    const updatedDraggedItem: InventoryLocation = {
      ...draggedItem,
      parentLocationId: parentIndex,
    }
    setLocations((prev) => [
      ...prev.filter((location) => location.id !== draggedItem.id),
      updatedDraggedItem,
    ])
  }

  const handleDragStart = () => {
    setIsEditing(true)
    setIsDragging(true)
  }

  const handleCreateLocation = (params: CreateLocationParams) => {
    createLocation({
      variables: {
        ...params,
        order: (locations[locations.length - 1]?.order || 0) + 1,
      },
    })
      .then((res) => {
        enqueueSnackbar(
          res.data?.createHospitalLocation.message ||
            'New location created successfully',
          {
            variant: 'success',
          }
        )
      })
      .catch((error) => {
        if (process.env.REACT_APP_NODE_ENV !== 'production') {
          console.warn(error)
        }
      })
      .finally(() => {
        setAddLocationModalOpen({ open: false })
        setLocationModalId(uniqueId())
        refetch()
      })
  }

  const handleUpdateLocationsTree = () => {
    updateLocationsTree({
      variables: {
        name: data?.getLocationsTree.hospital.name as string,
        updateLocationsTreeId: data?.getLocationsTree.hospital.id as number,
        locations: locations.map((location) => ({
          id: location.id,
          name: location.name,
          order: location.order,
          parentLocationId: location.parentLocationId,
          description: location.description,
        })),
      },
    })
      .then((res) => {
        enqueueSnackbar(
          res.data?.updateLocationsTree.message || 'Locations updated',
          {
            variant: 'success',
          }
        )
      })
      .catch((error) => {
        if (process.env.REACT_APP_NODE_ENV !== 'production') {
          console.warn(error)
        }
      })
      .finally(() => {
        setIsEditing(false)
        refetch()
      })
  }

  const handleCancelEditLocations = () => {
    setLocations(
      data?.getLocationsTree.hospital.locations as InventoryLocation[]
    )
    setIsEditing(false)
  }

  useEffect(() => {
    if (data) {
      setLocations(data.getLocationsTree.hospital.locations)
    }
  }, [data])

  return {
    maxDepth: getMaxDepth(locations, null),
    selectedLocations,
    locations,
    mapName,
    addLocationModalOpen,
    isDragging,
    isLoadingLocations,
    isLoadingCreateLocations,
    isEditing,
    isLoadingUpdateLocations,
    locationModalId,
    handleCancelEditLocations,
    handleUpdateLocationsTree,
    handleDragStart,
    setIsDragging,
    handleAddLocationClick,
    handleCloseAddLocation,
    handleLocationClick,
    onDragEnd,
    handleCreateLocation,
  }
}

export default useLogic
