import { useState, RefObject } from 'react'
import { DEFAULT_DISPLAY_WIDTH } from '../constants'

export const useScaling = () => {
  const [originalWidth, setOriginalWidth] = useState<number | null>(null)
  const [originalHeight, setOriginalHeight] = useState<number | null>(null)
  const [imageScale, setImageScale] = useState(1)
  const [uiScale, setUiScale] = useState(1)
  const [textScale, setTextScale] = useState(1)

  const calculateDynamicScale = (containerRef: RefObject<HTMLDivElement>) => {
    if (!containerRef.current || !originalWidth) return 1
    const containerWidth = containerRef.current.offsetWidth
    return containerWidth / originalWidth
  }

  const updateScales = (containerWidth: number) => {
    if (!originalWidth) return

    const newImageScale = containerWidth / originalWidth
    const newUiScale = containerWidth / DEFAULT_DISPLAY_WIDTH
    
    // Calculate text scale with a minimum size
    const baseTextScale = Math.max(newUiScale, 0.8)
    const newTextScale = Math.min(baseTextScale * 1.2, 1.5)

    setImageScale(newImageScale)
    setUiScale(newUiScale)
    setTextScale(newTextScale)
  }

  return {
    originalWidth,
    originalHeight,
    imageScale,
    uiScale,
    textScale,
    calculateDynamicScale,
    setImageScale,
    setUiScale,
    setOriginalWidth,
    setOriginalHeight,
    updateScales
  }
} 