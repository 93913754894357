import axios from 'axios'

const axiosInstanceGudId = axios.create({
  baseURL: process.env.REACT_APP_GUDID_URL,
  timeout: 8000,
})

const axiosInstanceIservices = axios.create({
  baseURL: process.env.REACT_APP_ISERVICES_BASE_URL,
  timeout: 8000,
  headers: {
    'Content-Type': 'application/json',
  },
})

export { axiosInstanceGudId, axiosInstanceIservices }
