import { useAuth0 } from 'app/Auth'
import axios from 'axios'
import { useMutation } from 'react-query'

export interface AdmitNewPatientParams {
  firstName: string
  lastName: string
  mrn: string
  dateOfBirth: string
  gender: string
}

export interface AdminNewPatientResponse {
  _id: string
  idMR: string
  firstName: string
  lastName: string
  gender: string
  dateOfBirth: string
  orgId: string
  createdAt: string
  updatedAt: string
  source: string
}

export const useAdminNewPatient = () => {
  const { getAccessToken } = useAuth0()
  const REPORTS_URL = process.env.REACT_APP_REPORTS_URL

  const mutation = useMutation<
    AdminNewPatientResponse,
    Error,
    AdmitNewPatientParams
  >(async (data: AdmitNewPatientParams) => {
    const token = await getAccessToken()
    try {
      const response = await axios.post(`${REPORTS_URL}api/v1/patients`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  })

  return mutation
}
