import { useOrganizationSettings } from 'lib/context/OrganizationSettingsContext'

export const ANALYTICS = 'analytics'
export const SURGERIES = 'surgeries'
export const USAGE_REPORTS = 'usage-reports'
export const ASSET_REPORTS = 'product-reports'

// DTM
export const DTM = 'dtm-options'
export const DTM_RELOADING = 'dtm-tray-reloading'

// Inventory and receiving
export const RECEIVE = 'receive'
export const SCAN = (id: string) => `scan/${id}`
export const PRODUCT_REQUESTS = 'product-requests'
export const INVENTORY_REPORT = 'inventory-report'

// Location Management
export const LOCATION_MANAGEMENT = 'location-management'
export const REFERRING_PHYSICIANS = 'referring-physicians'
export const SCRUB_TECHNICIANS = 'scrub-technicians'
export const LABOR_COST = 'labor-cost'

// Tray Management
export const TRAY_MANAGEMENT = 'tray-management'
export const ADD_TRAY = 'tray-management/add-tray'

export const getTitleMap = (
  organizationSettings: ReturnType<typeof useOrganizationSettings>
) => ({
  [SURGERIES]: 'Platform',
  [ANALYTICS]: 'Analytics',
  [USAGE_REPORTS]: 'Usage Reports',
  [ASSET_REPORTS]: 'Product Reports',
  [DTM]: 'DTM Options',
  [DTM_RELOADING]: 'DTM Tray Reloading',
  [RECEIVE]: 'Receive',
  [PRODUCT_REQUESTS]: 'Product Requests',
  [INVENTORY_REPORT]: 'Inventory Report',
  [LOCATION_MANAGEMENT]: 'Location Management',
  [REFERRING_PHYSICIANS]: organizationSettings.physiciansTitle,
  [SCRUB_TECHNICIANS]: 'Scrub Technicians',
  [TRAY_MANAGEMENT]: 'Tray Management',
  [ADD_TRAY]: 'Add Tray',
  [LABOR_COST]: 'Labor Cost',
})
