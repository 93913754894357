import { Route, Routes } from 'react-router-dom'
import { Alert, Snackbar } from '@mui/material'
import { Helmet } from 'react-helmet'

import TrayManagement from './TrayManagement/TrayManagement'
import FloatingBar from 'components/FloatingBar/FloatingBar'
import TrayDetails from './TrayDetails/TrayDetails'
import EditTray from './EditTray/EditTray'

import useTrayManagementLayoutLogic from './TrayManagementLayout.logic'
import { AssignedDigitalTraysProvider } from 'views/DigitalTrayMapping/AssignedDigitalTrays/AssignedDigitalTrays.context'
import { CaptureTrayContextProvider } from 'views/DigitalTrayMapping/CaptureTray/CaptureTray.context'

import './TrayManagementLayout.scss'
import AddTray from './AddTray/AddTray'
import { TrayConfigurationProvider } from 'views/TrayConfiguration/TrayConfiguration.context'
import { InventorySheetsProvider } from './AddTray/InventorySheets/InventorySheets.context'
import { AddTrayProvider } from './AddTray/AddTray.context'
import TrayFiles from './TrayFiles/TrayFiles'
import { TrayStepProvider } from './AddTray/AddTray.logic'
import { SPDCortexScannerProvider } from 'views/SPDLayout/SPD/SPDCortexScanner/SPDCortexScanner.context'
import { SPDProvider } from 'views/SPDLayout/SPD/SPD.context'
import { SPDScanProvider } from 'views/SPDLayout/SPD/Scan/Scan.context'

const TrayManagementLayout = () => {
  const {
    snackbarOpen,
    snackbarIcon,
    snackbarMessage,
    snackbarState,
    handleSnackbarClose,
  } = useTrayManagementLayoutLogic()

  return (
    <div className="tray-management-layout_wrapper">
      <Helmet>
        <title>Tray Management</title>
      </Helmet>

      <FloatingBar />

      <Routes>
        <Route
          path="/"
          element={
            <SPDProvider>
              <SPDScanProvider>
                <AssignedDigitalTraysProvider>
                  <CaptureTrayContextProvider>
                    <AddTrayProvider>
                      <TrayManagement />
                    </AddTrayProvider>
                  </CaptureTrayContextProvider>
                </AssignedDigitalTraysProvider>
              </SPDScanProvider>
            </SPDProvider>
          }
        />
        <Route
          path="/add-tray/*"
          element={
            <SPDProvider>
              <SPDScanProvider>
                <SPDCortexScannerProvider>
                  <TrayStepProvider>
                    <TrayConfigurationProvider>
                      <AssignedDigitalTraysProvider>
                        <CaptureTrayContextProvider>
                          <AddTrayProvider>
                            <InventorySheetsProvider>
                              <AddTray />
                            </InventorySheetsProvider>
                          </AddTrayProvider>
                        </CaptureTrayContextProvider>
                      </AssignedDigitalTraysProvider>
                    </TrayConfigurationProvider>
                  </TrayStepProvider>
                </SPDCortexScannerProvider>
              </SPDScanProvider>
            </SPDProvider>
          }
        />
        <Route
          path="/:trayId"
          element={
            <SPDProvider>
              <SPDScanProvider>
                <SPDCortexScannerProvider>
                  <TrayDetails />
                </SPDCortexScannerProvider>
              </SPDScanProvider>
            </SPDProvider>
          }
        />
        <Route
          path="/:trayId/tray-files"
          element={
            <SPDProvider>
              <SPDScanProvider>
                <SPDCortexScannerProvider>
                  <TrayFiles />
                </SPDCortexScannerProvider>
              </SPDScanProvider>
            </SPDProvider>
          }
        />
        <Route
          path="/:trayId/:subTrayItemId"
          element={
            <SPDProvider>
              <SPDScanProvider>
                <SPDCortexScannerProvider>
                  <EditTray isReviewing={false} />
                </SPDCortexScannerProvider>
              </SPDScanProvider>
            </SPDProvider>
          }
        />
      </Routes>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarState}
          sx={{ width: '100%' }}
          icon={snackbarIcon}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default TrayManagementLayout
