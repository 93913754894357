import { Alert, IconButton, Snackbar, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import { ConfirmLoadMultipleScrewsModal } from 'components/organisms/ConfirmLoadMultipleScrewsModal/ConfirmLoadMultipleScrewsModal'
import { ChooseTrayLocationModal } from 'components/organisms/ChooseTrayLocationModal/ChooseTrayLocationModal'
import SelectedTrayModal from '../../../components/organisms/SelectedTrayModal/SelectedTrayModal'
import EditTrayModal from 'components/organisms/EditTrayModal/EditTrayModal'
import SelectTray from './SelectTray/SelectTray'
import Scan from './Scan/Scan'
import InventoryProvider from 'lib/apollo/inventory-config'
import { useSPDHook } from './SPD.logic'
import { useSPD } from './SPD.context'
import { ItemResponse } from 'common/types'
import './SPD.scss'
import { useSPDScan } from './Scan/Scan.context'
import CaptureTrayDialog from 'components/organisms/CaptureTrayDialog/CaptureTrayDialog'
import LoadSPDTrayMap from './LoadSPDTrayMap/LoadSPDTrayMap'
import { LoadMultipleScrewsBox } from 'components/organisms/LoadMultipleScrewsBox/LoadMultipleScrewsBox'
import CloseIcon from '@mui/icons-material/Close'
import AlertDialog from 'components/AlertDialog/AlertDialog'

const SPD = () => {
  const {
    navbarHeight,
    trayData,
    showTrayDetails,
    setShowTrayDetails,
    showSurgeryChangeModal,
    setShowSurgeryChangeModal,
    showChooseTrayLocationModal,
    setShowChooseTrayLocationModal,
    snackbarOpen,
    snackbarState,
    snackbarMessage,
    snackbarIcon,
    handleSnackbarClose,
    trayDetails,
    onlyUseManualWorkflow,
    activeStep,
  } = useSPD()
  const {
    handleNextStep,
    steps,
    handleStepClick,
    handleDiscardAndExit,
    isDiscardAlertOpen,
    handleDiscardConfirm,
    handleDiscardCancel,
  } = useSPDHook()
  const { isAdditionalDeviceCountModalOpen, isLoadMultipleBoxVisible } =
    useSPDScan()

  return (
    <div
      style={{ minHeight: `calc(100vh - ${navbarHeight}px)` }}
      className="spd-container_wrapper"
    >
      <IconButton
        size="large"
        color="primary"
        onClick={handleDiscardAndExit}
        className="discard-exit-button"
      >
        <CloseIcon />
      </IconButton>

      <LoadMultipleScrewsBox open={isLoadMultipleBoxVisible} />
      <Helmet>
        <title>SPD</title>
      </Helmet>
      <Typography variant="h1" mt="20px">
        {onlyUseManualWorkflow
          ? 'Manual Tray Configuration'
          : 'Automated Workflow'}
      </Typography>
      <div className="step-layout_header">
        <Stepper activeStep={activeStep} className="stepper">
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={index < activeStep}
              onClick={() => handleStepClick(index)}
            >
              <StepLabel
                classes={{
                  root: 'step-root',
                  label: 'step-label',
                  labelContainer: 'step-label-container',
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      {trayDetails ? (
        <div className="stepper_content">
          {onlyUseManualWorkflow ? (
            <LoadSPDTrayMap trayDetails={trayDetails} onNext={handleNextStep} />
          ) : (
            <>
              {activeStep === 0 && <Scan onNext={handleNextStep} />}
              {activeStep === 1 && (
                <InventoryProvider>
                  <CaptureTrayDialog onNext={handleNextStep} />
                </InventoryProvider>
              )}
              {activeStep === 2 && (
                <LoadSPDTrayMap
                  trayDetails={trayDetails}
                  onNext={handleNextStep}
                />
              )}
            </>
          )}
        </div>
      ) : (
        <SelectTray />
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarState}
          sx={{ width: '100%' }}
          icon={snackbarIcon}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <AlertDialog
        isOpen={isDiscardAlertOpen}
        mode="warning"
        title="Discard Changes"
        description="Exiting will discard all your current progress. Are you sure that you want to exit?"
        secondaryButtonText="Cancel"
        secondaryButtonAction={handleDiscardCancel}
        primaryButtonText="Exit"
        primaryButtonAction={handleDiscardConfirm}
        zIndex={999}
      />

      <ConfirmLoadMultipleScrewsModal
        open={isAdditionalDeviceCountModalOpen}
        onClose={() => {
          return
        }}
      />

      <InventoryProvider>
        <EditTrayModal
          open={showSurgeryChangeModal}
          onClose={() => setShowSurgeryChangeModal(false)}
          trayData={trayData?.trayItem as ItemResponse}
        />

        <ChooseTrayLocationModal
          open={showChooseTrayLocationModal}
          onClose={() => setShowChooseTrayLocationModal(false)}
        />

        <SelectedTrayModal
          open={showTrayDetails}
          onClose={() => setShowTrayDetails(false)}
        />
      </InventoryProvider>
    </div>
  )
}

export default SPD
