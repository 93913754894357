import { useCortexDecoder } from 'lib/cortex'
import { useState } from 'react'
import { useSPDCortexScannerContext } from '../SPDCortexScanner.context'

export const useSPDCortexVideo = ({
  onScan,
  isEnabled = true,
}: {
  onScan: (newScan: CortexDecoder.CDResult) => void
  isEnabled?: boolean
}) => {
  const { setSelectedCamera } = useSPDCortexScannerContext()
  const { isActive } = useCortexDecoder({ onScan, isEnabled })
  const [isCameraMenuOpen, setIsCameraMenuOpen] = useState<boolean>(false)

  const handleCameraChange = (selectedCamera: MediaDeviceInfo) => {
    setSelectedCamera(selectedCamera)
  }

  const handleToggleCameraMenu = () => {
    setIsCameraMenuOpen((prev) => !prev)
  }

  return {
    isActive,
    isCameraMenuOpen,
    handleToggleCameraMenu,
    handleCameraChange,
  }
}
