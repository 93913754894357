type Environment = 'development' | 'staging' | 'qa' | 'production' | 'pcoms'

export const CSPConfig = {
  development: `
        default-src 'self';
        script-src 'self' 'unsafe-eval' https://unpkg.com;
        style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
        img-src 'self' data: https: blob:;
        font-src 'self' https://fonts.googleapis.com https://fonts.gstatic.com;
        connect-src 'self'
            http://localhost:4001//status
            http://localhost:4001/graphql
            https://*.launchdarkly.com
            https://at-graph-s.medgeo.com/graphql
            https://api-s.medgeo.com
            https://inventory-s.medgeo.com
            https://reports-s.medgeo.com
            https://hio0vzvmzc.execute-api.us-east-1.amazonaws.com
            https://7rmvx8i1ek.execute-api.us-east-1.amazonaws.com/STAGING/api/process_image
            https://medgeo-staging.us.auth0.com
            https://o4504499096780800.ingest.sentry.io
            https://httpbin.org
            https://mgat-media-staging.s3.us-east-1.amazonaws.com
            https://dtm-analysis-results.s3.us-east-1.amazonaws.com
            https://lottie.host
            https://gudid.medgeo.com/api/v2/;
        frame-src 'self' https://medgeo-staging.us.auth0.com https://dtm-analysis-results.s3.us-east-1.amazonaws.com https://analytics-stg.medgeo.com;
        object-src 'self' data: https://dtm-analysis-results.s3.us-east-1.amazonaws.com;
    `,
  qa: `
        default-src 'self';
        script-src 'self' 'unsafe-eval' https://unpkg.com;
        style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
        img-src 'self' data: https: blob:;
        font-src 'self' https://fonts.googleapis.com https://fonts.gstatic.com;
        connect-src 'self'
            https://api-s.medgeo.com/status
            https://*.launchdarkly.com
            https://at-graph-s.medgeo.com/graphql
            https://api-s.medgeo.com
            https://inventory-s.medgeo.com
            https://reports-s.medgeo.com
            https://hio0vzvmzc.execute-api.us-east-1.amazonaws.com
            https://7rmvx8i1ek.execute-api.us-east-1.amazonaws.com/STAGING/api/process_image
            https://medgeo-staging.us.auth0.com
            https://o4504499096780800.ingest.sentry.io
            https://httpbin.org
            https://mgat-media-staging.s3.us-east-1.amazonaws.com
            https://dtm-analysis-results.s3.us-east-1.amazonaws.com
            https://lottie.host
            https://gudid.medgeo.com/api/v2/;
        frame-src 'self' https://medgeo-staging.us.auth0.com https://dtm-analysis-results.s3.us-east-1.amazonaws.com https://analytics-stg.medgeo.com;
        object-src 'self' data: https://dtm-analysis-results.s3.us-east-1.amazonaws.com;
    `,
  staging: `
        default-src 'self';
        script-src 'self' 'unsafe-eval' https://unpkg.com;
        style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
        img-src 'self' data: https: blob:;
        font-src 'self' https://fonts.googleapis.com https://fonts.gstatic.com;
        connect-src 'self'
            https://api-s.medgeo.com/status
            https://*.launchdarkly.com
            https://at-graph-s.medgeo.com/graphql
            https://api-s.medgeo.com
            https://inventory-s.medgeo.com
            https://reports-s.medgeo.com
            https://hio0vzvmzc.execute-api.us-east-1.amazonaws.com
            https://7rmvx8i1ek.execute-api.us-east-1.amazonaws.com/STAGING/api/process_image
            https://medgeo-staging.us.auth0.com
            https://o4504499096780800.ingest.sentry.io
            https://httpbin.org
            https://mgat-media-staging.s3.us-east-1.amazonaws.com
            https://dtm-analysis-results.s3.us-east-1.amazonaws.com
            https://lottie.host
            https://gudid.medgeo.com/api/v2/;
        frame-src 'self' https://medgeo-staging.us.auth0.com https://dtm-analysis-results.s3.us-east-1.amazonaws.com https://analytics-stg.medgeo.com;
        object-src 'self' data: https://dtm-analysis-results.s3.us-east-1.amazonaws.com;
    `,
  production: `
        default-src 'self';
        script-src 'self' 'unsafe-eval' https://unpkg.com;
        style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
        img-src 'self' data: https: blob:;
        font-src 'self' https://fonts.googleapis.com https://fonts.gstatic.com;
        connect-src 'self'
            https://api.medgeo.com/status
            https://*.launchdarkly.com
            https://at-graph.medgeo.com/graphql
            https://api.medgeo.com
            https://inventory.medgeo.com
            https://reports.medgeo.com
            https://hio0vzvmzc.execute-api.us-east-1.amazonaws.com
            https://hio0vzvmzc.execute-api.us-east-1.amazonaws.com/PROD/api/process_image
            https://medgeo.us.auth0.com
            https://o4504499096780800.ingest.sentry.io
            https://o4504408301436928.ingest.sentry.io
            https://httpbin.org
            https://mgat-media.s3.us-east-1.amazonaws.com
            https://dtm-analysis-results.s3.us-east-1.amazonaws.com
            https://lottie.host
            https://gudid.medgeo.com/api/v2/;
        frame-src 'self' https://medgeo-staging.us.auth0.com https://dtm-analysis-results.s3.us-east-1.amazonaws.com https://analytics.medgeo.com;
        object-src 'self' data: https://dtm-analysis-results.s3.us-east-1.amazonaws.com;
    `,
  pcoms: `
        default-src 'self';
        script-src 'self' 'unsafe-eval' https://unpkg.com;
        style-src 'self' 'unsafe-inline' https://fonts.googleapis.com;
        img-src 'self' data: https: blob:;
        font-src 'self' https://fonts.googleapis.com https://fonts.gstatic.com;
        connect-src 'self'
            https://api.medgeo.com/status
            https://*.launchdarkly.com
            https://at-graph-pcoms.medgeo.com/graphql
            https://api-pcoms.medgeo.com
            https://inventory-pcoms.medgeo.com
            https://reports-pcom.medgeo.com
            https://hio0vzvmzc.execute-api.us-east-1.amazonaws.com
            https://medgeo.us.auth0.com
            https://o4504499096780800.ingest.sentry.io
            https://o4504408301436928.ingest.sentry.io
            https://httpbin.org
            https://mgat-media.s3.us-east-1.amazonaws.com
            https://dtm-analysis-results.s3.us-east-1.amazonaws.com
            https://lottie.host
            https://gudid.medgeo.com/api/v2/;
        frame-src 'self' https://analytics.medgeo.com
            https://medgeo.us.auth0.com;
        object-src 'none';
    `,
}

export const getCSPContent = (): string => {
  const env = process.env.REACT_APP_ENV as Environment
  return CSPConfig[env]
}
