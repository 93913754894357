import { useNavigate } from 'react-router-dom'
import {
  Box,
  Modal,
  Typography,
  Button,
  IconButton,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import { Check, CopyAll } from '@mui/icons-material'
import { useCopyToClipboard } from 'react-use'
import { CurrentScanDetails } from 'lib/scan-handler'

interface AlternateScanResultModalProps {
  currentScanValue?: string
  lookupQuery: CurrentScanDetails['lookupQuery']
  results: CurrentScanDetails['results']
  isComplete: CurrentScanDetails['isComplete']
  valueType: CurrentScanDetails['type']
  onClose: () => void
  show?: boolean
}

export function AlternateScanResultModal({
  show = false,
  currentScanValue,
  results,
  lookupQuery,
  isComplete,
  valueType,
  onClose,
}: AlternateScanResultModalProps) {
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.up('sm'))
  const [isCopied, copyToClipboard] = useCopyToClipboard()
  const navigate = useNavigate()

  const noResultsFound = results?.length === 0 && lookupQuery.called
  const isURL = valueType === 'url'
  const nonUDI = valueType === 'unknown'
  const isUDI = valueType === 'udi'

  const isOpen = (isComplete && (noResultsFound || isURL)) || show

  return (
    <Modal
      open={isOpen}
      BackdropProps={{ sx: { backgroundColor: 'rgba(40, 40, 40, 0.90)' } }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      disableAutoFocus
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: 2,
          maxWidth: 600,
          width: '100%',
          p: isSm ? 2 : 4,
        }}
      >
        <Box p={2}>
          <Typography
            variant="h3"
            sx={{
              position: 'relative',
              fontSize: 20,
            }}
          >
            Capture Value
            <Typography
              fontWeight={500}
              color="primary"
              maxWidth="60ch"
              sx={{ wordWrap: 'break-word' }}
            >
              {currentScanValue}
              <Tooltip title="Copy Capture Value">
                <IconButton
                  aria-label="copy"
                  size="small"
                  onClick={() => copyToClipboard(currentScanValue ?? '')}
                  sx={{ position: 'relative', left: 2, bottom: 2 }}
                >
                  {Boolean(isCopied.value) ? <Check /> : <CopyAll />}
                </IconButton>
              </Tooltip>
            </Typography>
          </Typography>
          <Box mt={1.5}>
            <Typography color="#333">
              Character Count:{' '}
              <Typography
                component="span"
                fontWeight={500}
                display="inline-block"
              >
                {currentScanValue?.length ?? 0}
              </Typography>
            </Typography>
            {isURL && <Typography color="#333">This code is a URL</Typography>}
            {isUDI && (
              <Typography color="#333">This code looks like a UDI</Typography>
            )}
            {nonUDI && (
              <Typography color="#333">This code is not a UDI</Typography>
            )}
            {noResultsFound && (
              <Typography mt={2} fontWeight={500}>
                No results were found in the GUDID or BetterID for this capture
                value
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" mt={3} columnGap={1.5}>
            <Button onClick={onClose}>Capture Again</Button>
            {isURL ? (
              <Button
                href={currentScanValue ?? ''}
                size="small"
                variant="contained"
                color="primary"
                target="_blank"
                onClick={() => navigate('../scan')}
              >
                Visit URL
              </Button>
            ) : (
              <></>
              // TODO: MGAT-885
              // Adding a new asset from batch scanning was not navigating to the correct route.
              // Now its going to the correct route but there is a problem when we add the asset.
              // Needs further debugging.
              // <Button
              //   size="small"
              //   variant="contained"
              //   color="primary"
              //   sx={{ color: 'primary.light' }}
              //   disableElevation
              //   onClick={() => {
              //     navigate(`/surgeries/${surgeryId}/asset/manual`, {
              //       state: { scannedCode: currentScanValue },
              //     })
              //   }}
              // >
              //   Add Manually
              // </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
