import './AddTray.scss'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import TrayId from './TrayId/TrayId'
import AddTrayDetails from './AddTrayDetails/AddTrayDetails'
import SubTrays from './SubTrays/SubTrays'
import InventorySheets from './InventorySheets/InventorySheets'
import AssignStep from './AssignStep/AssignStep'
import Review from './Review/Review'
import { useUser } from 'app/User'
import AlertDialog from 'components/AlertDialog/AlertDialog'

const TrayStepContext = React.createContext<
  ReturnType<typeof useAddTrayStepper> | undefined
>(undefined)

export const useTrayStepContext = () => {
  const context = React.useContext(TrayStepContext)
  if (!context) {
    throw new Error('useTrayStepContext must be used within an AddTrayProvider')
  }
  return context
}

function useAddTrayStepper() {
  const [stepNumber, setStepNumber] = React.useState(0)
  const [highestStepReached, setHighestStepReached] = React.useState(0)
  const [isDiscardAlertOpen, setIsDiscardAlertOpen] = React.useState(false)
  const { isRep, isGuest } = useUser()
  const navigate = useNavigate()
  const stepMap = [
    {
      label: 'Tray ID',
      Component: TrayId,
    },
    {
      label: 'Tray Details',
      Component: AddTrayDetails,
    },
    {
      label: 'Sub Trays',
      Component: SubTrays,
    },
    {
      label: isRep ? 'IFU Files + Inventory Sheets' : 'Inventory Sheets',
      Component: InventorySheets,
    },
    {
      label: 'Assign',
      Component: AssignStep,
    },
    {
      label: 'Review',
      Component: Review,
    },
  ] as const

  const steps = stepMap
  const currentStep = steps[stepNumber]

  const nextStep = () => {
    const nextStepNumber = stepNumber + 1
    setStepNumber(nextStepNumber)
    setHighestStepReached(Math.max(highestStepReached, nextStepNumber))
  }

  const prevStep = () => setStepNumber(stepNumber - 1)

  const reset = () => {
    setStepNumber(0)
    setHighestStepReached(0)
  }

  const goToStep = (step: number) => {
    if (step <= highestStepReached) {
      setStepNumber(step)
    }
  }

  const handleDiscardAndExit = () => {
    setIsDiscardAlertOpen(true)
  }

  const handleDiscardConfirm = () => {
    reset()
    if (isGuest) {
      navigate('/guest-info')
    } else {
      navigate('/tray-management')
    }
    setIsDiscardAlertOpen(false)
  }

  const handleDiscardCancel = () => {
    setIsDiscardAlertOpen(false)
  }

  return {
    stepNumber,
    steps,
    currentStep,
    nextStep,
    prevStep,
    reset,
    goToStep,
    highestStepReached,
    handleDiscardAndExit,
    isDiscardAlertOpen,
    handleDiscardConfirm,
    handleDiscardCancel,
  }
}

export const TrayStepProvider: React.FC = ({ children }) => {
  const value = useAddTrayStepper()
  return (
    <TrayStepContext.Provider value={value}>
      {children}
      <AlertDialog
        isOpen={value.isDiscardAlertOpen}
        mode="warning"
        title="Discard Changes"
        description="Exiting will discard all your current progress. Are you sure that you want to exit?"
        secondaryButtonText="Cancel"
        secondaryButtonAction={value.handleDiscardCancel}
        primaryButtonText="Exit"
        primaryButtonAction={value.handleDiscardConfirm}
        zIndex={999}
      />
    </TrayStepContext.Provider>
  )
}
