import { useEffect, useState } from 'react'
import { useSPD } from '../../../views/SPDLayout/SPD/SPD.context'
import useCaptureCamera from 'views/DigitalTrayMapping/CaptureTray/CaptureCamera/CaptureCamera.logic'
import { CheckCircle, Error as ErrorIcon } from '@mui/icons-material'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'
import { useEditSubTrayItemMutation } from 'lib/apollo/hooks'
import useEditTrayModal from '../EditTrayModal/EditTrayModal.logic'
import { ItemResponse, Surgery } from 'common/types'
import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'
import { useNavigate } from 'react-router-dom'
import useTrayConfiguration from 'views/TrayConfiguration/TrayConfiguration.logic'
import { SelectChangeEvent } from '@mui/material'

interface UseConfirmTrayImageModalProps {
  onClose: () => void
  onConfirm: () => void
}

const useConfirmTrayImageModal = ({
  onClose,
  onConfirm,
}: UseConfirmTrayImageModalProps) => {
  const navigate = useNavigate()
  const { editSubTrayItem } = useEditSubTrayItemMutation()
  const { locationsWithParents, findLocation } = useTrayConfiguration()

  const {
    handleSPDCaptureImage,
    image,
    setImage,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleSelectCamera,
    handleToggleCameraMenu,
  } = useCaptureCamera()
  const {
    setSnackbarIcon,
    setSnackbarMessage,
    setSnackbarOpen,
    setSnackbarState,
    trayData,
    setTrayData,
    newSubTrayImg,
    trayScrews,
    trayPlates,
    setSelectedScrew,
    selectedLocationId,
    setSelectedLocationId,
  } = useSPD()
  const { setIsDrawerOpen, setProductData } = useSPDScan()
  const {
    handleUpdateTrayItem: handleUpdateParentTrayItem,
    updating,
    setSuccess,
  } = useEditTrayModal({
    trayData: trayData?.trayItem as ItemResponse,
    trayScrews,
  })

  const [isCapturing, setIsCapturing] = useState<boolean>(false)
  const [shouldStop, setShouldStop] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState(0)
  const [selectedSurgery, setSelectedSurgery] = useState<Surgery | null>(null)
  const [showLocationPicker, setShowLocationPicker] = useState<boolean>(false)
  const [showSurgeryPicker, setShowSurgeryPicker] = useState<boolean>(false)

  const shouldNextBeDisabled = activeStep === 0 && !image && !newSubTrayImg

  useEffect(() => {
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop())
      }
    }
  }, [mediaStream])

  const handleStopClick = () => {
    setShouldStop((prev) => !prev)
  }

  const handleReset = () => {
    setIsComplete(false)
    setImage('')
  }

  const handleCaptureClick = () => {
    handleSPDCaptureImage(true)
    setIsComplete(true)
  }

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const handleLocationChange = (e: SelectChangeEvent<number>) => {
    const locationId = Number(e.target.value)
    setTrayData((prev) =>
      prev
        ? {
            ...prev,
            trayItem: {
              ...prev?.trayItem,
              locationId,
            },
          }
        : null
    )
    setSelectedLocationId(locationId)
  }

  const handleSurgeryChange = (surgery: Surgery) => {
    if (surgery._id === selectedSurgery?._id) {
      setSelectedSurgery(null)
    } else {
      setSelectedSurgery(surgery)
    }
  }

  const handleUpdateTrayItem = async () => {
    setIsSubmitting(true)
    try {
      const prevDetails = extractSubTrayJSONData(
        trayData?.trayItem?.subItems?.[0]
      )
      const newProductDetails = JSON.stringify({
        ...prevDetails,
        trayImg: image || newSubTrayImg || prevDetails?.trayImg,
        screws: trayScrews,
        plates: trayPlates,
      })

      const editResult = await editSubTrayItem({
        id: Number(trayData?.trayItem?.subItems?.[0].id),
        productDetails: newProductDetails,
      })

      if (editResult) {
        setTrayData((prev) =>
          prev
            ? {
                ...prev,
                trayItem: {
                  ...prev.trayItem,
                  locationId: selectedLocationId || prev.trayItem.locationId,
                },
              }
            : null
        )

        const success = await handleUpdateParentTrayItem(
          selectedSurgery?._id,
          selectedSurgery
        )

        if (success) {
          setSnackbarMessage('Successfully saved changes')
          setSnackbarState('success')
          setSnackbarIcon(<CheckCircle />)
          setSnackbarOpen(true)
          setSelectedScrew(null)
          setIsDrawerOpen(false)
          setProductData([])
          handleReset()
          setIsCapturing(false)
          setSuccess(false)
          navigate(`/tray-management/${trayData?.trayItem?.barcode}`)
          onClose()
        }
      }
    } catch (error) {
      setSnackbarMessage('Error submitting image')
      setSnackbarState('error')
      setSnackbarIcon(<ErrorIcon />)
      setSnackbarOpen(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCapture = () => {
    if (image) {
      handleReset()
    } else {
      handleCaptureClick()
    }
  }

  return {
    isCapturing,
    setIsCapturing,
    shouldStop,
    isComplete,
    isSubmitting,
    handleStopClick,
    handleReset,
    handleCaptureClick,
    handleUpdateTrayItem,
    image,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleToggleCameraMenu,
    handleSelectCamera,
    handleCapture,
    activeStep,
    handleNext,
    handleBack,
    handleLocationChange,
    handleSurgeryChange,
    selectedSurgery,
    locationsWithParents,
    findLocation,
    selectedLocationId,
    updatingParentTrayItem: updating,
    shouldNextBeDisabled,
    showLocationPicker,
    setShowLocationPicker,
    showSurgeryPicker,
    setShowSurgeryPicker,
  }
}

export default useConfirmTrayImageModal
