import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { usePaginatedCompanySearch } from 'lib/apollo/hooks'
import { BetterIdCompany, ManualInputFormData, Option } from 'common/types'
import { TRepSignUpFormData } from './AddRepModal/RepSignupForm/repSignupForm.types'
import { useState, useEffect, ChangeEvent } from 'react'

interface CompanySearchBarProps {
  handleChangeFormData: (option: Option) => void
  manualAssetInputFormData?: ManualInputFormData | TRepSignUpFormData
  variant?: 'standard' | 'outlined'
  disabled?: boolean
  required?: boolean
  error?: boolean
  helperText?: string
  mandatory?: boolean
  label?: string
  placeholder?: string
}

export const CompanySearchBar: React.FC<CompanySearchBarProps> = ({
  handleChangeFormData,
  manualAssetInputFormData,
  variant,
  disabled = false,
  required = true,
  error,
  helperText,
  mandatory = false,
  label = 'Search Company',
  placeholder = 'Search Company',
}) => {
  const [companyNameInput, setCompanyNameInput] = useState<string | undefined>(
    manualAssetInputFormData?.companyName
  )

  const [
    getCompanies,
    { loading: isLoadingCompanies, data: rawCompaniesData },
  ] = usePaginatedCompanySearch()

  const companiesList =
    (rawCompaniesData?.companySearchPaginated.companies?.map(
      (company: BetterIdCompany) => ({
        name: company.parentCompanyName
          ? `${company.parentCompanyName} [${company.name}]`
          : company.name,
        id: company.id,
      })
    ) as Option[]) ?? []

  const handleCompanyNameInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCompanyNameInput(e.target.value)
  }

  useEffect(() => {
    setCompanyNameInput(manualAssetInputFormData?.companyName)
  }, [manualAssetInputFormData?.companyName])

  useEffect(() => {
    getCompanies({
      variables: {
        data: {
          name: companyNameInput,
          limit: 20,
        },
      },
    })
  }, [companyNameInput])

  return (
    <AutocompleteInput
      id="companyName"
      label={mandatory ? `${label}*` : label}
      name="companyName"
      variant={variant}
      value={manualAssetInputFormData?.companyName}
      handleChangeFormData={(e) => {
        handleChangeFormData(e.target.value as Option)
      }}
      onTextInputChange={(e) =>
        handleCompanyNameInputChange(
          e as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        )
      }
      options={companiesList}
      autoSelect={false}
      isLoading={isLoadingCompanies}
      required={required}
      sx={{ my: 0 }}
      disabled={disabled}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      shrink={!!companyNameInput}
    />
  )
}
