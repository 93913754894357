export const facility = {
  redoxId: '62907',
  departments: [
    {
      name: 'Anesthetics',
    },
    {
      name: 'Cardiology',
    },
    {
      name: 'Gastroenterology',
    },
    {
      name: 'Haematology',
    },
    {
      name: 'Neurology',
    },
    {
      name: 'Oncology',
    },
    {
      name: 'Ophthalmology',
    },
    {
      name: 'Orthopaedics',
    },
    {
      name: 'Physiology',
    },
    {
      name: 'Radiology',
    },
    {
      name: 'Rheumatology',
    },
  ],
  wastedReasons: [
    {
      label: 'Defective',
      value: 'defective',
    },
    {
      label: 'Contaminated',
      value: 'contaminated',
    },
    {
      label: 'Incorrect Size',
      value: 'incorrectsize',
    },
    {
      label: 'Inability to achieve primary stability',
      value: 'INABILITY-TO-ACHIEVE-PRIMARY-STABILITY',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],
  assetTypes: [
    {
      label: 'Implantable Hardware/Associated Product',
      value: 'non-biological',
    },
    {
      label: 'Implantable Biologic',
      value: 'biological',
    },
    {
      label: 'Implantable Other',
      value: 'other-non-biological',
    },
    {
      label: 'Consumable',
      value: 'consumable',
    },
  ],
}
