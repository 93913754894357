import { FC, useState } from 'react'

// Components
import { Typography, IconButton, CircularProgress } from '@mui/material'
import TextInput from 'components/molecules/TextInput/TextInput'
import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import AlertDialog from 'components/AlertDialog/AlertDialog'

// Logic
import useProductItemLogic from './ProductItem.logic'

// Icons
import { Mail as MailIcon } from '@mui/icons-material'
import { Delete, Edit } from '@mui/icons-material'

// Types
import { ProductEmailDetails } from 'common/types'
import {
  INewEmail,
  ProductRegistrationItemProps,
} from '../ProductRegistration.types'

// Style
import './ProductItem.scss'

// Other
import classNames from 'classnames'
import { z } from 'zod'

const ProductItem: FC<ProductRegistrationItemProps> = (props) => {
  const {
    emails,
    newEmail,
    isMobile,
    sentEmail,
    isEditing,
    hasEmails,
    sentEmails,
    isDeleting,
    hasActions,
    disableSave,
    addNewEmail,
    emailExists,
    errorDialogOpen,
    isLoadingSendingEmail,
    isUpdatingProductEmail,
    isCreatingProductEmail,
    isDeletingProductEmail,
    setNewEmail,
    setIsEditing,
    setIsDeleting,
    setAddNewEmail,
    setErrorDialogOpen,
    handleDeleteProductEmail,
    handleCreateProductEmail,
    handleUpdateProductEmail,
    handleSendProductRegistrationEmail,
  } = useProductItemLogic(props)

  const { product, scan } = props
  const EmailContainer: FC<ProductEmailDetails> = ({
    email,
    emailId,
    productEmailId,
  }) => {
    const [localEdit, setLocalEdit] = useState<INewEmail>({
      email: email,
    })
    const isEditingMode = isEditing?.open && isEditing?.emailId === emailId
    const emailSchema = z.string().email()

    const disableSave =
      email === localEdit.email ||
      !emailSchema.safeParse(localEdit.email).success ||
      (!!localEdit.confirmEmail &&
        localEdit.email !== localEdit.confirmEmail) ||
      (!localEdit.confirmEmail && localEdit.email !== email)

    const editError =
      localEdit.confirmEmail &&
      emailSchema.safeParse(localEdit.email).success &&
      localEdit.email !== localEdit.confirmEmail

    return (
      <div
        className={classNames('email-container', {
          'is-editing': isEditingMode,
          'is-email-sent': sentEmails?.includes(email),
          'is-mobile': isMobile,
        })}
      >
        <div className="email">
          {isEditingMode ? (
            <>
              <div className="edit-inputs">
                <TextInput
                  className="email-input"
                  value={localEdit.email}
                  handleChangeFormData={(e) =>
                    setLocalEdit((prev) => ({
                      ...prev,
                      email: e.target.value.toLowerCase(),
                    }))
                  }
                  disabled={isUpdatingProductEmail}
                  placeholder="Email"
                  variant="outlined"
                  sx={{ m: 0 }}
                  inputProps={{
                    style: {
                      padding: '3px 7px',
                    },
                  }}
                  autoFocus
                  small
                />
                {!isUpdatingProductEmail && (
                  <TextInput
                    className="email-input"
                    value={localEdit?.confirmEmail || ''}
                    placeholder="Confirm Email"
                    disablePaste
                    handleChangeFormData={(e) =>
                      setLocalEdit((prev) => ({
                        ...prev,
                        confirmEmail: e.target.value.toLowerCase(),
                      }))
                    }
                    disabled={isUpdatingProductEmail}
                    variant="outlined"
                    sx={{ m: 0 }}
                    inputProps={{
                      style: {
                        padding: '3px 7px',
                      },
                    }}
                    small
                  />
                )}
              </div>
              {editError && (
                <Typography
                  variant="body2"
                  color="error"
                  className="email-input"
                >
                  Emails do not match
                </Typography>
              )}
            </>
          ) : (
            <div className="email-block-container">
              <div className="email-block">
                <Typography variant="body2">{email}</Typography>
                {sentEmails?.includes(email) ? <span>✅</span> : null}
              </div>
              {hasActions && (
                <div className="email-actions">
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={() => {
                      setIsEditing((prev) => ({
                        emailId: emailId,
                        open: !prev?.open,
                      }))
                      setAddNewEmail(false)
                    }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                  {!isEditingMode && (
                    <IconButton
                      edge="end"
                      onClick={() => handleSendProductRegistrationEmail(email)}
                      color="primary"
                    >
                      {isLoadingSendingEmail && sentEmail === email ? (
                        <CircularProgress size={25} />
                      ) : (
                        <MailIcon />
                      )}
                    </IconButton>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="actions-container">
          {isEditingMode && (
            <>
              <LoadingButton
                size="small"
                mode="default"
                fullWidth={false}
                onClick={() => {
                  setIsEditing((prev) => ({
                    emailId: emailId,
                    open: !prev?.open,
                  }))
                  setAddNewEmail(false)
                }}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                size="small"
                disabled={Boolean(disableSave)}
                loading={isUpdatingProductEmail}
                mode="default"
                fullWidth={false}
                onClick={(e) => {
                  e.preventDefault()
                  handleUpdateProductEmail(
                    localEdit?.email as string,
                    productEmailId
                  )
                }}
              >
                Save
              </LoadingButton>
            </>
          )}

          {hasActions && !isEditingMode && (
            <>
              <IconButton
                edge="end"
                color="primary"
                onClick={() =>
                  setIsDeleting({
                    open: true,
                    email,
                    productEmailId,
                  })
                }
              >
                <Delete fontSize="medium" />
              </IconButton>
            </>
          )}
        </div>
      </div>
    )
  }

  const emailExistMessage = () =>
    emailExists && (
      <Typography variant="body2" color="red">
        This email already exists.
      </Typography>
    )

  return (
    <div
      className={classNames('product-email-main-container', {
        'is-mobile': isMobile,
      })}
    >
      <div className="product-email-container">
        <div className="header">
          <Typography variant="h4">
            {`${product.company} - ${scan.deviceDescription}`}
          </Typography>
        </div>

        {hasEmails &&
          emails.map((email) => (
            <EmailContainer key={email.emailId} {...email} />
          ))}
      </div>

      <div className={classNames('new-email-container')}>
        {!hasEmails || addNewEmail ? (
          <div className="new-email">
            <div className="header">
              {!hasEmails && (
                <Typography variant="subtitle2">
                  No e-mail address assigned
                </Typography>
              )}
            </div>
            <div className="actions">
              <TextInput
                tabIndex={0}
                label={`Enter a new e-mail for product`}
                value={newEmail?.email}
                handleChangeFormData={(e) =>
                  setNewEmail((prev) => ({
                    ...prev,
                    email: e.target.value.toLowerCase(),
                  }))
                }
                disabled={isCreatingProductEmail}
                variant="outlined"
                sx={{ m: 0 }}
                autoFocus
                small
              />
              {isMobile && emailExistMessage()}
              <TextInput
                tabIndex={1}
                label="Confirm email"
                disablePaste
                value={newEmail?.confirmEmail}
                handleChangeFormData={(e) =>
                  setNewEmail((prev) => ({
                    ...prev,
                    confirmEmail: e.target.value.toLowerCase(),
                  }))
                }
                disabled={isCreatingProductEmail}
                variant="outlined"
                sx={{ m: 0 }}
                small
              />
              {!hasEmails ? (
                <LoadingButton
                  size="small"
                  disabled={disableSave}
                  loading={isCreatingProductEmail}
                  fullWidth={false}
                  onClick={(e) => {
                    e.preventDefault()
                    handleCreateProductEmail()
                  }}
                >
                  Save
                </LoadingButton>
              ) : (
                <LoadingButton
                  size="small"
                  disabled={newEmail?.email?.length ? disableSave : false}
                  loading={isCreatingProductEmail}
                  fullWidth={false}
                  onClick={() =>
                    newEmail?.email?.length
                      ? handleCreateProductEmail()
                      : setAddNewEmail(false)
                  }
                >
                  {newEmail?.email?.length ? 'Save' : 'Cancel'}
                </LoadingButton>
              )}
            </div>
            {!isMobile && emailExistMessage()}
          </div>
        ) : !isEditing?.open ? (
          <LoadingButton
            size="small"
            mode="default"
            fullWidth={false}
            onClick={() => {
              setAddNewEmail(true)
              setIsEditing({
                open: false,
              })
            }}
          >
            Add registration email
          </LoadingButton>
        ) : (
          <></>
        )}
      </div>

      <div>
        <AlertDialog
          isOpen={errorDialogOpen}
          mode="error"
          title="Failed to send record"
          description={`There was an error when attempting to send the product registration email. Please try again.`}
          position={{
            bottom: '40%',
          }}
          positionAboveBottomNavigation
          primaryButtonAction={() => handleSendProductRegistrationEmail()}
          primaryButtonText="Retry Send"
          secondaryButtonAction={() => setErrorDialogOpen(false)}
          secondaryButtonText="Cancel"
          zIndex={6}
          isPrimaryDisabled={emails.length === 0}
          isPrimaryLoading={isLoadingSendingEmail}
        />
        <AlertDialog
          isOpen={isDeleting?.open as boolean}
          mode="error"
          title="Delete Product Email"
          description={`Are you sure you want to delete ${
            isDeleting?.email || 'this email'
          }?`}
          position={{
            bottom: '40%',
          }}
          positionAboveBottomNavigation
          primaryButtonAction={() =>
            handleDeleteProductEmail(isDeleting?.productEmailId as number)
          }
          primaryButtonText="Delete"
          secondaryButtonAction={() => setIsDeleting({ open: false })}
          secondaryButtonText="Cancel"
          zIndex={6}
          isPrimaryDisabled={emails.length === 0}
          isPrimaryLoading={isDeletingProductEmail}
        />
      </div>
    </div>
  )
}
export default ProductItem
