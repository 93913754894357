import { useEffect, useState } from 'react'

import useSubmitTrayCapture from '../SubmitTrayCapture.logic'
import { getAnalysisJSONFileName } from 'lib/utils/getAnalysisJSONFile'
import { useAssignedDigitalTrays } from 'views/DigitalTrayMapping/AssignedDigitalTrays/AssignedDigitalTrays.context'
import { extractSubTrayJSONData } from 'lib/utils/extractSubTrayJSONData'

const useSubmitImageDialog = ({
  trayImage,
  setTrayImage,
}: {
  trayImage: string
  setTrayImage: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { submitImage, cancelSubmit, setAnalysisFileName } =
    useSubmitTrayCapture()
  const { selectedTray } = useAssignedDigitalTrays()
  const subTrayDetails = extractSubTrayJSONData(selectedTray?.subItems?.[0])
  const trayType = subTrayDetails?.trayType || 'stryker screw caddy'

  const [isConfirmBaseOpen, setIsConfirmBaseOpen] = useState<boolean>(false)

  useEffect(() => {
    const fileName = getAnalysisJSONFileName(trayType)
    setAnalysisFileName(fileName)
  }, [trayType, setAnalysisFileName])

  const rotateImage = () => {
    // Check if there is an image
    if (trayImage) {
      // Create a new Image object
      const img = new Image()
      img.src = trayImage

      // Create a canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

      // Set canvas dimensions to match image
      canvas.width = img.height
      canvas.height = img.width

      // Rotate the image
      ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.rotate(Math.PI / 2)
      ctx.drawImage(img, -img.width / 2, -img.height / 2)

      // Convert canvas to data URL and update state
      setTrayImage(canvas.toDataURL())
    }
  }

  const handleConfirmBaseModalClose = () => {
    setIsConfirmBaseOpen(false)
  }

  const handleSubmit = () => {
    handleConfirmBaseModalClose()
    submitImage(trayImage)
  }

  return {
    isConfirmBaseOpen,
    setIsConfirmBaseOpen,
    rotateImage,
    handleSubmit,
    handleConfirmBaseModalClose,
    cancelSubmit,
  }
}

export default useSubmitImageDialog
