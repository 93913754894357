import { Filter } from '../TrayManagementFilters/TrayManagementFilters.types'
import { TrayType } from 'components/organisms/AddTraySelectType/AddTraySelectType.types'

const useFilterDrawer = (
  filters: Filter[],
  selectedCategory: TrayType['category'] | undefined
) => {
  const getAvailableStatusFilters = () => {
    if (!selectedCategory) {
      const uniqueSubFilters = new Set()
      filters.forEach((filter) => {
        filter.subFilters?.forEach((subFilter) => {
          uniqueSubFilters.add(JSON.stringify(subFilter))
        })
      })
      return Array.from(uniqueSubFilters).map((filter) =>
        JSON.parse(filter as string)
      )
    }

    return selectedCategoryFilter?.subFilters || []
  }

  const selectedCategoryFilter = filters.find(
    (f) => f.filterValue === selectedCategory
  )
  const statusFilters = getAvailableStatusFilters()

  return {
    statusFilters,
    selectedCategoryFilter,
  }
}

export default useFilterDrawer
