import './AddTrayDetails.scss'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Button,
  Paper,
} from '@mui/material'

import { LoadingButton } from 'components/mui'
import useTrayConfiguration from 'views/TrayConfiguration/TrayConfiguration.logic'
import useAddTrayDetailsLogic from './AddTrayDetails.logic'
import { useUser } from 'app/User'
import { useAddTrayContext } from 'views/TrayManagementLayout/AddTray/AddTray.context'
import { TrayType } from 'components/organisms/AddTraySelectType/AddTraySelectType.types'
import { useTrayStepContext } from 'views/TrayManagementLayout/AddTray/AddTray.logic'

const AddTrayDetails = () => {
  const { user, isRep } = useUser()
  const { nextStep, prevStep } = useTrayStepContext()
  const showRepOptions =
    isRep || !user?.roles?.length || user?.roles?.includes('MGAT_GUEST')

  const {
    trayDescription,
    handleTrayDescriptionChange,
    selectedLocationId,
    handleSelectedLocationChange,
    trayVendor,
    handleTrayVendorChange,
    trayWeight,
    handleTrayWeightChange,
    renderDropOffDateTime,
    getTrayCategoryOptions,
  } = useAddTrayDetailsLogic()

  const { selectedTrayCategory, setSelectedTrayCategory } = useAddTrayContext()

  const { locationsWithParents, findLocation, isLoadingLocations } =
    useTrayConfiguration()

  const handleNext = () => {
    if (
      trayDescription &&
      selectedLocationId &&
      trayVendor &&
      selectedTrayCategory
    ) {
      nextStep()
    }
  }

  return (
    <>
      <Paper className="add-tray-details-step step-item">
        <div className="add-tray-details-step_content">
          {renderDropOffDateTime()}

          <FormControl fullWidth>
            <Select
              fullWidth
              variant="outlined"
              displayEmpty
              renderValue={(selected) =>
                !selected ? (
                  <Typography variant="body1" color="gray">
                    Select Tray Storage Location (required)
                  </Typography>
                ) : (
                  findLocation(selected as number)?.name
                )
              }
              value={selectedLocationId || ''}
              onChange={handleSelectedLocationChange}
            >
              <MenuItem disabled value="">
                {isLoadingLocations
                  ? 'Loading Locations...'
                  : 'Select Tray Storage Location'}
                Select Tray Storage Location
              </MenuItem>
              {locationsWithParents.map((location) => (
                <MenuItem
                  data-testid="hardware-menuitem"
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  key={location.id}
                  value={location.id}
                >
                  {location.name},{' '}
                  {findLocation(location.parentLocationId as number)?.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              You can create new locations from the location management
              dashboard
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth>
            <Select
              data-testid="add-tray-location-storage_input"
              fullWidth
              variant="outlined"
              displayEmpty
              renderValue={(selected) =>
                !selected ? (
                  <Typography variant="body1" color="gray">
                    Select Tray {!showRepOptions ? 'Manufacturer' : 'Vendor'}{' '}
                    (required)
                  </Typography>
                ) : (
                  selected
                )
              }
              value={trayVendor}
              onChange={handleTrayVendorChange}
            >
              <MenuItem disabled value="">
                Select Tray {!showRepOptions ? 'Manufacturer' : 'Vendor'}
              </MenuItem>
              {[
                'Arthrex',
                'B. Braun Melsungen',
                'Boston Scientific',
                'DePuy Synthes (Johnson & Johnson)',
                'Globus Medical',
                'Integra LifeSciences',
                'Medtronic',
                'NuVasive',
                'Smith & Nephew',
                'Stryker Corporation',
                'Wright Medical Group',
                'Zimmer Biomet',
              ].map((vendor) => (
                <MenuItem
                  key={vendor}
                  style={{ cursor: 'pointer', fontSize: 16 }}
                  value={vendor}
                >
                  {vendor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            autoComplete="off"
            placeholder="Enter Tray Details/ Description (required)"
            fullWidth
            multiline
            minRows={4}
            maxRows={7}
            type="text"
            value={trayDescription}
            onChange={handleTrayDescriptionChange}
            variant="outlined"
          />

          <TextField
            autoComplete="off"
            placeholder="Tray Weight lbs (optional)"
            fullWidth
            type="number"
            value={trayWeight || ''}
            onChange={handleTrayWeightChange}
            variant="outlined"
          />

          <div className="tray-category_selection">
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={selectedTrayCategory || 'facility'}
                onChange={(e) =>
                  setSelectedTrayCategory(
                    e.target.value as TrayType['category']
                  )
                }
              >
                {getTrayCategoryOptions().map((trayCategory) => (
                  <FormControlLabel
                    key={trayCategory}
                    value={trayCategory}
                    control={<Radio />}
                    label={`${trayCategory
                      .charAt(0)
                      .toUpperCase()}${trayCategory.slice(1)}`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Paper>
      <div className="buttons_container">
        <Button variant="outlined" onClick={prevStep}>
          Back
        </Button>
        <LoadingButton
          dataTestId="add-tray-submit_button"
          disabled={
            !trayDescription ||
            !selectedLocationId ||
            !trayVendor ||
            !selectedTrayCategory
          }
          onClick={handleNext}
        >
          Next
        </LoadingButton>
      </div>
    </>
  )
}

export default AddTrayDetails
