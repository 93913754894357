import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { captureException } from 'lib/monitoring'
import { useIsScannerScreenMidSize } from 'lib/utils/mediaQueries'

declare global {
  interface Window {
    cortexScanner?: CortexScanner
    isScannerFailed?: boolean
    mockScan: (text: string) => void
  }
}

type OnScan = (result: CortexDecoder.CDResult) => void
type OnResult = (result: { results: CortexDecoder.CDResult[] }) => void
type OnError = (error: Error) => void

interface CortexDecoderArgs {
  onScan: OnScan
  onError?: OnError
  isEnabled: boolean
  hasROI?: boolean
}

interface CameraSelectProps {
  value?: string
  onChange: (deviceId: string) => void
}

type SetCurrentScan = React.Dispatch<
  React.SetStateAction<CortexDecoder.CDResult | undefined>
>

interface CortexDecoderProviderProps {
  children: React.ReactNode
  onScan: OnScan
  currentScan?: CortexDecoder.CDResult
  setCurrentScan: SetCurrentScan
  canScan?: boolean
  hasROI?: boolean
}

type BaseDecoderContext = {
  currentScan?: CortexDecoder.CDResult
  setCurrentScan: SetCurrentScan
  isEnabled: boolean
  trayScannerState?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  serialNumberScannerState?: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ]
}

const CortexDecoderContext = React.createContext<
  | (ReturnType<typeof useCortexDecoder> & BaseDecoderContext)
  | BaseDecoderContext
>({
  isEnabled: false,
  setCurrentScan: () => {},
})

export function useCortexDecoderContext() {
  const context = React.useContext(CortexDecoderContext)
  if (!('isActive' in context)) {
    throw new Error(
      'useCortexDecoderContext must be used within a CortexDecoderContextProvider'
    )
  }
  return context
}

export function CortexDecoderProvider({
  children,
  onScan,
  currentScan,
  setCurrentScan,
  canScan = true,
  hasROI = true,
}: CortexDecoderProviderProps) {
  const location = useLocation()
  const [isEnabled, setIsEnabled] = React.useState(false)
  const trayScannerState = React.useState(false)
  const serialNumberScannerState = React.useState(false)
  const cortexDecoder = useCortexDecoder({ onScan, isEnabled, hasROI })

  React.useEffect(() => {
    if (
      (location.pathname.includes('/asset/scan') ||
        trayScannerState[0] ||
        serialNumberScannerState[0]) &&
      canScan
    ) {
      setIsEnabled(true)
    } else {
      setIsEnabled(false)
    }
  }, [location, trayScannerState, serialNumberScannerState, canScan])

  return (
    <CortexDecoderContext.Provider
      value={{
        ...cortexDecoder,
        currentScan,
        setCurrentScan,
        isEnabled,
        trayScannerState,
        serialNumberScannerState,
      }}
    >
      {children}
    </CortexDecoderContext.Provider>
  )
}

export function CortexVideo() {
  const { isEnabled } = useCortexDecoderContext()
  const [mediaStream, setMediaStream] = React.useState<
    MediaStream | undefined
  >()
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const srcObject = videoRef.current?.srcObject
    if (srcObject && 'id' in srcObject && srcObject.id !== mediaStream?.id) {
      setMediaStream(srcObject)
    } else if (!srcObject && mediaStream) {
      videoRef.current!.srcObject = mediaStream
    }
  }, [mediaStream])

  // disable pinch-zoom on mobile
  React.useEffect(() => {
    const disablePinchZoom = (e: any) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener('touchmove', disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener('touchmove', disablePinchZoom)
    }
  }, [])

  return (
    <div
      id="video-container"
      ref={containerRef}
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100vw',
        height: 'calc(100vh - 139px)',
        zIndex: isEnabled ? 0 : -1,
      }}
    >
      <video
        ref={videoRef}
        id="video"
        width={window.innerWidth}
        height={window.innerHeight}
        style={{
          objectFit: 'cover',
        }}
        playsInline
        autoPlay
      />
    </div>
  )
}

export function CortexReceivingVideo() {
  const { isEnabled } = useCortexDecoderContext()
  const [mediaStream, setMediaStream] = React.useState<
    MediaStream | undefined
  >()
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const srcObject = videoRef.current?.srcObject
    if (srcObject && 'id' in srcObject && srcObject.id !== mediaStream?.id) {
      setMediaStream(srcObject)
    } else if (!srcObject && mediaStream) {
      videoRef.current!.srcObject = mediaStream
    }
  }, [mediaStream])

  // disable pinch-zoom on mobile
  React.useEffect(() => {
    const disablePinchZoom = (e: any) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }
    document.addEventListener('touchmove', disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener('touchmove', disablePinchZoom)
    }
  }, [])

  return (
    <div
      id="video-container"
      ref={containerRef}
      style={{
        position: 'fixed',
        top: '143px',
        left: '90px',
        width: '692px',
        height: 'calc(100vh - 139px)',
        zIndex: isEnabled ? 0 : -1,
      }}
    >
      <video
        ref={videoRef}
        id="video"
        style={{
          objectFit: 'cover',
          width: '675px',
          height: '381px',
          borderRadius: '12px',
        }}
        playsInline
        autoPlay
      />
    </div>
  )
}

const FORMATS_1D = [
  'BC412',
  'Codabar',
  'Code11',
  'Code32',
  'Code39',
  'Code93',
  'Code128',
  'EAN8',
  'EAN13',
  'GS1Databar14',
  'MSIPlessey',
  'Pharmacode',
  'Plessey',
  'Telepen',
  'Trioptic',
  'UPCA',
  'UPCE',
  'HongKong2of5',
  'IATA2of5',
  'Interleaved2of5',
  'Matrix2of5',
  'NEC2of5',
  'Straight2of5',
]

const FORMATS_2D = [
  'Aztec',
  'DataMatrix',
  'CodablockF',
  'Code49',
  'CompositeCode',
  'PDF417',
  'MicroPDF417',
  'DotCode',
  'GoCode',
  'GridMatrix',
  'HanXinCode',
  'Maxicode',
  'QR',
  'QR Code',
]

const FORMATS = [...FORMATS_1D, ...FORMATS_2D]

export function CameraSelect({ value, onChange }: CameraSelectProps) {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value)
  }

  if (!value) {
    return null
  }

  return (
    <select
      onChange={handleChange}
      style={{
        background: 'transparent',
        color: 'black',
        padding: 4,
        fontSize: 13,
        // border: 'none',
      }}
      value={value}
    >
      {CortexDecoder.CDCamera.getConnectedCameras().map((cam: any) => (
        <option value={cam.id} key={cam.id}>
          {cam.label.split('(')[0]?.trim()}
        </option>
      ))}
    </select>
  )
}

export function useCortexDecoder({
  onScan,
  isEnabled,
  hasROI = true,
}: CortexDecoderArgs) {
  const [isInitialized, setIsInitialized] = React.useState(false)
  const [isActive, setIsActive] = React.useState(false)
  const [currentCamera, setCurrentCamera] = React.useState<string | undefined>()
  const [instanceNumber, setInstanceNumber] = React.useState(1)
  const [error, setError] = React.useState<Error | null>(null)
  const [orientation, setOrientation] = React.useState<
    'portrait' | 'landscape'
  >('portrait')
  const [viewfinderOrientation, setViewfinderOrientation] = React.useState<
    'vertical' | 'horizontal'
  >('horizontal')
  const isTablet = useIsScannerScreenMidSize()

  const instanceId = React.useMemo(() => {
    return currentCamera && instanceNumber > 0
      ? `cortex-${currentCamera}-${instanceNumber}`
      : undefined
  }, [currentCamera, instanceNumber])

  const prevInstanceId = React.useRef<string | undefined>(undefined)

  const bumpInstanceNumber = React.useCallback(
    () => setInstanceNumber((n) => n + 1),
    []
  )

  const onResult: OnResult = React.useCallback(
    ({ results }) => {
      const lastResult = results[results.length - 1]
      const doesResultFormatMatch = FORMATS.includes(lastResult.symbologyName)
      if (doesResultFormatMatch) {
        onScan(lastResult)
      }
    },
    [onScan]
  )

  const changeCamera = React.useCallback(
    (deviceId: string) => {
      CortexDecoder.CDCamera.setCamera(deviceId)
      setCurrentCamera(deviceId)
    },
    [setCurrentCamera]
  )

  const stopScanner = React.useCallback(async () => {
    if (isActive && isInitialized) {
      console.debug('[useCortexDecoder]: Stopping Scanner')
      await window.cortexScanner?.stop()
      setIsActive(false)
      console.debug('[useCortexDecoder]: Scanner Stopped')
    }
  }, [setIsActive, isActive, isInitialized])

  const startScanner = React.useCallback(async () => {
    if (!isActive && isInitialized) {
      console.debug('[useCortexDecoder]: Starting Scanner')
      await window.cortexScanner?.start({ onResult })
      setIsActive(true)
      console.debug('[useCortexDecoder]: Scanner Started')
    }
  }, [onResult, isActive, isInitialized])

  React.useEffect(() => {
    if (isEnabled) {
      window.cortexScanner?.startDecoding()
    } else {
      window.cortexScanner?.stopDecoding()
    }
  }, [isEnabled])

  // Initialize Cortex
  React.useEffect(() => {
    const handleInitError = (err: Error) => {
      captureException(err)
      setError(err)
    }

    const initializedCallback = () => {
      setIsInitialized(true)
      setIsActive(window.cortexScanner?.isScannerActive ?? false)
      setCurrentCamera(CortexDecoder.CDCamera.getCamera().id)
    }

    if (!window.cortexScanner) {
      window.cortexScanner = new CortexScanner()
    }

    console.debug('[useCortexDecoder]: Initializing Scanner')
    window.cortexScanner.init().then(initializedCallback).catch(handleInitError)

    return () => {
      console.debug('[useCortexDecoder]: Cleaning up')
      window.cortexScanner?.stop()
    }
  }, [])

  React.useEffect(() => {
    if (isActive) {
      if (isTablet) {
        setTimeout(() => {
          const cdRect = new CortexDecoder.CDRect()
          const windowWidth = window.innerWidth
          const windowHeight = window.innerHeight

          let rectangleWidth = 320
          let rectangleHeight = 250

          const x = (windowWidth - rectangleWidth) / 2
          const y = (windowHeight - rectangleHeight) / 2

          cdRect.BottomLeft.X = x.toString()
          cdRect.BottomLeft.Y = (y + rectangleHeight).toString()
          cdRect.BottomRight.X = (x + rectangleWidth).toString()
          cdRect.BottomRight.Y = (y + rectangleHeight).toString()
          cdRect.TopLeft.X = x.toString()
          cdRect.TopLeft.Y = y.toString()
          cdRect.TopRight.X = (x + rectangleWidth).toString()
          cdRect.TopRight.Y = y.toString()

          window.cortexScanner?.drawROI(cdRect)
          window.cortexScanner?.setROI(cdRect)
        }, 25)
      } else {
        const cdRect = new CortexDecoder.CDRect()
        cdRect.TopLeft.X = cdRect.BottomLeft.X = '170'
        cdRect.BottomLeft.Y = cdRect.BottomRight.Y = '294'
        cdRect.BottomRight.X = cdRect.TopRight.X = '490'
        cdRect.TopRight.Y = cdRect.TopLeft.Y = '94'

        window.cortexScanner?.drawROI(cdRect)
        window.cortexScanner?.setROI(cdRect)
      }
    }
  }, [orientation, isActive, viewfinderOrientation, hasROI, isTablet])

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        bumpInstanceNumber()
      } else {
        stopScanner()
      }
    }

    const handleOrientationChange = (e?: Event) => {
      const target = (e?.target ?? window) as Window
      const isPortrait = target.innerHeight > target.innerWidth
      setOrientation(isPortrait ? 'portrait' : 'landscape')
    }

    handleOrientationChange()

    window.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('orientationchange', handleOrientationChange)
    window.addEventListener('resize', handleOrientationChange)
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('orientationchange', handleOrientationChange)
      window.removeEventListener('resize', handleOrientationChange)
    }
  }, [bumpInstanceNumber, stopScanner, setOrientation])

  // Attempt to recover failed scanner preview
  React.useEffect(() => {
    if (isActive) {
      const handle = setInterval(() => {
        const video = document.querySelector('video')
        if (video?.readyState !== 4) {
          if (!window.isScannerFailed) {
            window.isScannerFailed = true
          } else {
            console.debug(
              '[useCortexDecoder]: Attempting to recover camera preview failure'
            )
            window.isScannerFailed = false
            bumpInstanceNumber()
          }
        }
      }, 1500)
      return () => {
        clearInterval(handle)
      }
    }
  }, [isActive, bumpInstanceNumber])

  // This very important effect decides when to start and stop the scanner
  React.useEffect(() => {
    if (isInitialized && instanceId && prevInstanceId?.current !== instanceId) {
      if (isActive) {
        stopScanner()
      } else {
        startScanner()
        prevInstanceId.current = instanceId
      }
    }
  }, [
    startScanner,
    stopScanner,
    instanceId,
    isActive,
    isInitialized,
    bumpInstanceNumber,
  ])

  return React.useMemo(
    () => ({
      error,
      isActive,
      start: startScanner,
      stop: stopScanner,
      changeCamera,
      currentCamera,
      viewfinderOrientation,
      toggleViewfinderOrientation: () =>
        setViewfinderOrientation((prev) =>
          prev === 'vertical' ? 'horizontal' : 'vertical'
        ),
    }),
    [
      error,
      changeCamera,
      currentCamera,
      startScanner,
      stopScanner,
      isActive,
      viewfinderOrientation,
      setViewfinderOrientation,
    ]
  )
}

class CortexScanner {
  _isInitialized: boolean
  _isStarting: boolean
  _isStopping: boolean
  _pendingActionHandle?: NodeJS.Timeout
  isScannerActive: boolean

  constructor() {
    this._isInitialized = false
    this._isStarting = false
    this._isStopping = false
    this.isScannerActive = false
  }

  async init() {
    if (!this._isInitialized) {
      console.debug('[CortexScanner]: Initializing')
      await CortexDecoder.CDDecoder.init('/cd')
      await CortexDecoder.CDLicense.activateLicense(
        process.env.REACT_APP_CORTEX_KEY!
      )
      await CortexDecoder.CDCamera.init(document.getElementById('video'))
      await CortexDecoder.CDPerformanceFeatures.setdataParsing(
        5,
        '000000000000|;^1^C'
      )
      this._isInitialized = true
    }
  }

  startDecoding() {
    CortexDecoder.CDDecoder.setDecoding(true)
  }

  stopDecoding() {
    CortexDecoder.CDDecoder.setDecoding(false)
  }

  async start({ onResult }: { onResult: OnResult }) {
    if (!this._isInitialized) {
      throw new Error('CortexScanner is not initialized')
    }

    const startFn = async () => {
      console.debug('[CortexScanner]: Starting scanner')
      this._isStarting = true
      await waitForIdle()
      await CortexDecoder.CDCamera.init(document.getElementById('video'))
      await CortexDecoder.CDCamera.startCamera()
      await CortexDecoder.CDCamera.startPreview(onResult)
      CortexDecoder.CDDecoder.setDecoding(true)
      CortexDecoder.CDCamera.videoCapturing = true
      this._isStarting = false
      this.isScannerActive = true
      console.debug('[CortexScanner]: Scanner started')
    }

    if (this._isStopping || this._isStarting) {
      this._clearPendingAction()

      console.debug('[CortexScanner]: Scheduling scanner start')
      this._pendingActionHandle = setInterval(() => {
        this._clearPendingAction()
        if (this.isScannerActive) {
          this.stop().then(startFn)
        } else {
          startFn()
          console.debug('[CortexScanner]: Executing scheduled start')
        }
      }, 250)
    } else {
      await startFn()
    }
  }

  async stop() {
    if (!this._isInitialized) {
      throw new Error('CortexScanner is not initialized')
    }

    if (!this._isStopping && this.isScannerActive) {
      this._clearPendingAction()

      const stopFn = async () => {
        console.debug('[CortexScanner]: Stopping scanner')
        this._isStopping = true
        await CortexDecoder.CDCamera.stopCamera()
        await CortexDecoder.CDCamera.stopPreview()
        CortexDecoder.CDDecoder.setDecoding(false)
        CortexDecoder.CDCamera.videoCapturing = false
        this._isStopping = false
        this.isScannerActive = false
        console.debug('[CortexScanner]: Scanner stopped')
      }

      if (this._isStarting) {
        console.debug('[CortexScanner]: Scheduling scanner stop')
        this._pendingActionHandle = setInterval(() => {
          if (!this._isStarting) {
            this._clearPendingAction()
            stopFn()
            console.debug('[CortexScanner]: Executing scheduled stop')
          }
        }, 250)
      } else {
        await stopFn()
      }
    }
  }

  async setROI(cdRect: CortexDecoder.CDRect) {
    await CortexDecoder.CDDecoder.setRegionOfInterest([cdRect])
  }

  async drawROI(cdRect: CortexDecoder.CDRect) {
    let canvas = document.querySelector('canvas')
    canvas = canvas ?? document.createElement('canvas')
    const videoElement = document.getElementById('video')
    const videoContainer = document.getElementById('video-container')
    const isTablet = window.matchMedia('(max-width: 1330px)').matches
    const ctx = canvas.getContext('2d')
    const canvasWidth = 674
    const canvasHeight = 381

    if (videoElement && videoContainer && ctx) {
      canvas.id = 'ROIcanvas'
      canvas.width = isTablet ? window.innerWidth : canvasWidth
      canvas.height = isTablet ? window.innerHeight : canvasHeight
      canvas.style.borderRadius = isTablet ? '0' : '10px'
      canvas.style.marginLeft = isTablet ? 'auto' : '0'
      canvas.style.marginRight = isTablet ? 'auto' : '0'
      canvas.style.zIndex = '5'
      canvas.style.position = 'absolute'
      canvas.style.left = '0'
      canvas.style.right = '0'
      canvas.style.textAlign = 'center'

      const { TopLeft, TopRight, BottomLeft, BottomRight } = cdRect

      const x = Math.min(
        parseInt(TopLeft.X, 10),
        parseInt(TopRight.X, 10),
        parseInt(BottomLeft.X, 10),
        parseInt(BottomRight.X, 10)
      )
      const y = Math.min(
        parseInt(TopLeft.Y, 10),
        parseInt(TopRight.Y, 10),
        parseInt(BottomLeft.Y, 10),
        parseInt(BottomRight.Y, 10)
      )
      const width =
        Math.max(
          parseInt(TopLeft.X, 10),
          parseInt(TopRight.X, 10),
          parseInt(BottomLeft.X, 10),
          parseInt(BottomRight.X, 10)
        ) - x
      const height =
        Math.max(
          parseInt(TopLeft.Y, 10),
          parseInt(TopRight.Y, 10),
          parseInt(BottomLeft.Y, 10),
          parseInt(BottomRight.Y, 10)
        ) - y

      // Clear the entire canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // draw semi-transparent black overlay
      ctx.fillStyle = 'rgba(0, 0, 0, 0.25)'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.clearRect(x, y, width, height)

      // draw box
      ctx.strokeStyle = 'white'
      ctx.lineWidth = 2
      ctx.beginPath()
      ctx.moveTo(parseInt(TopLeft.X, 10), parseInt(TopLeft.Y, 10))
      ctx.lineTo(parseInt(TopRight.X, 10), parseInt(TopRight.Y, 10))
      ctx.lineTo(parseInt(BottomRight.X, 10), parseInt(BottomRight.Y, 10))
      ctx.lineTo(parseInt(BottomLeft.X, 10), parseInt(BottomLeft.Y, 10))
      ctx.closePath()
      ctx.stroke()

      videoContainer.appendChild(canvas)
    }
  }

  _clearPendingAction() {
    clearInterval(this._pendingActionHandle)
    this._pendingActionHandle = undefined
  }
}

function waitForIdle() {
  return new Promise((resolve) => {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(resolve)
    } else {
      setTimeout(resolve, 0)
    }
  })
}
