import { SubTrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'
import { SubItem } from 'common/types'

export const extractSubTrayJSONData = (subItem: SubItem | undefined | null) => {
  const trayDetails: SubTrayItemProductDetails | undefined = subItem
    ? (JSON.parse(
        subItem.productDetails as string
      ) as SubTrayItemProductDetails)
    : undefined

  return trayDetails
}
