import { Surgery } from 'common/types'
import { useUser } from 'app/User'
import { useIsMobile } from 'lib/utils/useIsMobile'
import { Box, Chip, Typography } from '@mui/material'
import './ProcedureListTable.scss'
import { ProcedureListTableProps } from './ProcedureList.types'
import { formatSurgeryProviderName } from 'lib/utils/formatSurgeryProviderName'
import { useSetSurgeryStatusAsStarted } from 'lib/apollo/hooks'
import { useNavigate } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import dayjs from 'lib/dayjs'
import { useState } from 'react'

export const useProcedureListTableLogic = ({
  events,
  showDate = false,
}: ProcedureListTableProps) => {
  const flags = useFlags()
  const navigate = useNavigate()
  const { isNurse, isRep } = useUser()
  const hasItems = events && events?.length > 0
  const isMobile = useIsMobile()
  const [setStatus] = useSetSurgeryStatusAsStarted()

  const [isNotificationsOpen, setIsNotificationsOpen] = useState<{
    isOpen: boolean
    surgery?: Surgery
  }>({
    isOpen: false,
  })

  const hasNotifications = (surgery?: Surgery): boolean => {
    return (
      (surgery?.salesRepsNotified?.[0]?.salesReps?.length as number) > 0 ||
      false
    )
  }

  const handleStartSurgery = (surgery: Surgery) => {
    navigate(`/surgeries/${surgery?._id}`)
    if (surgery.status.name !== 'SCANNING') return
    setStatus({
      variables: {
        surgeryId: surgery._id,
      },
    })
  }

  const RoomInformation: React.FC<{
    surgery: Surgery
  }> = ({ surgery }) => {
    const room = surgery.visit?.location?.room || ''
    const isManuallyAdded = surgery.isManuallyAdded
    const earliestDateTime = surgery.procedures
      ?.map((proc) => proc.dateTime)
      .filter(Boolean)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]

    return (
      <Box className="room" data-testid="room-number">
        {isManuallyAdded ? (
          <Chip
            label="M"
            color="warning"
            sx={{
              color: 'var(--primary)',
              backgroundColor: 'white',
              fontWeight: 'bold',
            }}
          />
        ) : null}
        <Typography fontSize="1.25rem" fontWeight="700">
          Room
        </Typography>
        <Typography fontSize="1.25rem" fontWeight="700">
          {room}
        </Typography>
        <Typography
          className="procedure-datetime"
          fontSize="0.875rem"
          fontWeight="medium"
          textAlign="center"
          color="white"
        >
          {showDate && earliestDateTime && (
            <>
              {dayjs(earliestDateTime).format('MM/DD/YYYY')}
              <br />
              {dayjs(earliestDateTime).format('h:mm A')}
            </>
          )}
        </Typography>
      </Box>
    )
  }

  const PatientInformation: React.FC<{
    surgery: Surgery
    isNurse: boolean
  }> = ({ surgery, isNurse }) => (
    <Box className="patient" data-testid="patient-procedure-info">
      {isNurse && (
        <>
          <Typography fontSize="1rem" fontWeight="500">
            {`${
              surgery?.patient?.lastName
            }, ${surgery?.patient?.firstName?.charAt(0)}`}
          </Typography>
          <Typography>DOB: {surgery?.patient?.dateOfBirth}</Typography>
          <Typography>MRN: {surgery?.patient?.idMR}</Typography>
          <br />
        </>
      )}
      {flags.procedureListSurgeryType && (
        <Typography>
          Surgery: {surgery?.procedures?.[0]?.description}
        </Typography>
      )}
      <Typography>Surgeon: {formatSurgeryProviderName(surgery)}</Typography>
    </Box>
  )

  const caseInformation: React.FC<{
    surgery: Surgery
    isNurse: boolean
  }> = ({ surgery, isNurse }) => (
    <>
      <RoomInformation surgery={surgery} />
      <PatientInformation surgery={surgery} isNurse={isNurse} />
    </>
  )

  const eventsArray = events && [...events]
  const sortByRoom = eventsArray?.sort((a: Surgery, b: Surgery) => {
    const roomA = a?.visit?.location?.room || ''
    const roomB = b?.visit?.location?.room || ''
    if (roomA < roomB) {
      return -1
    }
    if (roomA > roomB) {
      return 1
    }
    return 0
  })

  return {
    flags,
    isRep,
    isNurse,
    hasItems,
    isMobile,
    caseInformation,
    sortByRoom,
    handleStartSurgery,
    hasNotifications,
    isNotificationsOpen,
    setIsNotificationsOpen,
  }
}
