import { FormControl, Skeleton, TextField, Typography } from '@mui/material'
import { CameraAlt, FlipCameraIos, Replay } from '@mui/icons-material'
import { Player } from '@lottiefiles/react-lottie-player'

import { LoadingButton } from 'components/mui'
import { useSPDScan } from '../../../views/SPDLayout/SPD/Scan/Scan.context'
import useCaptureTrayDialog from './CaptureTrayDialog.logic'

import './CaptureTrayDialog.scss'
import { CaptureTrayDialogProps } from './CaptureTrayDailog.types'

const CaptureTrayDialog = ({ onNext }: CaptureTrayDialogProps) => {
  const { productData } = useSPDScan()
  const {
    image,
    videoRef,
    mediaStream,
    cameras,
    activeCamera,
    isCameraMenuOpen,
    handleToggleCameraMenu,
    handleSelectCamera,
    manualImage,
    handleManualImageChange,
    isValidUrl,
    handleSubmitClick,
    handleCapture,
    isSubmitting,
  } = useCaptureTrayDialog({ onNext })

  return (
    <div className="capture-tray">
      <div className="product-container">
        <div className="product-info">
          <Typography variant="h1" className="description">
            {productData[0]?.deviceDescription}
          </Typography>
          <Typography variant="body1" className="company">
            Company: {productData[0]?.company?.name}
          </Typography>
          <Typography variant="body1" className="count">
            Device count: {productData[0]?.deviceCount}
          </Typography>
        </div>

        <div className="manual-input_container">
          <Typography variant="subtitle1" className="section-title">
            Manual Image Upload
          </Typography>
          <TextField
            fullWidth
            name="trayImageURL"
            label="Enter image URL"
            placeholder="https://example.com/image.jpg"
            value={manualImage}
            onChange={handleManualImageChange}
            error={!isValidUrl && manualImage.length > 0}
            helperText={!isValidUrl && manualImage ? 'Invalid image URL' : ''}
          />

          {isValidUrl && manualImage && (
            <div className="manual-image_container">
              <Skeleton className="image-loading_background" />
              <img src={manualImage} alt="Tray" />
            </div>
          )}
        </div>
      </div>

      <div className="camera-section">
        <div className="camera-container">
          {videoRef === null || mediaStream === null ? (
            <div className="camera-placeholder">
              <Skeleton className="feed" />
              <Typography variant="body2">Initializing camera...</Typography>
            </div>
          ) : (
            <>
              <div className="camera-controls">
                <FormControl className="camera-select_container">
                  <LoadingButton
                    variant="contained"
                    loading={false}
                    onClick={handleToggleCameraMenu}
                    startIcon={<FlipCameraIos />}
                    className="camera-switch"
                  >
                    Switch Camera
                  </LoadingButton>

                  {isCameraMenuOpen && (
                    <div className="camera-options">
                      {cameras.map((camera: any) => (
                        <div
                          key={camera.deviceId}
                          className={`camera-option ${
                            camera.deviceId === activeCamera?.deviceId
                              ? 'active'
                              : ''
                          }`}
                          onClick={() => handleSelectCamera(camera)}
                        >
                          <Typography className="label">
                            {camera.label}
                          </Typography>
                          <Typography className="type">
                            {camera.cameraType}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}
                </FormControl>

                <div className="capture-and-preview">
                  <LoadingButton
                    variant="contained"
                    className="capture-button"
                    onClick={handleCapture}
                  >
                    {image ? (
                      <>
                        <Replay className="icon" />
                        <span>Retake</span>
                      </>
                    ) : (
                      <>
                        <CameraAlt className="icon" />
                        <span>Capture</span>
                      </>
                    )}
                  </LoadingButton>

                  {image.length > 0 && (
                    <div className="preview-container">
                      <img
                        className="captured-img"
                        src={image}
                        alt="Captured Tray"
                      />
                      <Typography variant="caption" className="preview-label">
                        Preview
                      </Typography>
                    </div>
                  )}
                </div>
              </div>

              <video
                className="feed"
                ref={videoRef}
                autoPlay
                muted
                playsInline
              />
            </>
          )}
        </div>

        {isSubmitting && (
          <div className="loading-overlay">
            <Player
              autoplay
              loop
              src="https://lottie.host/70fc766e-3d02-4e03-be88-a640ee0daaed/ewUXiG4fm5.json"
              className="loading-atom"
            />
            <Typography variant="h6">Analyzing tray...</Typography>
          </div>
        )}

        <div className="action-container">
          {image.length > 0 || manualImage.length > 0 ? (
            <LoadingButton
              disabled={isSubmitting}
              loading={isSubmitting}
              variant="contained"
              onClick={handleSubmitClick}
              className="submit-button"
              size="large"
            >
              Digitize Tray
            </LoadingButton>
          ) : (
            !isSubmitting && (
              <div className="instructions">
                <Typography variant="h6" className="instruction-title">
                  Capture Instructions
                </Typography>
                <Typography variant="body2">
                  1. Load the scanned product into the tray
                </Typography>
                <Typography variant="body2">
                  2. Place your tray in a well-lit area
                </Typography>
                <Typography variant="body2">
                  3. Center the tray in the camera view
                </Typography>
                <Typography variant="body2">
                  4. Click the capture button when ready
                </Typography>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default CaptureTrayDialog
