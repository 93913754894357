import {
  GenericObject,
  AssetData,
  Facility,
  OptionObjectPair,
  Scan,
  AssetsGroupedBySubCategory,
  ScansGroupedBySubCategory,
  AssetsGroupedByCategory,
  ProcedureStatus,
  AssetsGroupedByAssetType,
  AssetStatus,
  DerivedSurgeryStatus,
  ManualInputFormData,
  NewAsset,
  Surgery,
} from 'common/types'
import dayjs from 'lib/dayjs'
import { facility } from 'common/facilityMigration'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'
import { DecrementUsageItem } from 'lib/services/api/product-service/useUseItems/types'

const slugify = (str: string) =>
  str
    .replace(/[^a-z0-9]/gi, ' ')
    .trim()
    .replace(/\s+/g, '_')
    .toLowerCase()

export const createGroupingKey = (data: unknown[]) =>
  Object.values(data)
    .filter((value) => value)
    .map((value) => (typeof value === 'string' ? slugify(value) : value))
    .join('-')
    .toLowerCase()

export const deriveMetadataFromAssetForm = (
  assetForm: ManualInputFormData
): NewAsset => ({
  deviceDescription: assetForm.deviceDescription || null,
  deviceId: assetForm.deviceId || null,
  deviceCount: assetForm.deviceCount || 1,
  expirationDate: assetForm.expirationDate
    ? dayjs(assetForm.expirationDate).toISOString()
    : null,
  lotBatch: assetForm.lotBatch || null,
  versionModelNumber: assetForm.versionModelNumber || null,
  serialNumber: assetForm.serialNumber || null,
  catalogNumber: assetForm.catalogNumber || null,
  manufacturingDate: assetForm.manufacturingDate
    ? dayjs(assetForm.manufacturingDate).toISOString()
    : null,
  udi: assetForm.udi || null,
  company: {
    name: assetForm.companyName,
  },
})

export function deriveAssetNameFromGUDID(
  assetQueryData: GenericObject
): string | null {
  const assetName =
    assetQueryData.data?.gudid?.device?.gmdnTerms?.gmdn?.[0]?.gmdnPTName ??
    assetQueryData.data?.productCodes?.[0]?.deviceName

  return assetName ?? null
}

export function deriveAssetDetailsFromAssetData(assetData: AssetData) {
  const _id = assetData._id
  const assetDescription = assetData.deviceDescription
  const deviceId = assetData.deviceId
  const deviceCount = assetData.deviceCount
  const scans = assetData.scans
  const totalCount = assetData.total
  const assetTray = assetData.assetTray
  const isManualAddition = assetData.isManualAddition
  const expirationDate = assetData.scans?.[0]?.expirationDate
  const manufacturingDate = assetData.scans?.[0]?.manufacturingDate
  const lotBatch = assetData.scans?.[0]?.lotBatch
  const serialNumber = assetData.scans?.[0]?.serialNumber
  const catalogNumber = assetData.scans?.[0]?.catalogNumber
  const companyName = assetData.scans?.[0]?.companyName
  const versionModelNumber = assetData.scans?.[0]?.versionModelNumber
  const sizeText = assetData.scans?.[0]?.sizeText
  const bidAssetId = assetData.scans?.[0].bidAssetId
  const bidAssetInstanceId = assetData.scans?.[0].bidAssetInstanceId
  const secondaryDeviceIdType = assetData.scans?.[0].secondaryDeviceIdType
  const accurateDeviceCount = assetData.accurateDeviceCount
  const deviceCountCorrectiveActionTaken =
    assetData.deviceCountCorrectiveActionTaken || false
  const actualDeviceCount = assetData.actualDeviceCount || null
  const memo = assetData.memo || null
  const parentCompany = assetData.scans[0].parentCompany
  const parentCompanyId = assetData.scans[0].parentCompanyId

  return {
    _id,
    assetDescription,
    sizeText,
    deviceId,
    deviceCount,
    isManualAddition,
    companyName,
    expirationDate,
    manufacturingDate,
    lotBatch,
    serialNumber,
    catalogNumber,
    versionModelNumber,
    scans,
    totalCount,
    assetTray,
    bidAssetId,
    bidAssetInstanceId,
    secondaryDeviceIdType,
    accurateDeviceCount,
    deviceCountCorrectiveActionTaken,
    actualDeviceCount,
    memo,
    parentCompany,
    parentCompanyId,
  }
}

export const subCategoryConfig = [
  {
    finalDisposition: 'implanted',
    sortByField: 'implantSite',
  },
  {
    finalDisposition: 'explanted',
    sortByField: 'implantSite',
  },
  { finalDisposition: 'wasted', sortByField: 'wastedReason' },
  { finalDisposition: 'other', sortByField: '' },
]

export const groupScansBySubCategory = ({
  scans,
  facility,
}: {
  scans: Scan[]
  facility: Facility
}) => {
  const { wastedReasons } = facility
  let result: ScansGroupedBySubCategory = {}

  subCategoryConfig.forEach((subCategoryOption) => {
    const finalDisposition = subCategoryOption.finalDisposition
    result[finalDisposition] = {}

    scans.forEach((scan: Scan) => {
      const sortByField = (
        scan[subCategoryOption.sortByField as keyof Scan] as string
      )?.toLowerCase()
      const expDate = scan.expirationDate
      const sortByFieldIndex: any =
        finalDisposition === 'wasted'
          ? wastedReasons.find(
              (wastedReason: OptionObjectPair) =>
                wastedReason.value === sortByField
            )?.label
          : sortByField

      result[finalDisposition][sortByFieldIndex] = { number: 0, expDate: null }

      if (
        sortByFieldIndex &&
        result[finalDisposition][sortByFieldIndex].number === 0
      ) {
        result[finalDisposition][sortByFieldIndex].number = 1
        result[finalDisposition][sortByFieldIndex].expDate = expDate
      } else if (
        sortByFieldIndex &&
        result[finalDisposition][sortByFieldIndex].number
      ) {
        result[finalDisposition][sortByFieldIndex].number++
      }
    })
  })

  return result
}

export const groupAssetsBySubCategory = ({
  procedureAssets,
}: {
  procedureAssets: AssetData[]
}) => {
  const { wastedReasons } = facility
  let result: AssetsGroupedBySubCategory = {}

  subCategoryConfig.forEach((subCategoryOption) => {
    const finalDisposition = subCategoryOption.finalDisposition
    result[finalDisposition] = {}

    procedureAssets.forEach((assetGroup: AssetData) => {
      const { scans, ...assetDetails } =
        deriveAssetDetailsFromAssetData(assetGroup)

      scans.forEach((scan: Scan) => {
        // Consumables have no finalDisposition, classified as 'other'
        const isConsumableIndex =
          finalDisposition === 'other' && scan.assetType === 'consumable'
        const sortByField = scan[subCategoryOption.sortByField as keyof Scan]
        const sortByFieldIndex: any =
          finalDisposition === 'wasted'
            ? wastedReasons.find(
                (wastedReason: OptionObjectPair) =>
                  wastedReason.value === sortByField
              )?.label
            : isConsumableIndex
            ? 'Consumable'
            : sortByField

        const assetObject = {
          assetDetails,
          scans,
          scanIds: [scan._id],
        }

        const bidAssetId = assetDetails.bidAssetId

        if (sortByFieldIndex && !result[finalDisposition][sortByFieldIndex]) {
          result[finalDisposition][sortByFieldIndex] = {
            [bidAssetId]: assetObject,
          }
        } else if (
          sortByFieldIndex &&
          result[finalDisposition][sortByFieldIndex] &&
          !result[finalDisposition][sortByFieldIndex][bidAssetId]
        ) {
          result[finalDisposition][sortByFieldIndex][bidAssetId] = assetObject
        } else if (
          sortByFieldIndex &&
          result[finalDisposition][sortByFieldIndex] &&
          result[finalDisposition][sortByFieldIndex][bidAssetId]
        ) {
          result[finalDisposition][sortByFieldIndex][bidAssetId].scanIds.push(
            scan._id
          )
        }
      })
    })
  })

  return result
}

export const compareAssetsAlphabetically = (a: AssetData, b: AssetData) =>
  a.deviceDescription < b.deviceDescription
    ? -1
    : a.deviceDescription > b.deviceDescription
    ? 1
    : 0

export const groupAssetsByAssetType = (
  ungroupedAssets: AssetData[],
  assetTypes: OptionObjectPair[]
) => {
  const result: AssetsGroupedByAssetType = {
    other: [],
  }

  assetTypes.forEach((assetType) => {
    result[assetType.value] = []
  })

  ungroupedAssets
    .slice()
    .sort(compareAssetsAlphabetically)
    .forEach((asset) => {
      asset.scans[0]?.assetType in result
        ? result[asset.scans[0].assetType].push(asset)
        : result['other'].push(asset)
    })

  return result
}

export const groupAssets = (ungroupedAssets: AssetData[]) => {
  const result: AssetsGroupedByCategory = {
    plates: [],
    mesh: [],
    screws: [],
    other: [],
    consumables: [],
  }

  ungroupedAssets
    .slice()
    .sort(compareAssetsAlphabetically)
    .forEach((asset) => {
      const deviceDescription = asset.deviceDescription
      if (
        deviceDescription.includes('PLATE') ||
        deviceDescription.includes('TAB')
      ) {
        result.plates.push(asset)
      } else if (deviceDescription.includes('MESH')) {
        result.mesh.push(asset)
      } else if (deviceDescription.includes('SCREW')) {
        result.screws.push(asset)
      } else if (asset.implanted === 0 && asset.wasted === 0) {
        result.consumables?.push(asset)
      } else {
        result.other.push(asset)
      }
    })

  return result
}

interface GroupedScanStatuses {
  [key: string]: {
    [key: string]: AssetStatus[]
  }
}

export const groupScanStatuses = (scans: Scan[]) => {
  const groupedStatuses: GroupedScanStatuses = {}
  scans.forEach((scan) => {
    let statusTarget = groupedStatuses[scan.status.name]

    if (!statusTarget) {
      groupedStatuses[scan.status.name] = {}
      statusTarget = groupedStatuses[scan.status.name]
    }

    let userTarget = statusTarget[scan.status.userId]

    if (!userTarget) {
      statusTarget[scan.status.userId] = []
      userTarget = statusTarget[scan.status.userId]
    }

    userTarget.push(scan.status)
  })

  return groupedStatuses
}

export const deriveSurgeryStatus = ({
  statusName,
  assetGroups = [],
}: {
  statusName?: ProcedureStatus['name']
  assetGroups: AssetData[]
}): DerivedSurgeryStatus => {
  const status: DerivedSurgeryStatus = {
    name: statusName ?? 'SCANNING',
    workflow: 'rep',
    hasImplantableHardware: false,
    hasImplantableBiologic: false,
    totalCount: 0,
    hasImplantableOther: false,
    totalHardwareCount: 0,
    totalImplantableBiologicCount: 0,
    totalImplantableOtherCount: 0,
    totalConsumableCount: 0,
    totalExplantedCount: 0,
    totalAssociatedCount: 0,
    totalCost: 0,
    totalHardwareCost: 0,
    totalImplantableBiologicCost: 0,
    totalImplantableOtherCost: 0,
    totalConsumableCost: 0,
    totalExplantedCost: 0,
    totalAssociatedCost: 0,
    scans: [],
    implantableScans: [],
    implantableScansByStatus: {
      SCANNED: [],
      PRE_APPROVAL: [],
      APPROVED: [],
    },
    implantableScanIdsByStatus: {
      SCANNED: [],
      PRE_APPROVAL: [],
      APPROVED: [],
    },
  }

  // Get all scans
  status.scans = assetGroups.flatMap((asset: AssetData) =>
    asset.scans.map((scan) => ({
      ...scan,
      bidCompanyId: asset.bidCompanyId,
    }))
  )

  const nonBiologicals = status.scans.filter(
    (scan: Scan) => scan.assetType === 'non-biological'
  )

  // If no scans, return default status
  if (!status.scans.length) {
    return status
  }

  const countAssets = (
    assetGroups: AssetData[],
    filterCondition: (scan: Scan) => any
  ) => {
    return assetGroups.reduce((total: number, group: AssetData) => {
      return (
        total +
        group.scans.reduce((count: number, scan: Scan) => {
          const isMultiPackScan = isMultiPack(scan)

          const result = filterCondition(scan)
            ? isMultiPackScan
              ? group.packsUsed ?? 1
              : count + (scan.count ?? 1)
            : count

          return result
        }, 0)
      )
    }, 0)
  }

  const calculateAssetCost = (
    assetGroups: AssetData[],
    filterCondition: (scan: Scan) => boolean
  ) => {
    return assetGroups.reduce((totalCost: number, group: AssetData) => {
      return (
        totalCost +
        group.scans.reduce((groupCost: number, scan: Scan) => {
          // Check if the scan meets the filter condition
          if (filterCondition(scan)) {
            // Calculate the cost for the current scan
            const scanCost = scan.cost ?? 0
            const scanCount = scan.count ?? 1
            const costForScan = scanCost * scanCount

            // Add the cost for the current scan to the group's total cost
            groupCost += costForScan
          }

          return groupCost
        }, 0)
      )
    }, 0)
  }

  status.totalCount = countAssets(
    assetGroups,
    (scan: Scan) => scan.assetType !== 'consumable'
  )
  status.totalCost = calculateAssetCost(
    assetGroups,
    (scan: Scan) => scan.assetType !== 'consumable'
  )

  status.totalConsumableCount = countAssets(
    assetGroups,
    (scan: Scan) => scan.assetType === 'consumable'
  )
  status.totalConsumableCost = calculateAssetCost(
    assetGroups,
    (scan: Scan) => scan.assetType === 'consumable'
  )

  status.totalExplantedCount = countAssets(
    assetGroups,
    (scan: Scan) => scan.implantStatus === 'EXPLANTED'
  )
  status.totalExplantedCost = calculateAssetCost(
    assetGroups,
    (scan: Scan) => scan.implantStatus === 'EXPLANTED'
  )

  status.totalHardwareCount = countAssets(
    assetGroups,
    (scan: Scan) =>
      scan.assetType === 'non-biological' &&
      scan.implantStatus !== 'EXPLANTED' &&
      scan.implantStatus !== 'ASSOCIATED_ASSET'
  )
  status.totalHardwareCost = calculateAssetCost(
    assetGroups,
    (scan: Scan) =>
      scan.assetType === 'non-biological' &&
      scan.implantStatus !== 'EXPLANTED' &&
      scan.implantStatus !== 'ASSOCIATED_ASSET'
  )

  status.totalImplantableBiologicCount = countAssets(
    assetGroups,
    (scan: Scan) =>
      scan.assetType === 'biological' && scan.implantStatus !== 'EXPLANTED'
  )
  status.totalImplantableBiologicCost = calculateAssetCost(
    assetGroups,
    (scan: Scan) =>
      scan.assetType === 'biological' && scan.implantStatus !== 'EXPLANTED'
  )

  status.totalImplantableOtherCount = countAssets(
    assetGroups,
    (scan: Scan) =>
      scan.assetType === 'other-non-biological' &&
      scan.implantStatus !== 'EXPLANTED'
  )
  status.totalImplantableOtherCost = calculateAssetCost(
    assetGroups,
    (scan: Scan) =>
      scan.assetType === 'other-non-biological' &&
      scan.implantStatus !== 'EXPLANTED'
  )

  status.totalAssociatedCount = countAssets(
    assetGroups,
    (scan: Scan) => scan.implantStatus === 'ASSOCIATED_ASSET'
  )
  status.totalAssociatedCost = calculateAssetCost(
    assetGroups,
    (scan: Scan) => scan.implantStatus === 'ASSOCIATED_ASSET'
  )

  status.totalAssociatedCount = countAssets(
    assetGroups,
    (scan: Scan) => scan.implantStatus === 'ASSOCIATED_ASSET'
  )

  nonBiologicals.reduce((a: number, b: Scan) => {
    if (b.implantStatus !== 'EXPLANTED') {
      return a + (b.count ?? 1)
    }
    return a
  }, 0)

  status.implantableScans = nonBiologicals

  nonBiologicals.forEach((scan: Scan) => {
    if (scan.status.name in status.implantableScansByStatus) {
      status.implantableScansByStatus[
        scan.status.name as AssetStatus['name']
      ].push(scan)

      status.implantableScanIdsByStatus[
        scan.status.name as AssetStatus['name']
      ].push(scan._id)
    }
  })

  status.hasImplantableHardware = status.totalHardwareCount > 0

  status.hasImplantableBiologic = status.totalImplantableBiologicCount > 0

  status.hasImplantableOther = status.totalImplantableOtherCount > 0

  // If status is SCANNING, it may be PRE_APPROVAL, or APPROVED
  if (statusName === 'SCANNING') {
    // If there are no implantable scans, it's automatically APPROVED
    if (!status.hasImplantableHardware) {
      status.name = 'APPROVED'
    } else {
      // If there are any PRE_APPROVAL scans, it's PRE_APPROVAL
      const preApprovalScans = status.implantableScans.filter((scan) => {
        return scan.status.name === 'PRE_APPROVAL'
      })

      if (preApprovalScans.length) {
        status.name = 'PRE_APPROVAL'

        // If no company is set on PRE_APPROVAL, it's no-rep workflow
        if (preApprovalScans[0].status.sendToCompany === null) {
          status.workflow = 'no-rep'
        }
      } else {
        // If they are all APPROVED, it's APPROVED
        const isAllApproved = status.implantableScans.every(
          (scan) => scan.status.name === 'APPROVED'
        )

        if (isAllApproved) {
          status.name = 'APPROVED'

          // If no company is set on APPROVED, it's no-rep workflow
          if (status.implantableScans[0].status.sendToCompany === null) {
            status.workflow = 'no-rep'
          }
        }
      }
    }
  }

  return status
}

export const getStatusDetails = (
  surgery: Surgery,
  statusName: ProcedureStatus['name']
) =>
  [...surgery?.statuses]
    .reverse()
    .find((status: ProcedureStatus) => status.name === statusName)

export const getCountFromScans = (scans: Scan[]) =>
  scans.reduce((prev: number, curr: Scan) => {
    const total = curr.count ?? 1
    return prev + total
  }, 0)

export const getUDIsWithQuantity = (
  assetGroups: AssetData[]
): DecrementUsageItem[] => {
  const itemMap: {
    [key: string]: { quantity: number; betterIdProductId: number }
  } = {}
  const uniqueGroups = new Map<string, AssetData>()
  assetGroups.forEach((group) => uniqueGroups.set(group._id, group))
  const uniqueGroupsArray: AssetData[] = Array.from(uniqueGroups.values())

  uniqueGroupsArray.forEach((group) => {
    const groupMap: { [key: string]: boolean } = {}
    group.scans.forEach((scan) => {
      const key = scan.udi ?? `bidAssetId_${scan.bidAssetId}`
      const currentQuantity = itemMap[key]?.quantity ?? 0
      const betterIdProductId = scan.bidAssetId

      if (isMultiPack(scan)) {
        if (!groupMap[key]) {
          itemMap[key] = {
            quantity: group.packsUsed,
            betterIdProductId: Number(betterIdProductId),
          }
          groupMap[key] = true
        }
      } else {
        itemMap[key] = {
          quantity: currentQuantity + (scan.count ?? 1),
          betterIdProductId: Number(betterIdProductId),
        }
      }
    })
  })

  const items: DecrementUsageItem[] = Object.keys(itemMap).map((key) => ({
    quantity: itemMap[key].quantity,
    udi: key.startsWith('bidAssetId_') ? undefined : key,
    betterIdProductId: itemMap[key].betterIdProductId,
  }))

  return items
}

export const isMultiPack = (scan: Scan): boolean => {
  const {
    isRfMultipackConsumable,
    isSpongeConsumable,
    isMultipackHardware,
    isMultipackConsumable,
  } = parseAssetIdentifiers({
    deviceDescription: scan.deviceDescription,
    deviceCount: scan.deviceCount,
    idType: scan.secondaryDeviceIdType,
    assetType: scan.assetType,
  })

  const isExplanted = scan.implantStatus === 'EXPLANTED'
  const multipacks =
    isMultipackConsumable ||
    isSpongeConsumable ||
    isRfMultipackConsumable ||
    (isMultipackHardware && !isExplanted)

  return multipacks
}
