import * as React from 'react'
import { Box, Typography, Button, LinearProgress } from '@mui/material'
import { useCortexDecoderContext } from 'lib/batchScanner'

interface TrayScannerProps {
  children: ({
    setIsTrayScannerOpen,
  }: {
    isTrayScannerOpen: boolean
    setIsTrayScannerOpen: (val: boolean) => void
    registerTrayScanHandler: (handler: (scan: string) => void) => void
  }) => React.ReactNode
}

export function TrayScanner({ children }: TrayScannerProps) {
  const {
    currentScan,
    setCurrentScan,
    trayScannerState,
    setEnabled,
    isActive,
  } = useCortexDecoderContext()
  const scanHandlerRef = React.useRef<(scan: string) => void>()

  const currentScanData = currentScan?.barcodeData
  const prevScanRef = React.useRef<string | undefined>(currentScanData)

  const maybeSetTrayScanner = trayScannerState?.[1]
  const isTrayScannerOpen = trayScannerState?.[0] ?? false
  const setIsTrayScannerOpen = React.useCallback(
    (val: boolean) => {
      if (maybeSetTrayScanner) {
        setEnabled(val)
        maybeSetTrayScanner(val)
      }
    },
    [maybeSetTrayScanner, setEnabled]
  )

  React.useEffect(() => {
    setCurrentScan(undefined)
    return () => {
      setIsTrayScannerOpen(false)
      setCurrentScan(undefined)
    }
  }, [setIsTrayScannerOpen, setCurrentScan])

  React.useEffect(() => {
    if (
      scanHandlerRef.current &&
      currentScanData &&
      currentScanData !== prevScanRef.current
    ) {
      prevScanRef.current = currentScanData
      scanHandlerRef.current?.(currentScanData)
      setCurrentScan(undefined)
      setIsTrayScannerOpen(false)
    }
  }, [currentScanData, setIsTrayScannerOpen, setCurrentScan])

  return (
    <>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          zIndex: isTrayScannerOpen ? 1 : -100,
        }}
      >
        {!isActive && (
          <Box
            width="100vw"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            zIndex={1}
            position="absolute"
            sx={{
              backgroundColor: 'white',
            }}
          >
            <Box textAlign="center">
              <Typography
                variant="h3"
                color="grey.800"
                my={6}
                letterSpacing={1}
                fontWeight={500}
                fontSize={18}
              >
                Preparing Image Capture
              </Typography>
              <LinearProgress />
            </Box>
          </Box>
        )}
        <Button
          sx={{ bottom: '72px', position: 'fixed', zIndex: 999999999999999 }}
          variant="contained"
          onClick={() => setIsTrayScannerOpen(false)}
          disabled={!isActive}
        >
          Close Image Capture
        </Button>
      </div>
      <div style={{ zIndex: isTrayScannerOpen ? -100 : 1 }}>
        {children({
          isTrayScannerOpen,
          setIsTrayScannerOpen,
          registerTrayScanHandler: (handler) =>
            (scanHandlerRef.current = handler),
        })}
      </div>
    </>
  )
}
