import { LoadingButton } from 'components/mui'
import TrayMapSelector from 'components/organisms/TrayMapSelector/TrayMapSelector'
import ConfirmTrayImageModal from 'components/organisms/ConfirmTrayImageModal/ConfirmTrayImageModal'
import './LoadSPDTrayMap.scss'
import { useState, useRef, useEffect } from 'react'
import InventoryProvider from 'lib/apollo/inventory-config'
import { useSPD } from '../SPD.context'
import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'
import { Typography } from '@mui/material'

const LoadSPDTrayMap = ({
  onNext,
  trayDetails,
}: {
  onNext: (stepNumber?: number) => void
  trayDetails: TrayItemProductDetails
}) => {
  const { onlyUseManualWorkflow, activeStep } = useSPD()
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [isScrollable, setIsScrollable] = useState(false)
  const pageRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkScrollable = () => {
      // Check if content is taller than viewport
      const viewportHeight = window.innerHeight;
      if (pageRef.current) {
        const contentHeight = pageRef.current.getBoundingClientRect().height;
        const isContentTallerThanViewport = contentHeight > viewportHeight;
        
        // Show indicator if content is taller and we're near the top
        setIsScrollable(isContentTallerThanViewport && window.scrollY < 100);
      }
    };

    // Initial check
    checkScrollable();

    // Check after a short delay to account for dynamic content
    const timeoutId = setTimeout(checkScrollable, 100);

    // Add resize listener
    window.addEventListener('resize', checkScrollable);

    // Handle scroll
    const handleScroll = () => {
      const isNearTop = window.scrollY < 100;
      const isNearBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100;
      
      if (pageRef.current) {
        const contentHeight = pageRef.current.getBoundingClientRect().height;
        const isContentTallerThanViewport = contentHeight > window.innerHeight;
        
        // Show indicator only when near top and content is scrollable
        setIsScrollable(isContentTallerThanViewport && isNearTop && !isNearBottom);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', checkScrollable);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeStep]); // Re-run when activeStep changes as it affects content height

  const getInstructionText = () => {
    const baseText = (text: string) => (
      <Typography variant="body1" className="instruction-text">
        <span className="instruction-header">Tray Map Instructions:</span>
        {text}
      </Typography>
    )

    if (onlyUseManualWorkflow) {
      if (activeStep === 0) {
        return baseText(
          ' Select a screw, plate, or drill location where you wish to load the product you will scan.'
        )
      } else if (activeStep === 2) {
        return baseText(
          ' Review the selected screw, plate, and drill areas where products have been placed. To continue:' +
            '\n• Click "Add Another Product" to scan additional items' +
            '\n• Click directly on any area to add or remove items' +
            '\n• Select "Done With This Tray" when finished'
        )
      }
    } else if (activeStep === 2) {
      return baseText(
        ' Review the screw, plate, and drill areas where products have been placed. To continue:' +
          '\n• Click "Add Another Product" to scan additional items' +
          '\n• Click directly on any area to add or remove items' +
          '\n• Select "Done With This Tray" when finished'
      )
    }
    return null
  }

  const handleDoneClick = () => {
    setIsConfirmModalOpen(true)
  }

  const handleConfirmImage = () => {
    setIsConfirmModalOpen(false)
  }

  const instructionText = getInstructionText()

  return (
    <div className="load-spd-tray-map_container">
      <div
        ref={pageRef}
        className={`load-spd-tray-map_page ${isScrollable ? 'is-scrollable' : ''}`}
        style={{ marginTop: onlyUseManualWorkflow ? '40px' : '0px' }}
      >
        {instructionText && (
          <div
            className="mode-banner map-mode"
            role="region"
            aria-label="Instructions"
          >
            {instructionText}
          </div>
        )}

        <div className="tray-map-container">
          <TrayMapSelector isSPD={true} trayType={trayDetails.trayType} />
        </div>

        {((onlyUseManualWorkflow && activeStep === 2) ||
          !onlyUseManualWorkflow) && (
          <div className="buttons_container">
            <LoadingButton
              onClick={() => onNext(0)}
              className="action-button add-product"
              variant="outlined"
            >
              Add Another Product
            </LoadingButton>
            <LoadingButton
              onClick={handleDoneClick}
              className="action-button done-tray"
              variant="contained"
              color="success"
            >
              Done With This Tray
            </LoadingButton>
          </div>
        )}
      </div>

      <InventoryProvider>
        <ConfirmTrayImageModal
          open={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={handleConfirmImage}
        />
      </InventoryProvider>
    </div>
  )
}

export default LoadSPDTrayMap
