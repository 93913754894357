import { useMemo } from 'react'
import dayjs, { humanizedMonthDateTimeFormat } from 'lib/dayjs'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import { StatusBar } from './molecules/StatusBar/StatusBar'
import { useOrganizationSettings } from 'lib/context/OrganizationSettingsContext'

type Props = {
  procedureStatus: string
  hasImplantableHardware?: boolean
  userName?: string
  scrubTechnician?: string
  timeSubmitted?: string
  isRep?: boolean
}

const formatDate = (date: any) =>
  dayjs(date).format(humanizedMonthDateTimeFormat)

const getStatusIndicatorDetails = ({
  procedureStatus,
  hasImplantableHardware,
  userName,
  timeSubmitted,
  scrubTechnician,
  isRep,
}: Props) => {
  const formattedDateTimeSubmitted = formatDate(timeSubmitted)
  // Initial configuration for procedureStatus === 'APPROVED'
  let message = hasImplantableHardware
    ? `All products in this record have been approved.`
    : `No products in this record require approval`
  let color = 'success'
  let icon = <CheckIcon color="success" fontSize="small" />

  if (procedureStatus === 'PRE_APPROVAL') {
    message = isRep
      ? 'Please scroll down, enter your name and approve'
      : 'Waiting for rep approval.'
    color = 'warning'
    icon = <CircularProgress size={16} color="warning" />
  }

  if (procedureStatus === 'SUBMITTED') {
    message = `Submitted on ${formattedDateTimeSubmitted}${
      userName && userName !== 'undefined undefined' ? ` by ${userName}` : ''
    }`
  }

  return { color, icon, message, isRendered: true }
}

function StatusIndicator(props: Props) {
  const { showScrubTechnician } = useOrganizationSettings()

  const { procedureStatus, scrubTechnician } = props
  const { color, icon, message, isRendered } = useMemo(
    () => getStatusIndicatorDetails(props),
    [props]
  )

  if (!isRendered) {
    return null
  }

  return (
    <>
      <StatusBar icon={icon} message={message} color={color} />
      {procedureStatus === 'SUBMITTED' &&
        showScrubTechnician &&
        scrubTechnician && (
          <StatusBar
            icon={undefined}
            message={`Scrub Technician: ${scrubTechnician}`}
            color={color}
          />
        )}
    </>
  )
}

export default StatusIndicator
