// Components
import { Typography } from '@mui/material'
import LandingIcon from './LandingIcon/LandingIcon'

// Types
import { MainIconFlowState } from 'common/types'

// Logic
import { useMainIconLanding } from './MainIconLanding.logic'

// Styles
import classNames from 'classnames'
import './MainIconLanding.scss'

// Other
import { Helmet } from 'react-helmet'
import { CustomModal } from 'components/molecules/Modal/Modal'
import { MainIconLandingProps } from './MainIconLanding.types'
import { FC } from 'react'

const MainIconLanding: FC<MainIconLandingProps> = ({ surgery }) => {
  const {
    handleChangeAssetType,
    icons,
    loading,
    isMobile,
    twoColumnsClass,
    showConsumablesAutopopulate,
    populateConsumables,
  } = useMainIconLanding({ surgery })

  return (
    <div
      className={classNames('main-icon-landing_wrapper', {
        'is-mobile': isMobile,
      })}
    >
      <Helmet>
        <title>Home</title>
      </Helmet>

      <Typography variant="h1" align="center">
        Select Product Type
      </Typography>

      <div className={`icons-container ${twoColumnsClass}`}>
        {Object.entries(icons).map(
          ([key, { IconComponent, label, dataTestId, isVisible }]) => {
            return isVisible === false ? null : (
              <LandingIcon
                key={key}
                isMobile={isMobile}
                onClick={() =>
                  handleChangeAssetType(
                    label?.toUpperCase() as MainIconFlowState['assetTypeLabel']
                  )
                }
                icon={IconComponent}
                label={label}
                dataTestId={dataTestId}
              />
            )
          }
        )}
      </div>

      <CustomModal
        header="Consumables Autopopulate"
        fitContentWidth={!isMobile}
        open={showConsumablesAutopopulate}
        isLoadingState={loading}
        primaryButtonText="YES"
        secondaryButtonText="NO"
        onCloseTakeSecondaryButtonAction={false}
        secondaryButtonAction={() => populateConsumables(false)}
        handleOnSubmit={() => populateConsumables(true)}
      >
        <Typography>
          A preference card was found for this surgery, Would you like to add
          all the consumables from it?
        </Typography>
      </CustomModal>
    </div>
  )
}

export default MainIconLanding
