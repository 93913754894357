import { Typography } from '@mui/material'
import { getMultipackItemNumber } from './getMultipackNumber'
import { isDatePast } from './isDatePast'

// this is for when we need to pass dynamic data into the alert msg genrator, i.e. an expiratoion date
export interface formValidationAlertMsgContext {
  expirationDate?: string
  numberOfExpired?: string
  numberOfExpiredHardware?: string
}

interface GenFormValidationAlertMsgProps {
  path: string
  pathArray?: string[]
  context?: formValidationAlertMsgContext
  orgId?: string
}

export const genFormValidationAlertMsg = ({
  path,
  pathArray,
  context,
}: GenFormValidationAlertMsgProps): string => {
  let itemNumber
  // only a multipack if item with similar path has index greater than 0
  const multipack =
    path.includes('-') &&
    pathArray?.find(
      (p: string) =>
        p.split('-')[0] === path.split('-')[0] && Number(p.split('-')[1]) > 0
    )
  if (multipack) {
    itemNumber = getMultipackItemNumber(path)
  }

  let message

  switch (
    path.split('-')[0] // note: only use '-' in pathnames for multipacks to keep track of index
  ) {
    case 'expirationDate':
      const expirationDate = isDatePast({
        date: context!.expirationDate!,
      }).expDate
      message = multipack
        ? `Product #${itemNumber} expired on ${expirationDate}. Are you sure you want to continue?`
        : `This product expired on ${expirationDate}. Are you sure you want to continue?`
      break
    case 'numberOfExpired':
      message = `The product list contains ${
        context!.numberOfExpired
      } expired product(s). Are you sure you want to continue?`
      break
    case 'numberOfExpiredHardware':
      message = `The product list contains ${
        context!.numberOfExpiredHardware
      } expired hardware product(s). Are you sure you want to continue?`
      break
    case 'packagingOk':
      message = multipack ? (
        <Typography variant="h6">
          Packaging for product #{itemNumber} was documented as{' '}
          <strong>not intact.</strong> Are you sure you want to continue?
        </Typography>
      ) : (
        <Typography variant="h6">
          Packaging for this product was documented as{' '}
          <strong>not intact.</strong> Are you sure you want to continue?
        </Typography>
      )
      break
    case 'temperatureOk':
      message = multipack ? (
        <Typography variant="h6">
          Temperature for product #{itemNumber}was documented as{' '}
          <strong>out of acceptable range.</strong> Are you sure you want to
          continue?
        </Typography>
      ) : (
        <Typography variant="h6">
          Temperature for this product was documented as{' '}
          <strong>out of acceptable range.</strong> Are you sure you want to
          continue?
        </Typography>
      )
      break
    case 'remainingUndocumentedScans':
      message = `This record cannot be submitted to the EMR while products remain undocumented.`
      break
    case 'rfMultipackConsumable':
      message =
        'All sponge counts must be marked as correct or a corrective action must be taken before submitting to EMR'
      break
    case 'submitToEmrConfirm':
      message =
        'Finalizing the record will send it to EMR or PRINTER. Once the recorded has been Finalized, you will no longer be able to edit it.'
      break
    default:
      throw new Error(
        'Something went wrong with generating the form validation message'
      )
  }

  return message as string
}
