import { useEffect, useState } from 'react'

// Services
import { useCreateSurgery, useGetPatients } from 'lib/apollo/hooks'
import { useGetSurgeons } from 'lib/services/api/reports-service/preference-card/getSurgeons'
import useGetLocationsTree from 'lib/services/api/product-service/getLocationsTree'

// Types
import { useGetProcedureTypes } from 'lib/services/api/reports-service/preference-card/getProcedureTypes'
import { ProcedureFormValues } from './AddProcedure.types'
import { Option } from 'common/types'

// Router
import { useNavigate } from 'react-router-dom'

// Other
import dayjs from 'lib/dayjs'
import { useForm } from 'react-hook-form'
import { enqueueSnackbar } from 'notistack'
import { TreeLocation } from 'lib/services/api/product-service/getLocationsTree/types'
import { v4 as uuidv4 } from 'uuid'
import { AdminNewPatientResponse } from 'lib/services/api/reports-service/patients/admin-new-patients'

const useAddProcedureLogic = () => {
  const navigate = useNavigate()
  const { data, loading: isLoading } = useGetLocationsTree()
  const [isAdmitPatientModalOpen, setIsAdmitPatientModalOpen] = useState(false)
  const [patientModalId, setPatientModalId] = useState(uuidv4())

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ProcedureFormValues>({
    defaultValues: {
      patient: null,
      mrNumber: null,
      patientDob: null,
      timeOfProcedure: null,
      surgeon: null,
      procedure: null,
      procedureLocation: null,
    },
    mode: 'onChange',
  })

  const [create, { loading: isLoadingCreateSurgery }] = useCreateSurgery()
  const {
    data: rawPatients,
    loading: isLoadingPatients,
    refetch,
  } = useGetPatients({
    filter: undefined,
  })
  const { data: rawSurgeons, isLoading: isLoadingSurgeons } = useGetSurgeons()
  const { data: rawProcedures, isLoading: isLoadingProcedures } =
    useGetProcedureTypes()

  const patients: Option[] =
    rawPatients?.getPatients?.map((item) => ({
      id: item._id,
      name: `${item.firstName} ${item.lastName}`,
    })) ?? []
  const surgeons: Option[] =
    rawSurgeons?.surgeons?.map((item) => ({
      id: item.id,
      name: `${item.firstName} ${item.lastName}`,
    })) ?? []
  const procedures =
    rawProcedures?.procedures
      ?.filter((item) => item)
      ?.map((item) => ({
        id: item,
        name: item,
      })) || []

  const selectedPatient = rawPatients?.getPatients.find(
    (item) => item._id === watch('patient')?.id
  )

  const onSubmit = (data: ProcedureFormValues) => {
    if (!data) return

    const {
      patient,
      dateOfProcedure,
      procedure,
      procedureLocation,
      surgeon,
      operatingRoomNumber,
      timeOfProcedure,
    } = data
    create({
      variables: {
        surgery: {
          patientId: patient?.id as string,
          patientDob: watch('patientDob') as string,
          surgeonId: surgeon?.id as string,
          procedureName: procedure?.name as string,
          procedureLocation: procedureLocation?.name as string,
          dateOfProcedure: dayjs(dateOfProcedure).format(
            'YYYY-MM-DD'
          ) as string,
          timeOfProcedure: dayjs(timeOfProcedure).format('HH:mm'),
          operatingRoom: operatingRoomNumber,
        },
      },
    })
      .then((res) => {
        if (res.data?.createSurgery.success) {
          enqueueSnackbar('Procedure has been created successfully.', {
            variant: 'success',
          })
          navigate('/surgeries')
        } else {
          enqueueSnackbar(
            'Something went wrong white creating the procedure, Please try again.',
            {
              variant: 'error',
            }
          )
        }
      })
      .catch((err) => console.error(err))
  }

  const handleNavigateHome = () => {
    navigate('/surgeries')
  }

  const handleAddPatient = (patient: AdminNewPatientResponse) => {
    setIsAdmitPatientModalOpen(false)
    setPatientModalId(uuidv4())
    const { dateOfBirth, firstName, lastName, idMR, _id } = patient

    // To add the created patient to the list
    refetch()

    // To select the created patient
    setValue('patient.id', _id)
    setValue('patient.name', `${firstName || ''} ${lastName || ''}`)
    setValue('mrNumber', idMR)
    setValue('patientDob', dateOfBirth)
  }

  const getSecondLayerLocations = (
    locations?: TreeLocation[]
  ): TreeLocation[] => {
    // Get parent Ids - Departments - First layer
    if (!locations) return []
    const parentIds = new Set(
      locations
        .filter(
          (location) =>
            location.isAssignableToDepartment ||
            location.parentLocationId === null
        )
        .map((parent) => parent.id)
    )

    // Filter locations to get rooms only - second layer
    return locations.filter(
      (location) =>
        location.parentLocationId && parentIds.has(location.parentLocationId)
    )
  }

  useEffect(() => {
    if (selectedPatient) {
      setValue('patientDob', selectedPatient.dateOfBirth)
      setValue('mrNumber', selectedPatient.idMR)
    }
  }, [selectedPatient])

  const locations: Option[] =
    getSecondLayerLocations(data?.getLocationsTree.hospital.locations)?.map(
      (location) => ({
        id: location.id,
        name: location.name,
      })
    ) ?? []

  return {
    errors,
    control,
    patients,
    surgeons,
    isLoading,
    locations,
    procedures,
    isLoadingPatients,
    isLoadingSurgeons,
    isLoadingProcedures,
    isLoadingCreateSurgery,
    patientModalId,
    watch,
    handleAddPatient,
    onSubmit,
    handleSubmit,
    handleNavigateHome,
    isAdmitPatientModalOpen,
    setIsAdmitPatientModalOpen,
  }
}

export default useAddProcedureLogic
