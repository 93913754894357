import { ItemResponse } from 'common/types'
import { TrayItemProductDetails } from 'lib/services/api/product-service/receiveItems/types'

export const extractTrayJSONData = (trayItem: ItemResponse | null) => {
  if (!trayItem) {
    return undefined
  }

  // Try main productDetails first
  let trayDetails: TrayItemProductDetails | undefined
  try {
    trayDetails = trayItem.productDetails
      ? (JSON.parse(
          trayItem.productDetails as string
        ) as TrayItemProductDetails)
      : undefined

    // If no trayType found in main productDetails, check subItems
    if (!trayDetails?.trayType && trayItem.subItems?.[0]?.productDetails) {
      const subItemDetails = JSON.parse(trayItem.subItems[0].productDetails)
      if (subItemDetails.trayType) {
        trayDetails = {
          ...trayDetails,
          trayType: subItemDetails.trayType,
        } as TrayItemProductDetails
      }
    }
  } catch (error) {
    console.error('Error parsing tray details:', error)
    return undefined
  }

  return trayDetails
}
