import { useEffect, useState } from 'react'

// Router
import { useParams } from 'react-router-dom'

// Services
import { useGetPreferenceCards } from 'lib/services/api/reports-service/preference-card/getPreferenceCards'
import { useGetSurgeryQuery } from 'lib/apollo/hooks'
import { useDisablePolling } from 'lib/useDisablePolling'

// Types
import { SurgeryResult } from 'views/PreferenceCards/PreferenceCards.types'
import { PreferenceCardProps } from 'views/PreferenceCards/CardView/CardView.types'

// MUI
import { Grid } from '@mui/material'
import { Item } from './PreferenceCard.types'

const usePreferenceCardLogic = () => {
  const { surgeryId } = useParams<{ surgeryId: string }>()
  const [preferenceCard, setPreferenceCard] = useState<
    PreferenceCardProps | undefined
  >()
  const [accordionsToggle, setAccordionsToggle] = useState({
    procedureInformation: true,
    equipment: true,
    instrumentTrays: true,
    implants: true,
    biologics: true,
    consumables: true,
    linkedSalesReps: true,
  })

  // Services
  const disablePolling = useDisablePolling()
  const { data } = useGetSurgeryQuery(surgeryId, {
    disablePolling,
  })
  const result: SurgeryResult = data?.getSurgery
  const surgeonId = result?.visit.attendingProvider._id
  const procedureType = result?.procedures[0].description

  const { data: cardData, isLoading: isLoadingCard } = useGetPreferenceCards({
    surgeonId: surgeonId,
    procedureType: procedureType ?? '',
    enabled: Boolean(procedureType) && Boolean(surgeonId),
  })

  const filteredPositioningDevices =
    preferenceCard?.procedureDetails?.positioningDevices &&
    Object.entries(preferenceCard.procedureDetails.positioningDevices).filter(
      ([key, value]) => value
    )

  const filteredSkinPreps =
    preferenceCard?.procedureDetails?.skinPrep &&
    Object.entries(preferenceCard.procedureDetails.skinPrep).filter(
      ([key, value]) => value
    )

  const flattenedArray = preferenceCard?.equipment?.options
    ?.filter(Boolean)
    .flatMap((equipment) =>
      Object.entries(equipment).map(([key, value]) => ({ [key]: value }))
    )

  const formatOutput = (
    key: string,
    value: string | number | boolean
  ): string | null => {
    const isBoolean = typeof value === 'boolean'
    const isText = typeof value === 'string' || typeof value === 'number'

    if (isText && !value.toString().length) return null
    if (key === 'Side' || key === 'Degree') return null

    if (isBoolean) {
      return value ? 'Yes' : 'No'
    }

    if (isText) {
      return value ? value.toString() : 'None'
    }

    return null
  }

  const GridItem: React.FC<{ item: Item }> = ({ item }) => (
    <>
      {Object.entries(item).map(([key, value]) => {
        const output = formatOutput(key, value)
        if (output === null) return null

        return (
          <div className="item" key={key}>
            <div className="label">{key}</div>
            <div className="value">{output}</div>
          </div>
        )
      })}
    </>
  )

  const renderGridItems = (flattenedArray: Item[]): React.ReactNode => (
    <>
      {flattenedArray.map((item, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <GridItem item={item} />
        </Grid>
      ))}
    </>
  )

  useEffect(() => {
    if (
      cardData?.preference_cards?.length &&
      cardData?.preference_cards[0].card_details
    ) {
      setPreferenceCard(cardData?.preference_cards[0]?.card_details)
    }
  }, [cardData])

  return {
    cardData,
    isLoadingCard,
    preferenceCard,
    flattenedArray,
    accordionsToggle,
    filteredSkinPreps,
    filteredPositioningDevices,
    renderGridItems,
    setAccordionsToggle,
  }
}

export default usePreferenceCardLogic
