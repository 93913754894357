import { Button, Chip, Paper } from '@mui/material'
import { FilterList, Clear, Add } from '@mui/icons-material'
import { AnimatePresence, motion } from 'framer-motion'

import useTrayManagementFiltersLogic from './TrayManagementFilters.logic'

import './TrayManagementFilters.scss'
import SearchBar from '../SearchBar/SearchBar'
import FilterDrawer from '../FilterDrawer/FilterDrawer'

const TrayManagementFilters = () => {
  const {
    listOfFilters,
    trayIdFilterValue,
    handleTrayIdFilterChange,
    vendorOptions,
    companyFilterValue,
    setCompanyFilterValue,
    handleClearAllFilters,
    selectedFilters,
    selectedCategory,
    isFilterDrawerOpen,
    toggleFilterDrawer,
    getActiveFilters,
    handleRemoveFilter,
    handleAddTrayClick,
    handleFilterClick,
    handleSubFilterClick,
  } = useTrayManagementFiltersLogic()

  const activeFilters = getActiveFilters()

  return (
    <div className="tray-filters">
      <Paper className="filter-bar" elevation={1}>
        <div className="filter-bar-left">
          <SearchBar
            value={trayIdFilterValue}
            onChange={handleTrayIdFilterChange}
            placeholder="Search by Tray ID..."
          />

          <Button
            variant="outlined"
            onClick={toggleFilterDrawer}
            startIcon={<FilterList />}
            className={isFilterDrawerOpen ? 'active' : ''}
          >
            Filters {activeFilters.length > 0 && `(${activeFilters.length})`}
          </Button>
        </div>

        <div className="filter-bar-right">
          {activeFilters.length > 0 && (
            <Button
              variant="text"
              size="small"
              onClick={handleClearAllFilters}
              startIcon={<Clear />}
            >
              Clear filters
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleAddTrayClick}
          >
            Add Tray
          </Button>
        </div>
      </Paper>

      <AnimatePresence>
        {activeFilters.length > 0 && (
          <motion.div
            className="active-filters"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            {activeFilters.map((filter) => (
              <Chip
                key={filter.id}
                label={filter.label}
                onDelete={() => handleRemoveFilter(filter.id)}
                size="small"
                color="primary"
                variant="outlined"
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <FilterDrawer
        open={isFilterDrawerOpen}
        onClose={toggleFilterDrawer}
        filters={listOfFilters}
        selectedFilters={selectedFilters}
        selectedCategory={selectedCategory}
        vendorOptions={vendorOptions}
        companyFilterValue={companyFilterValue}
        setCompanyFilterValue={setCompanyFilterValue}
        handleFilterClick={handleFilterClick}
        handleSubFilterClick={handleSubFilterClick}
        handleClearAllFilters={handleClearAllFilters}
      />
    </div>
  )
}

export default TrayManagementFilters
