import { axiosInstanceIservices } from 'lib/services/config/axios-config'
import { TRestResponse } from '../restApi.types'
import { TOrganization } from './organizations.types'

export const getOrganization = async (
  orgId: string
): Promise<TRestResponse<TOrganization>> => {
  try {
    const { data: responseData } = await axiosInstanceIservices.get<
      TRestResponse<TOrganization>
    >(`/organizations/${orgId}`)
    return {
      success: responseData.success,
      message: responseData.message,
      data: responseData.data || undefined,
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: (error as Error).message,
    }
  }
}
