import * as React from 'react'
import { Box } from '@mui/material'
import AlertDialog from './AlertDialog/AlertDialog'
import { NumberInput } from 'components/NumberInput'

interface DeleteAssetDialogProps {
  deleteableCount: number
  isLoading: boolean
  handleDelete: (count: number) => void
  onCancel: () => void
  isConsumable?: boolean
  isMultipack?: boolean
}

export function DeleteAssetDialog({
  deleteableCount,
  isLoading,
  handleDelete,
  onCancel,
  isConsumable = false,
  isMultipack = false,
}: DeleteAssetDialogProps) {
  const [deleteCount, setDeleteCount] = React.useState(1)
  let assetTypeCopy = 'product'
  if (isConsumable) {
    assetTypeCopy = isMultipack ? 'pack' : 'consumable'
  }

  return (
    <AlertDialog
      isOpen={true}
      mode="warning"
      title="Delete Confirmation"
      description={`You are about to permanently remove ${deleteCount} ${assetTypeCopy}${
        deleteCount > 1 ? 's' : ''
      } from the product list. This action cannot be undone.`}
      positionAboveBottomNavigation
      position={{ top: '25%' }}
      isPrimaryDisabled={false}
      isSecondaryLoading={isLoading}
      primaryButtonAction={() => handleDelete(deleteCount)}
      primaryButtonText="Confirm Delete"
      secondaryButtonAction={onCancel}
      secondaryButtonText="Cancel"
      zIndex={6}
      loadingText="Deleting..."
      isPrimaryLoading={isLoading}
    >
      {deleteableCount > 1 && (
        <Box mt={2}>
          <NumberInput
            value={deleteCount}
            min={1}
            max={deleteableCount}
            onChange={(_, v) => {
              if (v) {
                setDeleteCount(v)
              }
            }}
          />
        </Box>
      )}
    </AlertDialog>
  )
}
