import { useAddTrayContext } from '../AddTray.context'
import { useSubTraysContext } from '../SubTrays/SubTrays.context'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
import { useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'

const useTrayContentLogic = (trayIndex: number) => {
  const { subTrays, setSubTrays } = useAddTrayContext()
  const { setCurrentView } = useSubTraysContext()
  const { setTrayScrews, setTrayPlates, trayScrews, trayPlates } = useSPD()
  // Get the specific sub tray based on the index
  const subTray = subTrays[trayIndex]
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  useEffect(() => {
    if (subTray?.content) {
      setTrayScrews(subTray.content.screws || [])
      setTrayPlates(subTray.content.plates || [])
    } else {
      setTrayScrews([])
      setTrayPlates([])
    }
  }, [subTray, setTrayScrews, setTrayPlates])

  const saveChanges = () => {
    if (subTray) {
      const updatedSubTrays = [...subTrays]
      updatedSubTrays[trayIndex] = {
        ...subTray,
        content: {
          ...subTray.content,
          screws: trayScrews,
          plates: trayPlates,
        },
      }
      setSubTrays(updatedSubTrays)

      enqueueSnackbar('Sub tray updated successfully', { variant: 'success' })
    }
  }

  const handleBack = () => {
    const isSaved =
      subTray?.content?.screws === trayScrews &&
      subTray?.content?.plates === trayPlates
      
    if (!isSaved) {
      setShowConfirmModal(true)
      return
    }
    
    navigateBack()
  }

  const navigateBack = () => {
    setTrayScrews([])
    setTrayPlates([])
    setCurrentView('list')
  }

  return {
    subTray,
    handleBack,
    saveChanges,
    showConfirmModal,
    setShowConfirmModal,
    navigateBack,
  }
}

export default useTrayContentLogic
