import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import { useIsMobile } from 'lib/utils/useIsMobile'
import DeleteIcon from '@mui/icons-material/Delete'
import './DispositionedAssetDetails.scss'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useUser } from 'app/User'
import classNames from 'classnames'

interface DispositionedAssetDetailsProps {
  value: string | number
  label: string
  additionalLabels?: Array<string | React.ReactNode> | null
  summaryDisabled?: boolean
  children?: NonNullable<React.ReactNode>
  handleDelete?: () => void
  editComponent?: React.ReactNode
  isLoading?: boolean
  longestLabel?: number
  printMode?: boolean
}

export const DispositionedAssetDetails = ({
  value,
  label,
  additionalLabels,
  children,
  handleDelete,
  editComponent,
  isLoading,
  longestLabel,
  printMode,
}: DispositionedAssetDetailsProps) => {
  const flags = useFlags()
  const { isRep } = useUser()
  const repCanEdit = flags?.repCanEdit ?? false
  const hideEdit = isRep && !repCanEdit ? true : false
  const isMobile = useIsMobile()

  // TODO: khanh to fix, ignoring console warning for now after trying to fix 3 times
  console.error = (message) => {
    if (
      typeof message === 'string' &&
      message.startsWith(
        'Warning: Each child in a list should have a unique "key" prop.'
      )
    ) {
      return
    }
  }

  const filterNull = () => {
    // return only if string or node
    return additionalLabels?.filter(
      (label) => typeof label === 'string' || React.isValidElement(label)
    )
  }

  return (
    <div
      aria-label={'dispositioned-asset-details-parent-container'}
      className={`dispositioned-asset-details-parent-container`}
    >
      {/* Row container to space apart count box + asset label and delete component */}
      <div className="child-container-parent-row">
        <div
          className={classNames(`child-countbox-label`, {
            'gap-1': label === 'Used',
            'gap-2': Number(longestLabel) < 30,
            'gap-3': Number(longestLabel) > 30,
          })}
        >
          <div className="content">
            <div className="countbox">
              {isLoading ? (
                <CircularProgress size={15} />
              ) : (
                <Typography variant="h6">{value}</Typography>
              )}
            </div>
            <div>
              <Typography className="typography" variant="h6">
                {label}
              </Typography>
            </div>

            {filterNull()?.map((additionalLabel: React.ReactNode) => (
              <>
                <Divider flexItem orientation="vertical" className="divide" />
                <div>
                  <Typography className="typography" variant="h6">
                    {additionalLabel}
                  </Typography>
                </div>
              </>
            ))}
          </div>
          {/* Edit Component */}
          {editComponent && !hideEdit && (
            <>
              {/* <Divider className="divider" /> */}
              <div className="edit-container">{editComponent}</div>
            </>
          )}
        </div>

        {!printMode && handleDelete ? (
          <IconButton
            data-testid={`delete-asset-button`}
            size="small"
            onClick={handleDelete}
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
      </div>
      {children && (
        <div className={`children ${isMobile ? 'is-mobile' : ''}`}>
          {children}
        </div>
      )}
    </div>
  )
}
