export namespace CameraAccess {
  /**
   * Get a list of cameras available on the device after requesting media permissions.
   * @returns A promise resolving to the array of available `MediaDeviceInfo` objects.
   */
  export async function getCameras(): Promise<MediaDeviceInfo[]> {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true })
      const devices = await navigator.mediaDevices.enumerateDevices()
      return devices.filter((device) => device.kind === 'videoinput')
    } catch (err) {
      const error = err as DOMException
      handlePermissionError(error)
      return []
    }
  }

  /**
   * Access a specific camera by its device ID with specified constraints.
   * @param deviceId The device ID of the camera to access.
   * @param width Desired video width.
   * @param height Desired video height.
   * @returns A promise resolving to the `MediaStream` object coming from the accessed camera.
   */
  export function accessCamera(
    deviceId: string,
    width: number,
    height: number
  ): Promise<MediaStream> {
    const constraints = {
      video: {
        deviceId: { exact: deviceId },
        width: { ideal: width },
        height: { ideal: height },
        frameRate: { ideal: 30 },
      },
      audio: false,
    }

    return navigator.mediaDevices.getUserMedia(constraints)
  }

  /**
   * Handle different types of media permission errors.
   * @param error The media permissions error object.
   */
  function handlePermissionError(error: DOMException): void {
    const { name, message } = error

    switch (name) {
      case 'NotAllowedError':
        console.error('Camera access permission denied:', message)
        break
      case 'NotFoundError':
        console.error('No camera found:', message)
        break
      default:
        console.error('Unhandled camera access error:', name, message)
        break
    }
  }
}
