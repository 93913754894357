import { useEffect, useState } from 'react'

// Services
import {
  useDeleteMediaFiles,
  useUploadAssetMedia,
  useGetAssetMediaFiles,
} from 'lib/apollo/hooks'

// Types
import {
  Image,
  MediaFile,
  MediaUploadProps,
  PreviewModalProps,
} from './MediaUpload.types'

// Utils
import fileToBase64 from 'lib/utils/fileToBase64'

// Other
import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'

const useMediaUploadLogic = ({
  fileNames,
  assetId,
  scanId,
  isLocal,
  hasActions,
  isHandledLocally,
  onAddMedia,
  onDeleteMedia,
  onLocalMediaFilesChange,
  onMediaFilesChange,
  setIsMediaActionTriggered,
}: MediaUploadProps) => {
  const { surgeryId } = useParams()

  // Services
  const { data: media, loading: isLoadingFileNames } = useGetAssetMediaFiles(
    fileNames ?? []
  )
  const [deleteFiles, { loading: isLoadingDeleteFiles }] = useDeleteMediaFiles({
    surgeryId: surgeryId ?? '',
  })
  const [uploadFiles, { loading: isLoadingUploadFiles }] = useUploadAssetMedia()

  // States
  const [images, setImages] = useState<Image[]>([])
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [isPreviewModalOpen, setIsPreviewModalOpen] =
    useState<PreviewModalProps>({ isOpen: false })
  const [deletedImageName, setDeletedImageName] = useState<string>()
  const [modalId, setModalId] = useState<string>(uuidv4())
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<{
    isOpen: boolean
    fileName?: string
  }>()

  const isEmpty = images.length < 1
  const showMediaBlock = hasActions ? true : !isEmpty ? true : false

  // Handlers
  const handleSaveImages = async (newImages: File[]) => {
    if (!isHandledLocally) {
      const uploadPayloads = await Promise.all(
        newImages.map(async (img) => {
          const base64Content = await fileToBase64(img as File)
          return {
            filename: img.name,
            mimetype: img.type,
            encoding: 'base64',
            content: base64Content,
          }
        })
      )

      if (isLocal) {
        setImages((prev) => [
          ...prev,
          ...newImages.map((file) => ({
            preview: URL.createObjectURL(file),
            isLocal: false,
            file: file,
          })),
        ])

        setIsUploadModalOpen(false)
        setModalId(uuidv4())

        const filteredUploadPayloads = uploadPayloads.filter(
          (payload) => payload !== null
        ) as MediaFile[]

        if (onLocalMediaFilesChange) {
          onLocalMediaFilesChange(filteredUploadPayloads, 'add')
        }
        return
      }

      if (uploadPayloads.length === 0) return

      try {
        const response = await uploadFiles({
          variables: {
            assetId: assetId,
            scanId: scanId,
            files: uploadPayloads,
          },
        })

        if (
          response.data?.uploadAssetMedia?.files &&
          response.data?.uploadAssetMedia?.files.length > 0
        ) {
          const uploadedFiles = response.data.uploadAssetMedia.files.map(
            (file) => ({
              preview: file.base64Content,
              isLocal: false,
              file: new File([], file.filename),
            })
          )

          setImages((prevImages) => [
            ...prevImages.filter((img) => !img.isLocal),
            ...uploadedFiles,
          ])

          setIsMediaActionTriggered && setIsMediaActionTriggered(true)
          setIsUploadModalOpen(false)
          setModalId(uuidv4())

          enqueueSnackbar('Files uploaded successfully.', {
            variant: 'success',
          })

          // This is used to pass the uploaded files as mediaFiles in the scan in BATCH_ADD_ASSETS_TO_SURGERY mutation.
          onMediaFilesChange &&
            onMediaFilesChange(
              [...uploadedFiles.map((item) => item.file.name)],
              'add'
            )
        } else {
          enqueueSnackbar('Failed to upload files.', {
            variant: 'error',
          })
        }
      } catch (error) {
        console.error('Error uploading files:', error)
        enqueueSnackbar('Failed to upload files.', {
          variant: 'error',
        })
      }
    } else {
      setImages((prev) => [
        ...prev,
        ...newImages.map((file) => ({
          preview: URL.createObjectURL(file),
          isLocal: true,
          file: file,
        })),
      ])
      setIsUploadModalOpen(false)
      setModalId(uuidv4())
      if (onAddMedia) {
        const uploadPayloads: MediaFile[] = await Promise.all(
          newImages.map(async (img) => {
            const base64Content = await fileToBase64(img as File)
            return {
              filename: img.name,
              mimetype: img.type,
              encoding: 'base64',
              content: base64Content,
              action: 'add',
            }
          })
        )
        onAddMedia(uploadPayloads)
      }
    }
  }

  const handleDeleteImage = (fileName?: string) => {
    if (!fileName) return

    if (!isHandledLocally) {
      if (isLocal) {
        setImages((prev) => {
          const updatedImages = prev.filter(
            (item) => item.file?.name !== fileName
          )
          enqueueSnackbar('Image has been deleted', {
            variant: 'success',
          })
          if (onLocalMediaFilesChange) {
            const updatedFiles = updatedImages.map(async (item) => {
              const base64Content = await fileToBase64(item.file as File)
              return {
                filename: item.file?.name || '',
                mimetype: item.file?.type || '',
                encoding: 'base64',
                content: base64Content || '',
              }
            })

            Promise.all(updatedFiles).then((files) => {
              onLocalMediaFilesChange(files, 'delete')
            })
          }

          return updatedImages
        })

        setIsDeleteAlertOpen({ isOpen: false, fileName: undefined })
        return
      }

      setDeletedImageName(fileName)
      setIsDeleteAlertOpen({ isOpen: false, fileName: undefined })
      deleteFiles({
        variables: {
          assetId: assetId,
          scanId: scanId,
          filenames: [fileName],
        },
      })
        .then((res) => {
          if (res.data?.deleteMediaFiles.status === 'Media deleted') {
            enqueueSnackbar('Image has been deleted', {
              variant: 'success',
            })
            setImages((prev) =>
              prev.filter((item) => item.file?.name !== fileName)
            )

            setIsMediaActionTriggered && setIsMediaActionTriggered(true)
            onMediaFilesChange && onMediaFilesChange([fileName], 'delete')
          }
        })
        .catch(() => {
          enqueueSnackbar(
            'Something went wrong while deleting the image. Please try again.',
            {
              variant: 'error',
            }
          )
        })
        .finally(() => {
          setDeletedImageName(undefined)
        })
    } else {
      setImages((prev) => prev.filter((item) => item.file?.name !== fileName))
      setIsDeleteAlertOpen({ isOpen: false, fileName: undefined })
      onDeleteMedia?.(fileName)
    }
  }

  useEffect(() => {
    if (!media?.getMediaFiles.length) return

    const uniqueImages = media.getMediaFiles.reduce((acc, file) => {
      if (!acc.find((f) => f.file?.name === file.filename)) {
        acc.push({
          isLocal: false,
          preview: file.content,
          file: { name: file.filename },
        } as Image)
      }
      return acc
    }, [] as Image[])

    setImages(uniqueImages)
  }, [media])

  return {
    images,
    modalId,
    isEmpty,
    hasActions,
    showMediaBlock,
    deletedImageName,
    isUploadModalOpen,
    isPreviewModalOpen,
    isLoadingFileNames,
    isLoadingDeleteFiles,
    isLoadingUploadFiles,
    isDeleteAlertOpen,
    setIsDeleteAlertOpen,
    setIsUploadModalOpen,
    handleSaveImages,
    handleDeleteImage,
    setIsPreviewModalOpen,
  }
}

export default useMediaUploadLogic
