import { Box, Paper, Theme, SxProps } from '@mui/material'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import { BetterIdAsset, BetterIdResult } from 'common/types'
import { AssetDetailsCard } from 'views/assets/AssetDetailsCard/AssetDetailsCard'
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'
import { getAssetTypeCaps } from 'lib/utils/getAssetType'
import React from 'react'
import { useAssetType } from 'lib/context/AssetTypeContext/AssetTypeContext'

interface MultiDeviceCountCardProps {
  scan: BetterIdResult | BetterIdAsset
  count: number
  cardStyles?: SxProps<Theme>
  onChange: (count: number) => void
}

export const MultiDeviceCountCard = ({
  scan,
  count,
  cardStyles,
  onChange,
}: MultiDeviceCountCardProps) => {
  const { assetType: rawAssetTypes } = useAssetType()
  const assetType = getAssetTypeCaps(rawAssetTypes)

  const { lotBatch, serialNumber, expirationDate } = scan as BetterIdResult

  const handleChange = (
    e:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.PointerEvent<Element>
      | React.KeyboardEvent<Element>
      | React.SyntheticEvent<Element>
  ) => {
    // @ts-ignore
    onChange(parseInt(e.currentTarget.value, 10))
  }

  return (
    <Paper variant="outlined" sx={{ ...cardStyles }}>
      <AssetDetailsCard
        startComponent={
          <Box>
            <QrCode2Icon fontSize="large" />
          </Box>
        }
        endComponent={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="center"
            rowGap={1}
            sx={{
              flexDirection: { xs: 'row', sm: 'column' },
              justifyContent: { xs: 'space-between', sm: 'flex-end' },
              alignItems: { xs: 'center', sm: 'flex-end' },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              columnGap={1.5}
            >
              <input
                style={{ minWidth: '50px' }}
                min={1}
                max={scan.deviceCount}
                value={count}
                type={'number'}
                inputMode={'numeric'}
                pattern={'[0-9]*'}
                onChange={handleChange}
              />
            </Box>
          </Box>
        }
        assetDetails={{
          assetTitle: {
            value: scan.deviceDescription,
          },
          assetSizeText: {
            value: scan.sizeText,
          },
          assetTypeLabel: {
            value: getAssetTypeLabel(assetType),
          },
          assetManufacturer: {
            value: scan.company?.parentCompany?.name
              ? `${scan.company?.parentCompany?.name} [${scan?.company?.name}]`
              : scan?.company?.name || '',
          },
          deviceId: {
            value: scan.deviceId,
          },
          assetTrayId: {
            value: null,
          },
          modelNumber: {
            value: scan.versionModelNumber,
          },
          catalogNumber: {
            value: scan.catalogNumber,
          },
          lotNumber: {
            value: lotBatch || null,
          },
          serialNumber: {
            value: serialNumber || null,
          },
          expirationDate: {
            value: expirationDate || null,
          },
          deviceCount: {
            value: scan.deviceCount,
          },
          usedCount: {
            value: null,
          },
        }}
        assetType={assetType}
      />
    </Paper>
  )
}
