import { Fragment, memo, useMemo } from 'react'

// MUI
import { ButtonBase, CircularProgress, Modal, Typography } from '@mui/material'
import SearchInput from 'components/molecules/SearchInput/SearchInput'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import DialogDrawer from 'components/molecules/Drawer/DialogDrawer'
import { CompanyOption } from 'views/assets/ManualAssetEntry/ManualAddForm/ManualAddForm.types'

// Types
import { PaginatedSearchProps } from './PaginatedSearch.types'

// Logic
import usePaginatedSearchLogic from './PaginatedSearch.logic'

// Style
import './PaginatedSearch.scss'

// Other
import classNames from 'classnames'
import { isTablet, isMobile } from 'react-device-detect'
import { Waypoint } from 'react-waypoint'

const PaginatedSearch: React.FC<PaginatedSearchProps> = memo(
  ({
    name,
    value,
    isOpen,
    options,
    isLoading,
    isFetching,
    placeholder,
    searchValue,
    isPagination,
    onClose,
    onSelect,
    onChange,
    handleInfiniteScroll,
  }) => {
    const { isMobileOrTablet, ref, height } = usePaginatedSearchLogic()
    const memoizedOptions = useMemo(() => options, [options])

    return isMobileOrTablet ? (
      <Modal
        open={isOpen}
        onClose={onClose}
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <div
          className={classNames('manual-add-search-modal', {
            'is-mobile': isMobile,
          })}
        >
          <div
            className={classNames('paginated-search-container', {
              'is-mobile': isMobile,
            })}
            ref={ref}
          >
            <div className="input">
              <ButtonBase
                disableRipple
                onClick={onClose}
                className="icon-container"
              >
                <KeyboardBackspaceIcon color="primary" className="back-icon" />
              </ButtonBase>
              <SearchInput
                name={name}
                autoFocus={true}
                onSearch={onChange}
                isLoading={isLoading}
                placeholder={placeholder}
                value={value}
              />
            </div>
            <div
              className={classNames('result', {
                'is-tablet': isTablet,
                'is-mobile': isMobile,
              })}
              style={{
                height,
              }}
            >
              {searchValue &&
              memoizedOptions &&
              searchValue.length > 0 &&
              memoizedOptions.length === 0 &&
              !isLoading ? (
                <div className="no-data">
                  <Typography variant="body1">No data found</Typography>
                </div>
              ) : (
                memoizedOptions?.map((option, index) => (
                  <Fragment key={option.id}>
                    {isPagination && index === memoizedOptions.length - 1 && (
                      <Waypoint onEnter={handleInfiniteScroll} />
                    )}
                    <ButtonBase
                      className="item"
                      key={option.id}
                      onClick={() => {
                        onSelect(option)
                      }}
                    >
                      <Typography variant="body1" align="left">
                        {option.name}
                      </Typography>
                    </ButtonBase>
                  </Fragment>
                ))
              )}
              {isFetching && (
                <div className="infinite-scroll-loader">
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    ) : (
      <DialogDrawer open={isOpen} onClose={onClose} isHalfWidth height={'90%'}>
        <div className={classNames('paginated-search-container')}>
          <div className="input">
            <ButtonBase
              disableRipple
              onClick={onClose}
              className="icon-container"
            >
              <KeyboardBackspaceIcon color="primary" className="back-icon" />
            </ButtonBase>
            <SearchInput
              name={name}
              autoFocus={true}
              onSearch={onChange}
              isLoading={isLoading}
              placeholder={placeholder}
              value={value}
            />
          </div>
          <div
            className={classNames('result', {
              'is-tablet': isTablet,
              'is-mobile': isMobile,
            })}
            style={{
              height: height,
            }}
          >
            {searchValue &&
            memoizedOptions &&
            searchValue.length > 0 &&
            memoizedOptions.length === 0 &&
            !isLoading ? (
              <div className="no-data">
                <Typography variant="body1">No data found</Typography>
              </div>
            ) : (
              memoizedOptions?.map((option, index) => {
                return (
                  <Fragment key={option.id}>
                    {isPagination && index === memoizedOptions.length - 1 && (
                      <Waypoint onEnter={handleInfiniteScroll} />
                    )}
                    <ButtonBase
                      className={classNames('item', {
                        'is-child': (option as CompanyOption)?.isChild,
                      })}
                      key={option.id}
                      onClick={() => {
                        onSelect(option)
                      }}
                    >
                      <Typography
                        variant={
                          (option as CompanyOption)?.isChild ? 'body1' : 'h3'
                        }
                        align="left"
                      >
                        {option.name}
                      </Typography>
                    </ButtonBase>
                  </Fragment>
                )
              })
            )}
            {isFetching && (
              <div className="infinite-scroll-loader">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </DialogDrawer>
    )
  }
)

export default PaginatedSearch
