import React, { useRef } from 'react'

// Components
import Button from 'components/molecules/Button/Button'
import { Box, Typography } from '@mui/material'
import { useReactToPrint } from 'react-to-print'

// Types
import { IPrintWrapper } from './PrintWraper.types'

// Style
import './PrintWrapper.scss'

// Other
import classNames from 'classnames'
import { useIsMobile } from 'assets/utils/mediaQueries'

const PrintComponent: React.FC<IPrintWrapper> = ({
  header,
  sections,
  hidden,
}) => {
  const printRef = useRef<HTMLDivElement>(null)
  const isMobile = useIsMobile()
  const handlePrint = useReactToPrint({
    contentRef: printRef,
    pageStyle: `@media print {
      @page {
        size: auto; 
        margin: 20mm;
      }
      .print-section {
        page-break-after: always;
      }
      .print-section:last-child {
        page-break-after: avoid;
      }
      .print-button {
        display: none;
      }
    }`,
  })

  return (
    <div
      className={classNames('print-wrapper', {
        visible: !hidden,
        'is-mobile': isMobile,
      })}
    >
      <Button
        variant="contained"
        onClick={() => handlePrint()}
        className="print-button"
      >
        Print
      </Button>
      <div ref={printRef} className="print-content">
        {header}
        {sections
          .filter((item) => item.content)
          .map((section, index) => (
            <Box key={index} className="print-section">
              {section.title ? (
                <Typography variant="h2" gutterBottom>
                  {section.title}
                </Typography>
              ) : null}

              {section.content}
            </Box>
          ))}
      </div>
    </div>
  )
}

export default PrintComponent
