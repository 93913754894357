import { CircularProgress, Typography } from '@mui/material'
import { FlipCameraIos } from '@mui/icons-material'

import { LoadingButton } from 'components/mui'

import { useSPDCortexScannerContext } from '../SPDCortexScanner.context'

import './SPDCortexVideo.scss'
import { useSPDCortexVideo } from './SPDCortexVideo.logic'
import classNames from 'classnames'

const SPDCortexVideo = ({
  onScan,
  isEnabled = true,
}: {
  onScan: (newScan: CortexDecoder.CDResult) => void
  isEnabled?: boolean
}) => {
  const { handleToggleCameraMenu, isCameraMenuOpen, handleCameraChange } =
    useSPDCortexVideo({
      onScan,
      isEnabled,
    })
  const { cameras, selectedCamera, videoRef } = useSPDCortexScannerContext()

  return (
    <>
      {cameras.length === 0 || !selectedCamera ? (
        <CircularProgress />
      ) : (
        <div className="spd-cortex-video_wrapper">
          <div className="camera-select_container">
            <LoadingButton
              loading={false}
              disabled={false}
              onClick={handleToggleCameraMenu}
              sx={{ zIndex: 9999 }}
            >
              <FlipCameraIos />
            </LoadingButton>

            <div
              className="camera-options"
              style={{
                display: isCameraMenuOpen ? 'flex' : 'none',
                opacity: isCameraMenuOpen ? 1 : 0,
              }}
            >
              {cameras.map((camera: any) => (
                <div
                  key={camera.deviceId}
                  className={classNames('camera', {
                    active: camera.deviceId === selectedCamera?.deviceId,
                  })}
                  onClick={() => handleCameraChange(camera)}
                >
                  <Typography variant="body2" className="camera-text">
                    {camera.label}
                  </Typography>

                  <Typography variant="body2" className="camera-text">
                    {camera?.cameraType}
                  </Typography>
                </div>
              ))}
            </div>
          </div>

          <video
            ref={videoRef}
            id="video"
            width={window.innerWidth}
            height={window.innerHeight}
            playsInline
            autoPlay
          />
        </div>
      )}
    </>
  )
}

export default SPDCortexVideo
