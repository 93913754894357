import LoadingButton from 'components/molecules/LoadingButton/LoadingButton'
import { CustomModal } from 'components/molecules/Modal/Modal'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { SubmitToEMRProps } from './SubmitToEMR.types'
import { useSubmitToEMRLogic } from './SubmitToEMR.logic'

const SubmitToEMR = (props: SubmitToEMRProps) => {
  const { isGroupedAssetsEmpty } = props

  const {
    error,
    loading,
    isMobile,
    scrubTechs,
    disableSubmit,
    numberOfExpired,
    openScrubTechModal,
    undocumentedScanCount,
    selectedScrubTechnician,
    setSurgeryStatusSubmittedMutation,
    refetch,
    handleSubmitToEmr,
    handleSubmitScrubTech,
    handleCloseScrubTechModal,
    handleSelectScrubTechnician,
    FormValidationAlertToasters,
  } = useSubmitToEMRLogic(props)

  const ScrubTechModal = () => {
    refetch()
    return (
      <CustomModal
        open={openScrubTechModal}
        secondaryButtonAction={handleCloseScrubTechModal}
        header="Add primary scrub technician"
        isLoadingState={false}
        handleOnSubmit={handleSubmitScrubTech}
      >
        <AutocompleteInput
          label="Primary scrub technician"
          name="primaryScrubTechnician"
          placeholder="Search for scrub tech"
          options={scrubTechs || []}
          value={selectedScrubTechnician}
          required
          handleChangeFormData={handleSelectScrubTechnician}
          variant="outlined"
          isLoading={loading}
          error={!!error}
          errorMessage={error}
        />
      </CustomModal>
    )
  }

  return (
    <>
      {FormValidationAlertToasters()}
      {ScrubTechModal()}

      <form onSubmit={handleSubmitToEmr}>
        <input
          type="hidden"
          name="numberOfExpired"
          value={numberOfExpired?.expiredTotal ?? '0'}
        />
        <input
          type="hidden"
          name="undocumentedScansCount"
          value={undocumentedScanCount}
        />
        <input type="hidden" name="submitToEmrConfirm" value="false" />
        <LoadingButton
          type="submit"
          disabled={disableSubmit}
          loading={setSurgeryStatusSubmittedMutation.loading}
          loadingText="Submitting record to emr"
          size="small"
          sx={{ mb: 1.5, fontSize: isMobile ? '10px' : '' }}
        >
          {isGroupedAssetsEmpty
            ? 'Close Case'
            : 'FINALIZE CASE: SUBMIT RECORD TO EMR or PRINT FOR CHART'}
        </LoadingButton>
      </form>
    </>
  )
}

export default SubmitToEMR
