import { RecordCompanyResult, SentToSalesRep } from 'common/types'
import { useGetRecordCompanyRepsQuery } from 'lib/apollo/hooks'
import { useMemo, useState } from 'react'
import { TRecordSenderProps } from './RecordSender.types'
import { SelectChangeEvent } from '@mui/material'

export const useRecordSenderLogic = (props: TRecordSenderProps) => {
  const { surgery } = props

  const [currentCompany, setCurrentCompany] =
    useState<RecordCompanyResult | null>(null)

  const { data, loading } = useGetRecordCompanyRepsQuery(surgery._id)

  const repsByCompany = data?.getRecordCompanyReps

  const sentToSalesRep: SentToSalesRep | undefined = useMemo(
    () =>
      surgery.sentToSalesRep?.find(
        (item) => item.company === currentCompany?.name
      ),
    [surgery.sentToSalesRep, currentCompany]
  )

  const handleSelectChange = (e: SelectChangeEvent) => {
    setCurrentCompany(
      repsByCompany?.find((company) => company.name === e.target.value) || null
    )
  }

  return {
    surgery,
    loading,
    currentCompany,
    repsByCompany,
    sentToSalesRep,
    setCurrentCompany,
    handleSelectChange,
  }
}
