import {
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import {
  ASSET_TYPES,
  DispositionList,
  DISPOSITIONS,
  EXPLANTED_REASON_LIST,
  getDispositionList,
  WASTED_REASON_LIST,
} from 'common/disposition'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'
import { CustomModal } from 'components/molecules/Modal/Modal'
import { useEditDispositionModalLogic } from './EditDispositionModal.logic'
import MediaUpload from '../MediaUpload/MediaUpload'
import { EditDispositionModalProps } from 'lib/context/EditDocumentedListContext/EditDocumentedList.types'
import { AssetGoodConditionCheck, SamplePreparationType } from 'common/types'
import TextInput from 'components/molecules/TextInput/TextInput'
import { MediaFile } from '../MediaUpload/MediaUpload.types'

export const EditDispositionModal = ({
  scans,
  assetTray,
  isMultipack,
  dataTestId,
  isManualAddition,
  isMultipackHardware,
  singleConsumablesCount,
  isSponge,
}: EditDispositionModalProps) => {
  const {
    isLoading,
    formState,
    isConsumable,
    isBiological,
    isEditModalOpen,
    implantSiteList,
    selectedAssetId,
    validationErrors,
    wastedReasonLabel,
    implantStatusLabel,
    areInputsUnchanged,
    renderImplantSites,
    consumableAssetGroup,
    selectedAssetGroupId,
    isSiteLocationEnabled,
    handleReset,
    handleSubmit,
    setIsEditModalOpen,
    isSavingEditedAsset,
    handleChangeFormData,
    setIsMediaActionTriggered,
    handleOnAddMedia,
    handleOnDeleteMedia,
    isLoadingMedia,
  } = useEditDispositionModalLogic({
    assetTray,
    isMultipack,
    isMultipackHardware,
    isManualAddition,
    singleConsumablesCount,
    isSponge,
  })

  return (
    <CustomModal
      open={isEditModalOpen}
      dataTestId={dataTestId}
      secondaryButtonAction={() => {
        handleReset()
        setIsEditModalOpen(false)
      }}
      header={isConsumable ? 'Edit Consumable' : 'Edit Disposition'}
      primaryButtonDisabled={areInputsUnchanged}
      isLoadingState={
        isLoading || isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
      }
      handleOnSubmit={handleSubmit}
      primaryButtonText={'Save'}
    >
      <FormControl fullWidth sx={{ my: 2 }}>
        <Box>
          <InputLabel id="asset-type-label">Product Type</InputLabel>
          <Select
            fullWidth
            data-testid="asset-type-input"
            id="assetType"
            name="assetType"
            variant="outlined"
            labelId="asset-type-label"
            label="Product Type"
            value={formState?.assetType}
            onChange={handleChangeFormData}
            disabled={
              isSavingEditedAsset(selectedAssetGroupId) ||
              isMultipackHardware ||
              isBiological ||
              isLoadingMedia
            }
            sx={{
              backgroundColor: 'grayscale.lightest',
              '& .MuiSvgIcon-root': {
                color: 'primary.main',
              },
            }}
          >
            <MenuItem
              data-testid="hardware-menuitem"
              style={{ cursor: 'pointer', fontSize: 16 }}
              key={ASSET_TYPES[0]}
              value={ASSET_TYPES[0]}
            >
              Implantable Hardware/Assoc. Product
            </MenuItem>
            {isBiological && (
              <MenuItem
                data-testid="hardware-menuitem"
                style={{ cursor: 'pointer', fontSize: 16 }}
                key={ASSET_TYPES[1]}
                value={ASSET_TYPES[1]}
              >
                Implantable Biologic
              </MenuItem>
            )}
            <MenuItem
              data-testid="other-menuitem"
              style={{ cursor: 'pointer' }}
              key={ASSET_TYPES[2]}
              value={ASSET_TYPES[2]}
            >
              Implantable Other
            </MenuItem>
            <MenuItem
              data-testid="consumable-menuitem"
              style={{ cursor: 'pointer' }}
              key={ASSET_TYPES[3]}
              value={ASSET_TYPES[3]}
            >
              Consumable
            </MenuItem>
          </Select>
        </Box>
      </FormControl>

      {formState?.assetType && !isConsumable && (
        <AutocompleteInput
          dataTestId="implant-status-input"
          id="implantStatus"
          name="implantStatus"
          variant="outlined"
          label="Disposition*"
          disabled={isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia}
          value={implantStatusLabel}
          sx={{
            backgroundColor: 'grayscale.lightest',
          }}
          options={
            getDispositionList({
              assetType: formState.assetType,
              isMultipackHardware,
            }) as DispositionList
          }
          handleChangeFormData={(e: any) => {
            handleChangeFormData({
              target: {
                name: e.target.name,
                value: e.target.value.id,
              },
            })
          }}
          errorMessage={validationErrors.implantStatus}
          disableClearable
          error={!!validationErrors.implantStatus}
        />
      )}

      {renderImplantSites && (
        <AutocompleteInput
          dataTestId="implant-site-input"
          id="implantSite"
          name="implantSite"
          label={
            formState.implantStatus === 'IMPLANTED'
              ? 'Implant Site*'
              : 'Explant Site*'
          }
          sx={{
            backgroundColor: 'grayscale.lightest',
          }}
          value={formState?.implantSite}
          options={implantSiteList}
          handleChangeFormData={handleChangeFormData}
          variant="outlined"
          disabled={isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia}
          disableClearable
          errorMessage={validationErrors.implantSite}
          error={!!validationErrors.implantSite}
        />
      )}

      {isSiteLocationEnabled && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <TextField
            variant="outlined"
            id={`implant-site`}
            label={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Location*'
                : 'Explant Location*'
            }
            name={`siteLocation`}
            value={formState.siteLocation ?? ''}
            placeholder={
              formState.implantStatus === 'IMPLANTED'
                ? 'Implant Location*'
                : 'Explant Location*'
            }
            inputMode="text"
            error={!!validationErrors.siteLocation}
            sx={{
              backgroundColor: 'grayscale.lightest',
            }}
            onChange={handleChangeFormData}
            disabled={
              isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
            }
          />
          {!!validationErrors.siteLocation && (
            <Typography
              variant="caption"
              sx={{ color: 'error.main', ml: '0.5em' }}
            >
              {validationErrors.siteLocation}
            </Typography>
          )}
        </FormControl>
      )}

      {(isConsumable || formState.assetType === 'consumable') && (
        <>
          <TextInput
            handleChangeFormData={handleChangeFormData}
            id={`consumable-count-${consumableAssetGroup?.bidAssetId}`}
            label={isSponge ? 'Sponges Used*' : 'Items Used*'}
            name="count"
            value={formState.count ?? ''}
            inputMode="numeric"
            variant="outlined"
            type="number"
            required={false}
            disabled={isSavingEditedAsset(selectedAssetGroupId)}
            sx={{ mb: 1, backgroundColor: 'grayscale.lightest' }}
            error={!!validationErrors.count}
            errorMessage={validationErrors.count}
          />
          {(singleConsumablesCount !== undefined ||
            formState.assetType === 'consumable') &&
            !isSponge && (
              <TextInput
                handleChangeFormData={handleChangeFormData}
                id={`wasted-consumable-count-${consumableAssetGroup?.bidAssetId}`}
                label="Items Wasted"
                name="wastedConsumablesCount"
                value={formState.wastedConsumablesCount ?? ''}
                inputMode="numeric"
                variant="outlined"
                type="number"
                required={false}
                disabled={isSavingEditedAsset(selectedAssetGroupId)}
                sx={{ mb: 1, mt: 1, backgroundColor: 'grayscale.lightest' }}
                error={!!validationErrors.wastedConsumablesCount}
                errorMessage={validationErrors.wastedConsumablesCount}
              />
            )}
        </>
      )}

      {(formState?.implantStatus === 'WASTED' && !isConsumable) ||
      (isConsumable &&
        formState.wastedConsumablesCount &&
        formState?.wastedConsumablesCount > 0) ? (
        <AutocompleteInput
          dataTestId="wasted-reason-input"
          id="wastedReason"
          name="wastedReason"
          variant="outlined"
          label="Wasted Reason*"
          value={wastedReasonLabel}
          options={
            isConsumable
              ? WASTED_REASON_LIST.filter(
                  (reason) =>
                    reason.id !== 'INABILITY-TO-ACHIEVE-PRIMARY-STABILITY'
                )
              : WASTED_REASON_LIST
          }
          handleChangeFormData={(e: any) => {
            handleChangeFormData({
              target: {
                name: e.target.name,
                value: e.target.value.id,
              },
            })
          }}
          disabled={isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia}
          errorMessage={validationErrors.wastedReason}
          disableClearable
          error={!!validationErrors.wastedReason}
          sx={{
            backgroundColor: 'grayscale.lightest',
          }}
        />
      ) : null}

      {isManualAddition && formState?.implantStatus === 'EXPLANTED' && (
        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel id="explanted-reason-label">Explanted Reason*</InputLabel>
          <Select
            id="explantedReason"
            name="explantedReason"
            variant="outlined"
            labelId="explanted-reason-label"
            label="Explanted Reason*"
            value={formState.explantedReason ?? ''}
            onChange={handleChangeFormData}
            disabled={
              isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
            }
            error={!!validationErrors.explantedReason}
            sx={{
              backgroundColor: 'grayscale.lightest',
              '& .MuiButtonBase-root, & .MuiSvgIcon-root, & .MuiSelect-icon': {
                color: 'primary.main',
              },
            }}
          >
            {EXPLANTED_REASON_LIST.map((reason) => (
              <MenuItem key={reason.id} value={reason.id}>
                {reason.name}
              </MenuItem>
            ))}
          </Select>
          <Typography
            variant="caption"
            sx={{ color: 'error.main', ml: '0.5em' }}
          >
            {validationErrors.explantedReason}
          </Typography>
        </FormControl>
      )}

      {isManualAddition &&
        formState?.explantedReason === 'OTHER' &&
        formState.implantStatus === 'EXPLANTED' && (
          <FormControl fullWidth sx={{ my: 2 }}>
            <TextField
              variant="outlined"
              id={`explanted-reason-note`}
              label="Other Reason*"
              placeholder="Other Reason*"
              name={`explantedReasonNote`}
              value={formState.explantedReasonNote ?? ''}
              inputMode="text"
              sx={{ backgroundColor: 'grayscale.lightest' }}
              onChange={handleChangeFormData}
              disabled={
                isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
              }
              error={!!validationErrors.explantedReasonNote}
            />
            <Typography
              variant="caption"
              sx={{ color: 'error.main', ml: '0.5em' }}
            >
              {validationErrors.explantedReasonNote}
            </Typography>
          </FormControl>
        )}

      {isBiological && formState.implantStatus === DISPOSITIONS[0] && (
        <>
          <FormControl fullWidth sx={{ my: 2 }}>
            <Box>
              <InputLabel id="packaging-ok-label">Packaging Intact</InputLabel>
              <Select
                fullWidth
                data-testid="packaging-ok-input"
                id="packagingOk"
                name="packagingOk"
                variant="outlined"
                labelId="packaging-ok-label"
                label="Packaging Intact"
                value={
                  AssetGoodConditionCheck[
                    formState?.packagingOk as keyof typeof AssetGoodConditionCheck
                  ]
                }
                onChange={handleChangeFormData}
                sx={{
                  backgroundColor: 'grayscale.lightest',
                  '& .MuiSvgIcon-root': {
                    color: 'primary.main',
                  },
                }}
              >
                {Object.entries(AssetGoodConditionCheck).map(
                  ([key, value], index) => (
                    <MenuItem
                      data-testid="hardware-menuitem"
                      style={{ cursor: 'pointer', fontSize: 16 }}
                      key={key}
                      value={value}
                    >
                      {value}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
          </FormControl>

          <FormControl fullWidth sx={{ my: 2 }}>
            <Box>
              <InputLabel id="temperature-ok-label">Temperature Ok</InputLabel>
              <Select
                fullWidth
                data-testid="temperature-ok-input"
                id="temperatureOk"
                name="temperatureOk"
                variant="outlined"
                labelId="temperature-ok-label"
                label="Temperature Ok"
                value={
                  AssetGoodConditionCheck[
                    formState?.temperatureOk as keyof typeof AssetGoodConditionCheck
                  ]
                }
                onChange={handleChangeFormData}
                sx={{
                  backgroundColor: 'grayscale.lightest',
                  '& .MuiSvgIcon-root': {
                    color: 'primary.main',
                  },
                }}
              >
                {Object.entries(AssetGoodConditionCheck).map(
                  ([key, value], index) => (
                    <MenuItem
                      data-testid="hardware-menuitem"
                      style={{ cursor: 'pointer', fontSize: 16 }}
                      key={key}
                      value={value}
                    >
                      {value}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
          </FormControl>

          <FormControl fullWidth sx={{ my: 2 }}>
            <Box>
              <InputLabel id="sample-preperation-label">
                Sample Preparation*
              </InputLabel>
              <Select
                fullWidth
                data-testid="sample-preparation-input"
                id="samplePreparation"
                name="samplePreparation"
                variant="outlined"
                labelId="sample-preparation-label"
                label="Sample Preparation*"
                value={
                  SamplePreparationType[
                    formState?.samplePreparation as keyof typeof SamplePreparationType
                  ]
                }
                onChange={handleChangeFormData}
                sx={{
                  backgroundColor: 'grayscale.lightest',
                  '& .MuiSvgIcon-root': {
                    color: 'primary.main',
                  },
                }}
              >
                {Object.entries(SamplePreparationType).map(
                  ([key, value], index) => (
                    <MenuItem
                      data-testid="hardware-menuitem"
                      style={{ cursor: 'pointer', fontSize: 16 }}
                      key={key}
                      value={value}
                    >
                      {value}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
          </FormControl>

          {formState.samplePreparation === 'OTHER' && (
            <FormControl fullWidth sx={{ my: 2 }}>
              <TextField
                variant="outlined"
                id="sample-preperation-note"
                label="Sample Preperation Note*"
                placeholder="Sample Preperation Note*"
                name="samplePreparationNote"
                value={formState.samplePreparationNote ?? ''}
                inputMode="text"
                sx={{ backgroundColor: 'grayscale.lightest' }}
                onChange={handleChangeFormData}
                disabled={
                  isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
                }
                error={!!validationErrors.samplePreparationNote}
              />
              <Typography
                variant="caption"
                sx={{ color: 'error.main', ml: '0.5em' }}
              >
                {validationErrors.samplePreparationNote}
              </Typography>
            </FormControl>
          )}

          <FormControl fullWidth sx={{ my: 2 }}>
            <TextField
              variant="outlined"
              id="implant-memo"
              label="Scrub Tech / Note"
              placeholder="Scrub Tech / Note"
              name="implantMemo"
              value={formState.implantMemo ?? ''}
              inputMode="text"
              sx={{ backgroundColor: 'grayscale.lightest' }}
              onChange={handleChangeFormData}
              disabled={
                isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
              }
            />
            <Typography
              variant="caption"
              sx={{ color: 'error.main', ml: '0.5em' }}
            />
          </FormControl>
        </>
      )}

      {!isConsumable && (
        <>
          <MediaUpload
            disableActions={
              isSavingEditedAsset(selectedAssetGroupId) || isLoadingMedia
            }
            hasActions
            setIsMediaActionTriggered={setIsMediaActionTriggered}
            assetId={selectedAssetId || ''}
            fileNames={
              selectedAssetId
                ? (scans?.filter((item) => item._id === selectedAssetId)[0]
                    .media as string[])
                : []
            }
            isHandledLocally
            onAddMedia={(mediaFiles: MediaFile[]) =>
              handleOnAddMedia(mediaFiles)
            }
            onDeleteMedia={(fileName: string) => handleOnDeleteMedia(fileName)}
          />
        </>
      )}
    </CustomModal>
  )
}
