import { useState } from 'react'

// Services
import { useSendSurgeryToRepMutation } from 'lib/apollo/hooks'

// Types
import { RepItemProps } from './RepItem.types'
import { SendSurgeryToRepVariables } from 'common/types'

// Other
import { enqueueSnackbar } from 'notistack'

export const useRepItemLogic = ({
  rep,
  surgeryId,
  companyName,
}: RepItemProps) => {
  const [sendSurgeryToRep, { loading: isLoading }] =
    useSendSurgeryToRepMutation()
  const [selectedType, setSelectedType] =
    useState<SendSurgeryToRepVariables['type']>()

  const handleSendSurgeryToRep = (type: SendSurgeryToRepVariables['type']) => {
    setSelectedType(type)
    sendSurgeryToRep({
      variables: {
        surgeryId,
        repId: rep?.id ?? '',
        email: rep?.email ?? '',
        phoneNumber: rep?.phoneNumber ?? '',
        firstName: rep.firstName ?? '',
        lastName: rep.lastName ?? '',
        companyName: companyName,
        origin: window.origin,
        type,
      },
    })
      .then((res) => {
        if (res.data?.sendSurgeryToRep.success) {
          const isEmail = type === 'EMAIL'
          enqueueSnackbar(
            `${isEmail ? 'Email' : 'Text message'} sent successfully.`,
            {
              variant: 'success',
            }
          )
        }
      })
      .catch((err) => {
        enqueueSnackbar(err, {
          variant: 'error',
        })
      })
  }

  return {
    isLoading,
    selectedType,
    handleSendSurgeryToRep,
  }
}

export default useRepItemLogic
