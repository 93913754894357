import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Create, ReadMore } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'

import { HeadCell } from 'components/molecules/DataTable/TableHeader'

import useGetLocationsTree from 'lib/services/api/product-service/getLocationsTree'
import { extractTrayJSONData } from 'lib/utils/extractTrayJSONData'
import { useTrayConfigurationContext } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.context'

import { MappedTrays } from 'views/TrayManagementLayout/TrayManagement/TrayManagement.types'
import { InventoryLocation } from 'views/LocationManagement/LocationManagement.types'
import {
  TrayStatus,
  TrayStatusDetails,
  trayStatusDisplayMap,
} from 'components/organisms/EditTrayModal/EditTrayModal.types'
import { SPDContextProps } from 'views/SPDLayout/SPD/SPD.types'
import { ItemResponse } from 'common/types'
import { formatSurgeryProviderName } from 'lib/utils/formatSurgeryProviderName'
import { useUpdateTrayItem } from 'lib/apollo/hooks'
import { useUser } from 'app/User'
import dayjs from 'lib/dayjs'
import classNames from 'classnames'
import { LoadingButton } from 'components/mui'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'
import { useFlags } from 'launchdarkly-react-client-sdk'

/**
 * Custom hook for managing tray logic in a tray management feature.
 * Provides state and functions to handle tray operations.
 * @returns Object containing state variables and functions for tray management.
 */
const useTrayManagementLogic = () => {
  const navigate = useNavigate()
  const flags = useFlags()
  const { data, loading: isLoadingLocations } = useGetLocationsTree()
  const {
    refetch,
    setSelectedTrayCategory,
    setSelectedTray,
    selectedTray,
    isListOfSurgeriesOpen,
    setIsListOfSurgeriesOpen,
    isAddTrayModalOpen,
    setIsAddTrayModalOpen,
    isEditTrayStatusModalOpen,
    setIsEditTrayStatusModalOpen,
    trays,
  } = useTrayConfigurationContext()
  const { editTrayItem } = useUpdateTrayItem()
  const { user } = useUser()
  const { setTrayData } = useSPD()

  const [locations, setLocations] = useState<InventoryLocation[]>([])
  const [locationsWithParents, setLocationsWithParents] = useState<
    InventoryLocation[]
  >([])
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false)
  const [isRejectionDetailsModalOpen, setIsRejectionDetailsModalOpen] =
    useState<boolean>(false)
  const [rejectionDetails, setRejectionDetails] =
    useState<TrayStatusDetails | null>(null)
  const [
    isShowChooseTrayLocationModalVisible,
    setIsShowChooseTrayLocationModalVisible,
  ] = useState<boolean>(false)

  const selectedTrayCategory = selectedTray
    ? extractTrayJSONData(selectedTray.trayItem)?.trayCategory
    : undefined
  const isSPD = user?.roles?.includes('MGAT_SPD')
  const isRep = user?.roles?.includes('MGAT_REP')
  const isNurse =
    user?.roles?.includes('MGAT_NURSE') && !user?.roles?.includes('MGAT_SPD')

  /**
   * Defines the header cells for the tray management table.
   */
  const trayTableHeaderCells: HeadCell[] = [
    { id: 'trayIcon', label: '', numeric: false },
    { id: 'trayID', label: 'Tray Id', numeric: false },
    { id: 'vendor', label: 'Vendor', numeric: false },
    { id: 'createdAt', label: 'Created At', numeric: false },
    { id: 'case', label: 'Case', numeric: false },
    { id: 'status', label: 'Status', numeric: false },
    { id: 'location', label: 'Location', numeric: false },
    { id: 'trayDetails', label: 'Tray Details', numeric: false },
  ]

  /**
   * Redirects the user to the SPD workflow so they can start loading their tray immediately
   */
  const handleLoadTray = (selectedTray: ItemResponse) => {
    const trayData = {
      trayID: selectedTray.barcode || '',
      trayItem: selectedTray,
    }

    const encodedTrayData = Buffer.from(JSON.stringify(trayData)).toString(
      'base64'
    )

    navigate(`/spd?trayData=${encodedTrayData}`)
  }

  const handleChooseTrayLocationModalOpen = (tray: ItemResponse) => {
    setIsShowChooseTrayLocationModalVisible(true)
    setTrayData({
      trayID: `${tray.id}`,
      trayItem: tray,
    })
  }

  const handleChooseTrayLocationModalClose = () => {
    setIsShowChooseTrayLocationModalVisible(false)
    setTrayData(null)
  }

  /**
   * Maps tray data into structured cells for the tray management table body.
   * @param trays Array of tray items to be mapped into table rows.
   * @returns Mapped tray data formatted for table display.
   */
  const trayTableBodyCells = (trays: ItemResponse[]): MappedTrays[] => {
    return trays.map((tray) => {
      const trayDetails = extractTrayJSONData(tray)
      const surgery = trayDetails?.surgeryDetails
      const isAssigned = trayDetails?.surgeryId && trayDetails?.surgeryDetails
      const hasCaseNumber = surgery?.surgicalCase?.number
      const caseDisplayText = hasCaseNumber
        ? `CN-${surgery?.surgicalCase?.number}`
        : surgery?._id || 'Unassigned'
      const shouldBeAllowedToEditTrayStatus =
        isSPD || (isRep && trayDetails?.status === 'readyToFill')
      const shouldBeAllowedToAssignToSurgery =
        trayDetails?.status !== 'rejected' &&
        !isRep &&
        (!(isNurse && isAssigned) || isSPD)
      const selectedSurgeon = trayDetails?.selectedSurgeon || 'Unassigned'
      const facilityTrayImg = require('assets/icons/images/facility-tray.jpg')
      const consignedTrayImg = require('assets/icons/images/consigned-tray.jpg')
      const loanerTrayImg = require('assets/icons/images/loaner-tray.jpg')
      const trayImg =
        trayDetails?.trayCategory === 'facility'
          ? facilityTrayImg
          : trayDetails?.trayCategory === 'consigned'
          ? consignedTrayImg
          : loanerTrayImg

      return {
        trayIcon: (
          <div
            className={`tray-icon-svg_container ${trayDetails?.trayCategory}`}
          >
            <img src={trayImg} alt="Tray Icon" className="tray-icon_svg" />
          </div>
        ),
        trayId: `${tray.barcode}`,
        vendor: `${tray.companyName}`,
        createdAt: `${dayjs(tray.createdAt).format('DD/MM/YYYY h:mm A')}`,
        case: (
          <div className="case-number_container">
            {shouldBeAllowedToAssignToSurgery && (
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  handleAssignToSurgeryClick(tray)
                }}
              >
                <Create />
              </Button>
            )}
            <Typography
              variant="body2"
              className={classNames({
                'un-assigned': caseDisplayText === 'Unassigned',
                'case-information_wrapper': caseDisplayText !== 'Unassigned',
              })}
            >
              {caseDisplayText === 'Unassigned' ? (
                <Typography variant="body2">Unassigned</Typography>
              ) : (
                <>
                  <Typography variant="body2">
                    - <b>Room:</b> {surgery?.visit?.location?.room}
                  </Typography>
                  {(isNurse || isSPD) && (
                    <>
                      <Typography variant="body2">
                        - <b>Patient:</b>
                        {`${
                          surgery?.patient?.lastName
                        }, ${surgery?.patient?.firstName?.charAt(0)}`}
                      </Typography>
                      <Typography variant="body2">
                        - <b>DOB:</b> {surgery?.patient?.dateOfBirth}
                      </Typography>
                      <Typography variant="body2">
                        - <b>MRN:</b> {surgery?.patient?.idMR}
                      </Typography>
                      <br />
                    </>
                  )}
                  {flags.procedureListSurgeryType && (
                    <Typography variant="body2">
                      - <b>Surgery:</b> {surgery?.procedures?.[0]?.description}
                    </Typography>
                  )}
                  <Typography variant="body2">
                    - <b>Surgeon:</b>{' '}
                    {surgery && surgery.visit?.attendingProvider
                      ? formatSurgeryProviderName(surgery)
                      : selectedSurgeon}
                  </Typography>
                </>
              )}
            </Typography>
          </div>
        ),
        status: (
          <div className="case-number_container">
            {shouldBeAllowedToEditTrayStatus && (
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  handleEditTrayStatusClick(tray)
                }}
              >
                <Create />
              </Button>
            )}
            <div
              className={classNames('tray-status_container', {
                [trayDetails?.status || 'unknown']: true,
              })}
            >
              {trayDetails?.status !== 'approved' &&
                trayDetails?.statusDetails?.isApproved && (
                  <Typography
                    variant="body2"
                    className="tray-status_text approved"
                  >
                    Approved
                  </Typography>
                )}
              <Typography variant="body2" className="tray-status_text">
                {getTrayStatusDisplay(trayDetails?.status)}
              </Typography>

              {trayDetails?.status === 'rejected' &&
                trayDetails?.statusDetails && (
                  <LoadingButton
                    mode="cancel"
                    variant="outlined"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleViewRejectionDetails(
                        trayDetails?.statusDetails || {}
                      )
                    }}
                  >
                    View Details
                  </LoadingButton>
                )}

              {trayDetails?.status === 'readyToFill' && isSPD && (
                <LoadingButton
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleLoadTray(tray)
                  }}
                >
                  Load Tray
                </LoadingButton>
              )}

              <Typography variant="subtitle2">
                {trayDetails?.statusDetails?.lastUpdatedAt &&
                  `Last updated at ${dayjs(
                    trayDetails.statusDetails.lastUpdatedAt
                  ).format('DD/MM/YYYY h:mm A')}`}
              </Typography>
            </div>
          </div>
        ),
        location: isLoadingLocations ? (
          'Loading...'
        ) : (
          <div className="case-number_container">
            {(isSPD || isNurse) && (
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  handleChooseTrayLocationModalOpen(tray)
                }}
              >
                <Create />
              </Button>
            )}

            <Typography variant="body2">
              {findLocation(tray?.locationId as number)?.name}
            </Typography>
          </div>
        ),
        trayDetails: (
          <Button onClick={() => handleViewDetailsClick(tray)}>
            <ReadMore />
          </Button>
        ),
      }
    })
  }

  /**
   * Effect to update locations state when data changes.
   */
  useEffect(() => {
    if (data) {
      const { hospital } = data.getLocationsTree
      const allLocations = hospital.locations
      const locationsWithParents = allLocations.filter(
        (location) => location.parentLocationId
      )

      setLocationsWithParents(locationsWithParents)
      setLocations(allLocations)
    }
  }, [data])

  /**
   * Effect to open details modal when selected tray changes.
   */
  useEffect(() => {
    if (selectedTray && !isListOfSurgeriesOpen) {
      setIsDetailsModalOpen(true)
    }
  }, [selectedTray, isListOfSurgeriesOpen])

  /**
   * Opens the modal for adding a new tray.
   */
  const handleAddTrayModalOpen = () => {
    setIsAddTrayModalOpen(true)
  }

  /**
   * Redirects user to the add tray page.
   */
  const handleAddTrayClick = () => {
    navigate('/tray-management/add-tray')
  }

  /**
   * Closes the modal for adding a new tray.
   */
  const handleAddTrayModalClose = () => {
    setSelectedTrayCategory(null)
    setIsAddTrayModalOpen(false)
  }

  /**
   * Finds a location by its ID.
   * @param locationId The ID of the location to find.
   * @returns The location object if found, otherwise undefined.
   */
  const findLocation = (locationId: number): InventoryLocation | undefined => {
    return locations.find((location) => location.id === locationId)
  }

  /**
   * Closes the details modal for the selected tray.
   */
  const handleDetailsModalClose = () => {
    setSelectedTray(null)
    setIsDetailsModalOpen(false)
  }

  /**
   * Opens the list of surgeries modal and sets the selected tray.
   * @param selectedTray The tray to be assigned to a surgery.
   */
  const handleAssignToSurgeryClick = (tray: ItemResponse) => {
    const spdTray: SPDContextProps['trayData'] = {
      trayID: `${tray.id}`,
      trayItem: tray,
    }

    setIsListOfSurgeriesOpen(true)
    setIsDetailsModalOpen(false)
    setSelectedTray(spdTray)
  }

  /**
   * Closes the list of surgeries modal.
   */
  const handleListOfSurgeriesClose = () => {
    setIsListOfSurgeriesOpen(false)
    setSelectedTray(null)
  }

  /**
   * Navigates to view details of the selected tray.
   * @param tray The tray item for which details are to be viewed.
   */
  const handleViewDetailsClick = (tray: ItemResponse) => {
    navigate(`./${tray.barcode}`)
  }

  /**
   * Retrieves the display name for a given tray status.
   * @param trayStatus The status of the tray.
   * @returns The display name of the tray status.
   */
  const getTrayStatusDisplay = (trayStatus: TrayStatus): string => {
    if (trayStatus === undefined) {
      return 'Unknown Status'
    }
    return trayStatusDisplayMap[trayStatus] ?? 'Unknown Status'
  }

  const handleEditTrayStatusClick = (tray?: ItemResponse) => {
    if (tray) {
      setSelectedTray({
        trayID: `${tray.id}`,
        trayItem: tray,
      })
    }
    setIsEditTrayStatusModalOpen((prev) => !prev)
  }

  const handleEditTrayStatusChange = async (status: TrayStatus) => {
    const trayItem = selectedTray?.trayItem

    if (!selectedTray || !trayItem) {
      return
    }

    const trayDetails = extractTrayJSONData(selectedTray.trayItem)

    try {
      const statusDetails: TrayStatusDetails = {
        ...(trayDetails?.statusDetails || {}),
        isRejected: status === 'rejected',
        isApproved: status === 'approved',
        lastUpdatedBy: user?.email,
        lastUpdatedAt: new Date().toISOString(),
      }

      const newProductDetails = JSON.stringify({
        ...trayDetails,
        status,
        ...(status === 'unAssigned'
          ? { surgeryId: undefined, surgeryDetails: undefined }
          : {}),
        statusDetails,
      })

      const { subItems, ...newTrayItem } = trayItem

      const editResult = await editTrayItem({
        ...newTrayItem,
        productDetails: newProductDetails,
      })

      if (!editResult) {
        throw new Error('Failed to edit tray item.')
      }

      handleEditTrayStatusClick()
      refetch()
    } catch (error) {
      console.error(error)
    }
  }

  const handleViewRejectionDetails = (rejectionDetails: TrayStatusDetails) => {
    setRejectionDetails(rejectionDetails)
    setIsRejectionDetailsModalOpen(true)
  }

  const handleRejectionDetailsModalClose = () => {
    setIsRejectionDetailsModalOpen(false)
    setRejectionDetails(null)
  }

  const handleRowClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    row: MappedTrays
  ) => {
    if (!event.defaultPrevented) {
      const tray = trays.find((t) => t.barcode === row.trayId)
      if (tray) {
        handleViewDetailsClick(tray)
      }
    }
  }

  return {
    isAddTrayModalOpen,
    setIsAddTrayModalOpen,
    handleAddTrayModalOpen,
    handleAddTrayModalClose,
    locations,
    locationsWithParents,
    isLoadingLocations,
    findLocation,
    isDetailsModalOpen,
    setIsDetailsModalOpen,
    handleDetailsModalClose,
    handleAssignToSurgeryClick,
    handleListOfSurgeriesClose,
    trayTableBodyCells,
    trayTableHeaderCells,
    getTrayStatusDisplay,
    handleAddTrayClick,
    isEditTrayStatusModalOpen,
    handleEditTrayStatusClick,
    handleEditTrayStatusChange,
    selectedTrayCategory,
    isRejectionDetailsModalOpen,
    setIsRejectionDetailsModalOpen,
    rejectionDetails,
    handleRejectionDetailsModalClose,
    handleRowClick,
    handleLoadTray,
    isShowChooseTrayLocationModalVisible,
    setIsShowChooseTrayLocationModalVisible,
    handleChooseTrayLocationModalOpen,
    handleChooseTrayLocationModalClose,
  }
}

export default useTrayManagementLogic
