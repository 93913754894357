import { Box, Chip, IconButton, Paper, Theme, SxProps } from '@mui/material'
import MoreTimeIcon from '@mui/icons-material/MoreTime'
import DeleteIcon from '@mui/icons-material/Delete'
import dayjs from 'lib/dayjs'
import { Scan, UndocumentedScan } from 'common/types'
import { useDeleteUndocumentedScanMutation } from 'lib/apollo/hooks'
import { AssetDetailsCard } from 'views/assets/AssetDetailsCard/AssetDetailsCard'
import { getAssetTypeLabel } from 'lib/utils/getAssetTypeLabel'
import AssetDetailsStartIcon from './molecules/AssetDetailsStartIcon/AssetDetailsStartIcon'
import { AssetDetailsList } from 'views/assets/AssetDetailsCard/AssetDetailsCard.types'

interface UndocumentedScanCardProps {
  surgeryId: string
  scan: UndocumentedScan | Scan
  isExpireAccepted?: boolean
  cardStyles?: SxProps<Theme>
  onClick?: () => void
  disabled?: boolean
  renderDelete?: boolean
  dataTestId: string
  isSelected?: boolean
  isManualAddition?: boolean
}

export const UndocumentedScanCard = ({
  surgeryId,
  scan,
  isExpireAccepted,
  cardStyles,
  onClick,
  disabled,
  renderDelete = true,
  dataTestId,
  isSelected,
  isManualAddition,
}: UndocumentedScanCardProps) => {
  // moving to undocumented context will add the disabled loading state to all assets, and not just the one that is being deleted
  const [deleteUndocumentedScan, { loading: deleteLoading }] =
    useDeleteUndocumentedScanMutation(surgeryId)

  const onDeleteClick = async (e: any) => {
    e?.stopPropagation()

    await deleteUndocumentedScan({ variables: { scanIds: [scan._id] } })
  }

  const endComponent = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      justifyContent="center"
      rowGap={1}
      sx={{
        flexDirection: { xs: 'row', sm: 'column' },
        justifyContent: {
          xs: renderDelete ? 'space-between' : 'center',
          sm: 'flex-end',
        },
        alignItems: { xs: 'center', sm: 'flex-end' },
        width: { xs: '100%', sm: 'auto' },
        ml: 1,
      }}
    >
      {renderDelete ? (
        <IconButton
          data-testid="delete-undocumented-asset-button"
          disabled={deleteLoading || disabled}
          aria-label="delete"
          size="small"
          sx={{
            order: { xs: 1, sm: 0 },
          }}
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </IconButton>
      ) : null}
      <Chip
        size="small"
        variant="filled"
        icon={<MoreTimeIcon fontSize="small" />}
        label={dayjs(scan.createdAt).format('HH:mm M-D-YY')}
        sx={{
          '> span': {
            mt: 0.5,
          },
          order: { xs: 0, sm: 1 },
        }}
      />
    </Box>
  )

  const assetDetails: AssetDetailsList = {
    assetTitle: {
      value: scan.deviceDescription,
    },
    assetSizeText: {
      value: scan.sizeText,
    },
    assetTypeLabel: {
      value: getAssetTypeLabel(scan.assetType) || 'Implantable Other',
    },
    assetManufacturer: {
      value: scan.parentCompany
        ? `${scan.parentCompany} [${scan.companyName}]`
        : scan.companyName,
    },
    deviceId: {
      value: scan.deviceId,
    },
    assetTrayId: {
      value: null, // asset trays added after dispositioning
    },
    modelNumber: {
      value: scan.versionModelNumber,
    },
    catalogNumber: {
      value: scan.catalogNumber,
    },
    lotNumber: {
      value: scan.lotBatch,
    },
    serialNumber: {
      value: scan.serialNumber,
    },
    expirationDate: {
      value: scan.expirationDate,
    },
    deviceCount: {
      value: scan.deviceCount,
    },
    usedCount: {
      value: scan.count,
    },
  }

  return (
    <Paper
      data-testid={`undocumented-scan-card-${dataTestId}`}
      variant="outlined"
      sx={{ ...cardStyles, opacity: deleteLoading || disabled ? 0.5 : 1 }}
      className={`${isSelected ? 'selected' : 'unselected'} ${
        disabled ? 'disabled' : 'enabled'
      }`}
    >
      <AssetDetailsCard
        startComponent={
          <AssetDetailsStartIcon
            isManualAddition={isManualAddition as boolean}
          />
        }
        endComponent={endComponent}
        assetDetails={assetDetails}
        isExpireAccepted={isExpireAccepted ? isExpireAccepted : false}
        assetDeleteLoading={deleteLoading}
        assetSelectHandle={onClick}
        assetType={scan.assetType}
        renderButton
        renderUsedCounts
        isDisabled={disabled}
        dataTestId={dataTestId}
        isDTMScrew={scan.isDTMScrew}
        dtmScrewData={scan.dtmScrewData}
      />
    </Paper>
  )
}
