import React from 'react'

// Components
import { CustomModal } from 'components/molecules/Modal/Modal'
import Button from 'components/molecules/Button/Button'

import { Typography } from '@mui/material'

// Types
import { MediaUploadModalProps } from './MediaUploadModal.types'

// Style
import './MediaUploadModal.scss'

// Other
import useMediaUploadModalLogic from './MediaUploadModal.logic'
import CameraCapture from '../CameraCapture/CameraCapture'
import ImageWithDetails from '../ImageDetails/ImageDetails'
import classNames from 'classnames'

const MediaUploadModal: React.FC<MediaUploadModalProps> = ({
  isOpen,
  onClose,
  onSave,
  isLoading,
  primaryButtonText,
}) => {
  const {
    isEmpty,
    selectedFiles,
    uploadInputRef,
    isMobileOrTablet,
    openCamera,
    handleFileChange,
    handleDelete,
    handleSave,
    handleCaptureFromCamera,
  } = useMediaUploadModalLogic({
    isOpen,
    onClose,
    onSave,
    isLoading,
  })

  return (
    <CustomModal
      open={isOpen}
      handleOnSubmit={handleSave}
      secondaryButtonAction={onClose}
      header="Attach images to product"
      isLoadingState={isLoading}
      primaryButtonText={primaryButtonText || 'Save'}
      icon={<></>}
      primaryButtonDisabled={isEmpty}
    >
      <div
        className={classNames('media-upload-modal-container', {
          'is-mobile': isMobileOrTablet,
        })}
      >
        {isMobileOrTablet ? (
          <input
            className="hidden-input"
            ref={uploadInputRef}
            type="file"
            capture="environment"
            accept="image/*"
            onChange={handleFileChange}
          />
        ) : (
          <CameraCapture
            onCapture={handleCaptureFromCamera}
            canCapture={!isLoading}
          />
        )}

        {isEmpty && (
          <div className="empty-state">
            <Typography variant="h5">No images added yet.</Typography>
          </div>
        )}

        {!isEmpty && (
          <div className="images">
            {selectedFiles.map((file, index) => (
              <ImageWithDetails
                onClick={() => {}}
                disableActions={isLoading}
                key={index}
                handleDelete={() => handleDelete(index)}
                file={file}
                src={URL.createObjectURL(file)}
                alt={`preview-${index}`}
              />
            ))}
          </div>
        )}

        {isMobileOrTablet && (
          <div className="actions">
            <Button
              fullWidth
              onClick={openCamera}
              variant="outlined"
              size="large"
              className="attach-button"
              disabled={isLoading}
            >
              {isEmpty ? 'Attach Images' : 'Add more'}
            </Button>
          </div>
        )}
      </div>
    </CustomModal>
  )
}

export default MediaUploadModal
