import { Point } from './GrayScale.types'
import './SelectionBox.scss'

interface SelectionBoxProps {
  startPoint: Point
  endPoint: Point
}

export const SelectionBox = ({ startPoint, endPoint }: SelectionBoxProps) => {
  const left = Math.min(startPoint.x, endPoint.x)
  const top = Math.min(startPoint.y, endPoint.y)
  const width = Math.abs(endPoint.x - startPoint.x)
  const height = Math.abs(endPoint.y - startPoint.y)

  return (
    <div
      className="selection-box"
      style={{
        left,
        top,
        width,
        height,
      }}
    />
  )
} 