import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import { AssetType } from 'common/disposition'
import { useIsMobile } from 'lib/utils/useIsMobile'
import { isDatePast } from 'lib/utils/isDatePast'
import { parseAssetIdentifiers } from 'lib/utils/ParseAssetIdentifiers/parseAssetIdentifiers'
import './AssetDetailsCard.scss'
import classNames from 'classnames'
import { AssetDetailsList, AssetDetailsProps } from './AssetDetailsCard.types'
import { useMemo, useState } from 'react'
import { Surgery } from 'common/types'
import ImplantableOtherRecordSender from 'components/ImplantableOtherRecordSender'
import { TRecordSenderAssetDetails } from 'components/ImplantableOtherRecordSender/ImplantableOtherRecordSender.types'
import { useLocation, useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useUser } from 'app/User'

export const AssetDetailsCard = ({
  startComponent,
  endComponent,
  children,
  assetDetails,
  isExpireAccepted = false,
  assetDeleteLoading,
  assetSelectHandle,
  assetType,
  isExplanted = false,
  renderButton = false,
  renderUsedCounts = false,
  isDisabled,
  dataTestId,
  isDTMScrew,
  dtmScrewData,
  isProcedureSubmitted,
  printMode,
  isAutopopulated = true,
}: AssetDetailsProps) => {
  const flags = useFlags()
  const { surgeryId } = useParams() as { surgeryId: Surgery['_id'] }
  const { pathname } = useLocation()
  const isMobile = useIsMobile()

  const { isRep } = useUser()
  const repCanEdit = flags?.repCanEdit ?? false
  const disallowSelection = isRep && !repCanEdit ? true : false

  const [ShowRecordSenderPopup, setShowRecordSenderPopup] = useState(false)

  const showShare =
    useLocation().pathname.endsWith('list') &&
    isProcedureSubmitted &&
    flags.shareImplantableOthersWithRep &&
    !isRep

  // handle multipack asset types
  const {
    isMultipackAsset,
    isRfMultipackConsumable,
    isSpongeConsumable,
    isMultipackHardware,
  } = parseAssetIdentifiers({
    deviceDescription: assetDetails.assetTitle.value,
    deviceCount: assetDetails.deviceCount.value,
    assetType: assetType,
  })

  const recordSenderAssetDetails: TRecordSenderAssetDetails = {
    assetTitle: assetDetails?.assetTitle?.value as string,
    deviceId: assetDetails?.deviceId?.value as string,
    lotNumber: assetDetails?.lotNumber?.value as string,
    modelNumber: assetDetails?.modelNumber?.value as string,
    expirationDate: assetDetails?.expirationDate?.value as string,
    deviceCount: assetDetails?.deviceCount?.value as number,
    usedCount: assetDetails?.usedCount?.value as number,
  }

  // handle which asset details to display
  const DISPLAY_FIELD_MAP: Partial<Record<keyof AssetDetailsList, string>> = {
    assetTypeLabel: 'Type',
    assetManufacturer: 'Manufacturer',
    deviceId: 'Device ID',
    assetTrayId: 'Inventory Location ID',
    modelNumber: 'Model Number',
    catalogNumber: 'Catalog Number',
    lotNumber: 'Lot Number',
    serialNumber: 'Serial Number',
    expirationDate: 'Expiration Date',
    deviceCount: 'Device Count',
    usedCount:
      isRfMultipackConsumable || isSpongeConsumable || isMultipackHardware
        ? 'Multipacks Used'
        : 'Used Count',
  }

  function handleFieldDisplay(
    assetType: AssetType,
    isDTMScrew: boolean = false
  ) {
    const commonFields = [
      'assetTitle',
      'assetManufacturer',
      'deviceId',
      'assetTrayId',
      'catalogNumber',
      'modelNumber',
    ]

    if (!isDTMScrew) {
      commonFields.push('expirationDate')
    }

    let additionalFields: string[] = []

    if (!isDTMScrew) {
      additionalFields =
        assetType === 'biological'
          ? ['modelNumber', 'catalogNumber', 'lotNumber', 'serialNumber']
          : []
      if (isMultipackAsset) {
        additionalFields.push('deviceCount')
      }
      if (renderUsedCounts) {
        additionalFields.push('usedCount')
      }
    }

    return commonFields.concat(additionalFields)
  }

  const { isDateExpired, expDate } = useMemo(() => {
    const expirationDateValue = assetDetails['expirationDate'].value
    return isDatePast({ date: expirationDateValue })
  }, [assetDetails])

  assetDetails['expirationDate'].value = expDate

  if (assetDetails['expirationDate'].value === null) {
    assetDetails['expirationDate'].value = 'Expiration Not Found'
  }

  const assetDetailComponent = (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      flexGrow="1"
    >
      <Typography
        variant="h5"
        sx={{
          mb: 1,
          wordBreak: 'break-word',
          maxWidth: '360px',
        }}
        color="text.primary"
        align="left"
      >
        {assetDetails.assetSizeText.value
          ? `${
              assetDetails?.assetTitle?.value?.includes(
                assetDetails.assetSizeText.value
              )
                ? assetDetails.assetTitle.value
                : `${assetDetails.assetTitle.value} ${assetDetails.assetSizeText.value}`
            }`
          : `${assetDetails.assetTitle.value}`}
      </Typography>
      {Object.entries(DISPLAY_FIELD_MAP).map(([key, value]) => {
        const fieldsToDisplay = handleFieldDisplay(assetType, isDTMScrew)

        // handle explanted assets
        if (isExplanted && assetDetails['assetTypeLabel'].value) {
          fieldsToDisplay.unshift('assetTypeLabel')
        }

        // handle null asset tray IDs
        if (key === 'assetTrayId' && assetDetails[key].value === null) {
          return null
        }

        if (
          isAutopopulated &&
          (!assetDetails[key as keyof AssetDetailsList]?.value ||
            key === 'expirationDate')
        ) {
          return null
        }

        if (fieldsToDisplay?.includes(key)) {
          return (
            <Box display="flex" flexDirection="row" flexGrow="1" key={key}>
              <Typography
                key={key}
                variant="body2"
                fontSize={12}
                color="text.secondary"
                sx={{
                  mr: 0.5,
                }}
              >
                {value}:
              </Typography>
              <Typography
                variant="body2"
                fontWeight={500}
                fontSize={13}
                component="span"
                color={
                  key === 'expirationDate' && isDateExpired
                    ? isExpireAccepted
                      ? 'orange'
                      : 'red'
                    : 'text.secondary'
                }
              >
                {assetDetails[key as keyof AssetDetailsList]?.value ||
                  `${value} not found`}
              </Typography>
            </Box>
          )
        }

        return null
      })}
      {isDTMScrew && dtmScrewData && (
        <>
          {dtmScrewData.label && (
            <Box display="flex" flexDirection="row" flexGrow="1">
              <Typography
                variant="body2"
                fontSize={12}
                color="text.secondary"
                sx={{
                  mr: 0.5,
                }}
              >
                Location:
              </Typography>
              <Typography
                variant="body2"
                fontWeight={500}
                fontSize={13}
                component="span"
                color="text.secondary"
              >
                {dtmScrewData?.label}, row: {dtmScrewData?.row}, size:{' '}
                {dtmScrewData?.size}
              </Typography>
            </Box>
          )}

          {!pathname.includes('list') && (
            <Stack
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              sx={{ borderTop: '1px solid #D5D5D5', mt: '15px', pt: '10px' }}
            >
              <Typography
                fontSize={14}
                fontWeight={400}
                color="#000000"
                textAlign="left"
              >
                {dtmScrewData?.wasted ? 'Wasted' : 'Implanted'}
              </Typography>
            </Stack>
          )}
        </>
      )}
    </Box>
  )

  return (
    <>
      <ButtonBase
        aria-label="asset-details-card-grid-container"
        className={classNames('asset-details-card-container', {
          'is-mobile': isMobile,
          'has-reps': ShowRecordSenderPopup,
        })}
        style={{
          cursor: disallowSelection
            ? 'auto'
            : !renderButton || isDisabled
            ? 'auto'
            : 'pointer',
        }}
        onClick={() => {
          if (!disallowSelection && !(assetDeleteLoading || isDisabled)) {
            assetSelectHandle && assetSelectHandle()
          }
        }}
      >
        {/* start of row */}
        <Box
          aria-label="asset-details-card-box-row"
          className="asset-details-card-box-row"
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { sx: 'flex-start', sm: 'center' },
          }}
        >
          <div className="left-side">
            {/* start component */}
            {startComponent && <div>{startComponent}</div>}

            {/* asset details component */}
            <div aria-label="asset-details-card-details-grid-item">
              {renderButton ? (
                <div data-testid={`${dataTestId}-button`}>
                  {assetDetailComponent}
                </div>
              ) : (
                assetDetailComponent
              )}
            </div>
          </div>

          {/* end component */}
          {endComponent && <div>{endComponent}</div>}

          {!printMode && assetType === 'other-non-biological' && showShare && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Button onClick={() => setShowRecordSenderPopup((prev) => !prev)}>
                Share
              </Button>
            </div>
          )}

          {/* end of container row */}
        </Box>
        {/* children sub column */}
        {children && (
          <div className="asset-card-children">
            <Divider variant="middle" sx={{ m: 1 }} />
            <div>{children}</div>
          </div>
        )}
        {/* end of  container column */}
      </ButtonBase>
      {!printMode && ShowRecordSenderPopup && (
        <ImplantableOtherRecordSender
          bidCompanyId={assetDetails.bidCompanyId?.value as number}
          manufacturerName={assetDetails.assetManufacturer.value}
          surgeryId={surgeryId}
          assetDetails={recordSenderAssetDetails}
        />
      )}
    </>
  )
}
