import { CustomModal } from 'components/molecules/Modal/Modal'
import { FC } from 'react'

// Components
import TextInput from 'components/molecules/TextInput/TextInput'
import AutocompleteInput from 'components/organisms/AutocompleteInput/AutocompleteInput'

// Logic
import useAdmitNewPatientLogic from './AdmitNewPatient.logic'

// Types
import { IAdmitNewPatient } from './AdmitNewPatient.types'
import { DateInput } from 'components/mui'

// Hook form
import { Controller } from 'react-hook-form'

// Style
import './AdmitNewPatient.scss'

const AdmitNewPatient: FC<IAdmitNewPatient> = (props) => {
  const { open, onClose } = props
  const { control, errors, isLoadingAdmitPatient, handleOnSubmit } =
    useAdmitNewPatientLogic(props)

  return (
    <CustomModal
      header="Admit New Patient"
      isLoadingState={isLoadingAdmitPatient}
      open={open}
      secondaryButtonAction={() => onClose && onClose('', 'escapeKeyDown')}
      handleOnSubmit={handleOnSubmit}
      icon={<></>}
    >
      <div className="admit-new-patient-container">
        <form onSubmit={handleOnSubmit}>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: {
                message: 'First name is required.',
                value: true,
              },
            }}
            render={({ field: { onChange, value, ...field } }) => (
              <TextInput
                {...field}
                shrink
                value={value}
                handleChangeFormData={onChange}
                variant="outlined"
                placeholder="First Name"
                label="First Name"
                error={!!errors.firstName}
                errorMessage={errors.firstName?.message}
                disabled={isLoadingAdmitPatient}
              />
            )}
          />

          <Controller
            name="lastName"
            control={control}
            rules={{
              required: {
                message: 'Last name is required.',
                value: true,
              },
            }}
            render={({ field: { onChange, value, ...field } }) => (
              <TextInput
                {...field}
                shrink
                value={value}
                handleChangeFormData={onChange}
                variant="outlined"
                placeholder="Last Name"
                label="Last Name"
                error={!!errors.lastName}
                errorMessage={errors.lastName?.message}
                disabled={isLoadingAdmitPatient}
              />
            )}
          />

          <Controller
            name="mrn"
            control={control}
            rules={{
              required: {
                message: 'MRN is required.',
                value: true,
              },
            }}
            render={({ field: { onChange, value, ...field } }) => (
              <TextInput
                {...field}
                shrink
                value={value}
                handleChangeFormData={onChange}
                variant="outlined"
                placeholder="MRN"
                label="MRN"
                error={!!errors.mrn}
                errorMessage={errors.mrn?.message}
                disabled={isLoadingAdmitPatient}
              />
            )}
          />
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{
              required: {
                message: 'Date of birth is required.',
                value: true,
              },
            }}
            render={({ field: { onChange, value, ...field } }) => (
              <DateInput
                placeholder="MM/DD/YYYY"
                format="MM/DD/YYYY"
                className="date-input"
                readOnly
                openTo="month"
                handleChangeFormData={onChange}
                label="Date of Birth*"
                value={value}
                error={!!errors.dateOfBirth}
                variant="outlined"
                helperText={errors.dateOfBirth?.message}
                fullWidth
                disabled={isLoadingAdmitPatient}
                sx={{
                  backgroundColor: 'white',
                  mt: 1,
                }}
                {...field}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            rules={{
              required: {
                message: 'Gender is required.',
                value: true,
              },
            }}
            render={({ field: { onChange, value, ...field } }) => (
              <AutocompleteInput
                options={[
                  {
                    id: 'Male',
                    name: 'Male',
                  },
                  {
                    id: 'Female',
                    name: 'Female',
                  },
                ]}
                shrink
                onSelect={onChange}
                variant="outlined"
                placeholder="Gender"
                label="Gender"
                error={!!errors.gender}
                helperText={errors.gender?.message}
                disabled={isLoadingAdmitPatient}
                value={value}
                handleChangeFormData={(e) => onChange(e.target.value)}
                sx={{
                  mt: 1,
                }}
                {...field}
              />
            )}
          />
        </form>
      </div>
    </CustomModal>
  )
}

export default AdmitNewPatient
