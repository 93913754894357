import './SubTrays.scss'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { LoadingButton } from 'components/mui'
import useSubTraysLogic from './SubTrays.logic'
import { DataTable } from 'components/molecules/DataTable/DataTable'
import { useAddTrayContext } from '../AddTray.context'
import AnalyzeTray from '../AnalyzeTray/AnalyzeTray'
import TrayContent from '../TrayContent/TrayContent'
import { SubTraysProvider } from './SubTrays.context'
import useAnalyzeTrayLogic from '../AnalyzeTray/AnalyzeTray.logic'
import AlertDialog from 'components/AlertDialog/AlertDialog'

const SubTraysTable = () => {
  const { subTrays } = useAddTrayContext()
  const {
    handleAddInstrumentTrayClick,
    handleAddImplantTrayClick,
    handleAddInstrumentWithSubTraysClick,
    subTraysTableHeaderCells,
    subTraysTableBodyCells,
    handleNext,
    handleBack,
    showDeleteConfirmation,
    handleConfirmDelete,
    handleCancelDelete,
  } = useSubTraysLogic()

  return (
    <div className="sub-trays-step">
      <AlertDialog
        isOpen={showDeleteConfirmation}
        mode="error"
        title="Delete Sub-Tray"
        description="Are you sure you want to delete this sub-tray? This action cannot be undone."
        primaryButtonText="Delete"
        primaryButtonAction={handleConfirmDelete}
        secondaryButtonText="Cancel"
        secondaryButtonAction={handleCancelDelete}
        zIndex={9999}
      />

      <div className="sub-trays-actions">
        <LoadingButton
          onClick={handleAddInstrumentTrayClick}
          startIcon={<Add />}
          fullWidth={false}
        >
          Add Instrument Tray
        </LoadingButton>
        <LoadingButton
          onClick={handleAddImplantTrayClick}
          startIcon={<Add />}
          fullWidth={false}
        >
          Add Implant Tray
        </LoadingButton>
        <LoadingButton
          onClick={handleAddInstrumentWithSubTraysClick}
          startIcon={<Add />}
          fullWidth={false}
        >
          Add Instrument Tray w/ Implants
        </LoadingButton>
      </div>

      <div className="sub-trays-table_container">
        <DataTable
          tableHeaders={subTraysTableHeaderCells}
          tableRows={subTraysTableBodyCells(subTrays)}
          isLoading={false}
          isErrored={false}
          disableSorting
        />
        <div className="buttons_container">
          <Button variant="outlined" onClick={handleBack} fullWidth>
            Back
          </Button>
          {subTrays.length > 0 ? (
            <LoadingButton variant="contained" onClick={handleNext} fullWidth>
              Next
            </LoadingButton>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  )
}

const SubTrays = () => {
  const { currentView, selectedTrayIndex } = useSubTraysLogic()
  const { handleTrayMapDrawerClose } = useAnalyzeTrayLogic()

  if (currentView === 'analyze') {
    return <AnalyzeTray />
  }

  if (currentView === 'details' && selectedTrayIndex !== undefined) {
    return <TrayContent trayIndex={selectedTrayIndex} />
  }

  return <SubTraysTable />
}

const WrappedSubTrays = () => {
  return (
    <SubTraysProvider>
      <SubTrays />
    </SubTraysProvider>
  )
}

export default WrappedSubTrays
