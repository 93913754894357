import {
  Drawer,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Button,
  RadioGroup,
  Radio,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import Select from 'components/molecules/Select/Select'
import { FilterDrawerProps } from './FilterDrawer.types'
import useFilterDrawer from './FilterDrawer.logic'

const FilterDrawer = ({
  open,
  onClose,
  filters,
  selectedFilters,
  selectedCategory,
  vendorOptions,
  companyFilterValue,
  setCompanyFilterValue,
  handleFilterClick,
  handleSubFilterClick,
  handleClearAllFilters,
}: FilterDrawerProps) => {
  const { statusFilters, selectedCategoryFilter } = useFilterDrawer(
    filters,
    selectedCategory
  )

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className="filter-drawer"
    >
      <div className="filter-drawer-header">
        <Typography variant="h6">Filters</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <Divider />

      <div className="filter-drawer-content">
        <div className="filter-section">
          <Typography variant="subtitle2" color="textSecondary">
            Vendor
          </Typography>
          <Select
            label=""
            fullWidth
            options={vendorOptions}
            onClear={() => setCompanyFilterValue(undefined)}
            onSelect={(value) => setCompanyFilterValue(value)}
            selectedValue={companyFilterValue?.name}
            placeholder="Select vendor..."
          />
        </div>

        <Divider />

        <div className="filter-section">
          <Typography variant="subtitle2" color="textSecondary">
            Tray Category
          </Typography>
          <RadioGroup
            value={selectedCategory || ''}
            onChange={(e) => {
              const filter = filters.find(
                (f) => f.filterValue === e.target.value
              )
              if (filter) handleFilterClick(filter)
            }}
          >
            {filters.map((filter) => (
              <FormControlLabel
                key={filter.filterValue}
                value={filter.filterValue}
                control={<Radio />}
                label={filter.title}
              />
            ))}
          </RadioGroup>
        </div>

        <div className="filter-section">
          <Typography variant="subtitle2" color="textSecondary">
            Tray Status
          </Typography>
          <FormGroup>
            {statusFilters.map((subFilter) => (
              <FormControlLabel
                key={subFilter.filterValue}
                control={
                  <Checkbox
                    checked={selectedFilters.has(subFilter.filterValue)}
                    onChange={() => handleSubFilterClick(subFilter.filterValue)}
                    size="small"
                  />
                }
                label={subFilter.title}
              />
            ))}
          </FormGroup>
        </div>

        <div className="filter-actions">
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleClearAllFilters}
          >
            Reset Filters
          </Button>
        </div>
      </div>
    </Drawer>
  )
}

export default FilterDrawer
