// Images
import pos1 from 'assets/icons/positions/pos-1.png'
import pos2 from 'assets/icons/positions/pos-2.png'
import pos3 from 'assets/icons/positions/pos-3.png'
import pos4 from 'assets/icons/positions/pos-4.png'
import pos5 from 'assets/icons/positions/pos-5.png'
import pos6 from 'assets/icons/positions/pos-6.png'
import pos7 from 'assets/icons/positions/pos-7.png'
import pos8 from 'assets/icons/positions/pos-8.png'
import pos9 from 'assets/icons/positions/pos-9.png'
import pos10 from 'assets/icons/positions/pos-10.png'
import pos11 from 'assets/icons/positions/pos-11.png'
import pos12 from 'assets/icons/positions/pos-12.png'

import { Option } from 'common/types'

export const skinPreps = [
  {
    id: 'betadine5',
    name: 'Betadine 5%',
  },
  {
    id: 'betadine10',
    name: 'Betadine 10%',
  },
  {
    id: 'dilutedBetadine',
    name: 'Diluted Betadine',
  },
  {
    id: 'chloralPrep',
    name: 'Chloralprep',
  },
  {
    id: 'chlorhexidine',
    name: 'Chlorhexidine',
  },
  {
    id: 'PCMX',
    name: '3% PCMX',
  },
]

export const positioningDevices = [
  {
    id: 'gelDonut',
    name: 'Gel Donut',
  },
  {
    id: 'gelRolls',
    name: 'Gel Rolls',
  },
]

export const packsOptions: Option[] = [
  { id: 'General', name: 'General' },
  { id: 'ECMO', name: 'ECMO' },
  { id: 'Mini', name: 'Mini' },
  { id: 'Cystoscopy', name: 'Cystoscopy' },
  { id: 'Cataract', name: 'Cataract' },
  { id: 'Eye', name: 'Eye' },
  { id: 'Craniotomy', name: 'Craniotomy' },
  { id: 'VP shunt', name: 'VP shunt' },
  { id: 'L and B', name: 'L and B' },
  { id: 'T and A', name: 'T and A' },
  { id: 'Mastoid', name: 'Mastoid' },
  { id: 'ENT', name: 'ENT' },
  { id: 'Spine', name: 'Spine' },
  { id: 'Major Ortho', name: 'Major Ortho' },
  { id: 'Plastics', name: 'Plastics' },
  { id: 'Heart', name: 'Heart' },
]

export const drillOptions = [
  { id: 'Stryker cordless driver', name: 'Stryker cordless driver' },
  { id: 'Stryker system 8', name: 'Stryker system 8' },
  { id: 'Stryker MicroAirpower', name: 'Stryker MicroAirpower' },
  { id: 'Stryker TPS', name: 'Stryker TPS' },
  { id: 'Stryker CD4', name: 'Stryker CD4' },
  { id: 'Midas Rex (pneumatic)', name: 'Midas Rex (pneumatic)' },
]

export const positions: { image: string; name: string }[] = [
  {
    image: pos1,
    name: 'Supine',
  },
  {
    image: pos2,
    name: 'Trendelenburg',
  },
  {
    image: pos3,
    name: 'Reverse Trendelenburg',
  },
  {
    image: pos4,
    name: 'Fracture Table',
  },
  {
    image: pos5,
    name: 'Lithotomy',
  },
  {
    image: pos6,
    name: 'Prone',
  },
  {
    image: pos7,
    name: 'Jackknife',
  },
  {
    image: pos8,
    name: "Fowler's",
  },
  {
    image: pos9,
    name: 'Knee-Chest',
  },
  {
    image: pos10,
    name: 'Kidney',
  },
  {
    image: pos11,
    name: 'Lateral',
  },
  {
    image: pos12,
    name: 'Wilson Frame',
  },
]
