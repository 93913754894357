import { ReactNode } from 'react'

// Components
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { IconButton } from '@mui/material'

// Style
import './ScannerActions.scss'

// Logic
import useScannerActionsLogic from './ScannerActions.logic'

// Other
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import SwipeableViews from 'react-swipeable-views'

const ScannerActions = ({ children }: { children?: ReactNode }) => {
  const {
    assetTypes,
    currentIndex,
    handleChangeIndex,
    handleSwipeChangeIndex,
  } = useScannerActionsLogic()

  return (
    <div
      className={classNames('scanner-actions', {
        'is-mobile': isMobile,
      })}
    >
      {children || null}
      <div
        className={classNames('carousel-container', {
          'is-mobile': isMobile,
        })}
      >
        {/* Left Arrow */}
        <IconButton
          className={classNames('arrow-button', {
            disabled: currentIndex === 0,
          })}
          size="small"
          disabled={currentIndex === 0}
          onClick={() => handleChangeIndex('left')}
        >
          <ArrowBackIos
            sx={{
              color: currentIndex === 0 ? 'var(--grayscale-disabled)' : 'white',
            }}
          />
        </IconButton>

        <SwipeableViews
          index={currentIndex}
          onChangeIndex={handleSwipeChangeIndex}
        >
          {assetTypes.map((type) => (
            <div key={type} className="asset-type-container">
              {type}
            </div>
          ))}
        </SwipeableViews>

        {/* Right Arrow */}
        <IconButton
          className={classNames('arrow-button', {
            disabled: currentIndex === assetTypes.length - 1,
          })}
          size="small"
          disabled={currentIndex === assetTypes.length - 1}
          onClick={() => handleChangeIndex('right')}
        >
          <ArrowForwardIos
            sx={{
              color:
                currentIndex === assetTypes.length - 1
                  ? 'var(--grayscale-disabled)'
                  : 'white',
            }}
          />
        </IconButton>
      </div>
    </div>
  )
}

export default ScannerActions
