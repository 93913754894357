import { Handyman } from '@mui/icons-material'
import { Typography } from '@mui/material'

import Modal from 'components/molecules/Modal/Modal'
import { LoadingButton } from 'components/mui'

import { useSPDScan } from 'views/SPDLayout/SPD/Scan/Scan.context'

import './ConfirmLoadMultipleScrewsModal.scss'
import useTrayMap from 'views/SPDLayout/SPD/TrayMap/TrayMap.logic'
import { useSPD } from 'views/SPDLayout/SPD/SPD.context'

export const ConfirmLoadMultipleScrewsModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const { selectedScrew } = useSPD()
  const { productData } = useSPDScan()
  const { handleStatusChange, handleConfirmLoadMultiple } = useTrayMap()

  const asset = productData[0]

  return (
    <Modal open={open} onClose={onClose}>
      <div className="confirm-load-multiple-screws_modal">
        <div className="header-box">
          <Handyman />

          <Typography variant="h3">Load 1 or Multiple Screws?</Typography>
        </div>

        <Typography variant="body1">{asset?.deviceDescription}</Typography>
        <Typography variant="body2">
          Device count: {asset?.deviceCount}
        </Typography>

        <div className="buttons-container">
          <LoadingButton
            disabled={false}
            loading={false}
            variant="outlined"
            onClick={() => {
              if (!selectedScrew) return
              handleStatusChange({
                ...selectedScrew,
                deviceId: asset.deviceId,
                company: asset.company,
                expirationDate: asset.expirationDate,
                deviceDescription: asset.deviceDescription,
              })
            }}
          >
            Load 1 Screw
          </LoadingButton>

          <LoadingButton
            variant="contained"
            disabled={false}
            loading={false}
            onClick={handleConfirmLoadMultiple}
          >
            Load Multiple Screws
          </LoadingButton>
        </div>
      </div>
    </Modal>
  )
}
