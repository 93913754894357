import { Box, Typography } from '@mui/material'
import { Close, QuestionMark, CompareArrows } from '@mui/icons-material'

import { LoadingButton } from 'components/mui'

import useTrayAnalysisContentLogic from './TrayAnalysisContent.logic'
import { TrayType } from '../EditTrayModal/EditTrayModal.types'
import './TrayAnalysisContent.scss'
import TrayMapSelector from '../TrayMapSelector/TrayMapSelector'

const renderGuideline = (
  label: string,
  color: string,
  border: string,
  count: number
) => {
  return (
    <div className="legend-item">
      <span
        className="count-circle"
        style={{ backgroundColor: color, border: border }}
      >
        {count}
      </span>
      <Typography variant="body2">{label}</Typography>
    </div>
  )
}

const TrayAnalysisContent = ({
  onClose,
  trayType = 'stryker screw caddy',
  trayImg,
}: {
  onClose: () => void
  trayType?: TrayType
  trayImg?: string
}) => {
  const { shouldShowGuide, handleGuideClick } = useTrayAnalysisContentLogic()

  return (
    <div className="loaner-tray-analysis-content_wrapper">
      <div className="header-section">
        <div className="header-content">
          <Box>
            <Typography variant="h4" lineHeight={1.2} className="main-title">
              Tray Content Analysis
            </Typography>
            <Typography
              variant="body1"
              fontSize={14}
              textTransform="capitalize"
              className="tray-info"
            >
              Tray Type: <strong>{trayType}</strong>
            </Typography>
          </Box>
          <div className="header-actions">
            <LoadingButton
              className="help-button"
              variant="outlined"
              size="small"
              onClick={handleGuideClick}
            >
              {shouldShowGuide ? (
                <>
                  <Close fontSize="small" /> Hide Legend
                </>
              ) : (
                <>
                  <QuestionMark fontSize="small" /> Show Legend
                </>
              )}
            </LoadingButton>
          </div>
        </div>
      </div>
      {shouldShowGuide && (
        <div className="legend-banner">
          <div className="legend-content">
            {renderGuideline(
              'Empty Screw Position',
              'var(--empty-screw-background)',
              '0.5px solid #ddd',
              0
            )}
            {renderGuideline(
              'Loaded Screw Position',
              'var(--active-screw-background)',
              '0.5px solid transparent',
              1
            )}
          </div>
        </div>
      )}

      <div className="info-banner">
        <CompareArrows className="icon" />
        <Typography variant="body1">
          <strong>Compare:</strong> Review the captured tray image against the
          digital tray map to verify contents
        </Typography>
      </div>

      <div className="content-section">
        <div className="analysis-panels">
          {trayImg && (
            <div className="panel image-panel">
              <div className="panel-header">
                <Typography variant="h6">Reference Image</Typography>
              </div>
              <div className="panel-content">
                <img
                  src={trayImg}
                  alt="Tray Reference"
                  className="tray-image"
                />
              </div>
            </div>
          )}

          <div className="panel map-panel">
            <div className="panel-header">
              <Typography variant="h6">Digital Tray Map</Typography>
            </div>
            <div className="panel-content">
              <TrayMapSelector trayType={trayType} loadWithUDIs={false} />
            </div>
          </div>
        </div>
      </div>

      <div className="footer-section">
        <LoadingButton
          onClick={onClose}
          className="confirm-button"
          variant="contained"
          color="primary"
        >
          Confirm Analysis
        </LoadingButton>
      </div>
    </div>
  )
}

export default TrayAnalysisContent
