import dayjs from 'lib/dayjs'
import useDecrementUsageMutation from 'lib/services/api/product-service/useUseItems'
import useFormValidation from 'lib/hooks/useFormValidation/useFormValidation'
import { CustomChangeEvent, Option } from 'common/types'
import { useRfSpongeCount } from 'lib/context/RfSpongeCountContext/RfSpongeCountContext'
import { getUDIsWithQuantity } from 'lib/utils/data'
import { getExpiredAssetCount } from 'lib/utils/getExpiredAssetCount'
import { animateScroll as scroll } from 'react-scroll'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'
import { useCallback, useEffect, useState } from 'react'
import { useUser } from 'app/User'
import {
  SubmitToEMRProps,
  submitToEmrValidationSchema,
} from './SubmitToEMR.types'
import {
  useAssignTrayStatusAsCaseCompleteMutation,
  useGetScrubTechniciansQuery,
  useSetSurgeryStatus,
} from 'lib/apollo/hooks'
import {
  ORGANIZATIONS,
  useOrganizationSettings,
} from 'lib/context/OrganizationSettingsContext'
import { useIsMobile } from 'assets/utils/mediaQueries'

export const useSubmitToEMRLogic = (props: SubmitToEMRProps) => {
  const { surgery, dtmHardwareAssetData } = props
  const { user } = useUser()
  const { showScrubTechnician } = useOrganizationSettings()

  const orgId = user?.orgId
  const isAdventistUser = orgId === ORGANIZATIONS.adventist
  const isMobile = useIsMobile()
  const { assignTrayStatusAsCaseComplete } =
    useAssignTrayStatusAsCaseCompleteMutation()

  const [setSurgeryStatusSubmitted, setSurgeryStatusSubmittedMutation] =
    useSetSurgeryStatus(surgery._id)

  const { mutateAsync: decrementUsage } = useDecrementUsageMutation()

  const userName = `${user?.firstName} ${user?.lastName}`

  const numberOfExpired = getExpiredAssetCount(surgery.assetGroups)

  const { rfSpongeAssetGroupCount } = useRfSpongeCount()

  const { undocumentedScanCount, undocumentedScans } =
    useUndocumentedAssetsContext()

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [dtmTrayIds, setDTMTrayIds] = useState<number[]>([])
  const [openScrubTechModal, setOpenScrubTechModal] = useState(false)
  const [event, setEvent] = useState<any>()

  const [selectedScrubTechnician, setSelectedScrubTechnician] =
    useState<Option | null>(null)
  const [error, setError] = useState<string>()

  const { data, loading, refetch } = useGetScrubTechniciansQuery()

  const scrubTechs: Option[] =
    data?.getScrubTechnicians.data.map((scrubTechnician) => ({
      name: `${scrubTechnician.firstName} ${scrubTechnician.lastName} - ${scrubTechnician.speciality}`,
      id: scrubTechnician._id || '',
    })) || []

  useEffect(() => {
    const dtmTrayIdsArr = dtmHardwareAssetData
      ?.flatMap((asset) => asset.scans)
      ?.filter((scan) => scan.dtmScrewData)
      ?.map((scan) => Number(scan.dtmScrewData?.trayId))

    setDTMTrayIds(dtmTrayIdsArr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undocumentedScans?.length])

  const handleSubmitRecord = useCallback(() => {
    setSurgeryStatusSubmitted({
      variables: {
        surgeryId: surgery._id,
        status: 'SUBMITTED',
        rep: userName,
        dateTime: dayjs().toISOString(),
        scrubTechnician: selectedScrubTechnician?.name,
      },
    })
      .then(() => {
        const assetsToDecrement = getUDIsWithQuantity(surgery.assetGroups)
        decrementUsage({
          items: assetsToDecrement,
        })
      })
      .finally(() => {
        scroll.scrollToTop()
      })
  }, [
    setSurgeryStatusSubmitted,
    surgery._id,
    surgery.assetGroups,
    userName,
    selectedScrubTechnician?.name,
    decrementUsage,
  ])

  const {
    isFormValidated,
    isFormSubmitted,
    isSubmitDisabled,
    handleValidation,
    FormValidationAlertToasters,
  } = useFormValidation({
    validationSchema: submitToEmrValidationSchema,
  })

  const [isSubmittedToEmr, setIsSubmittedToEmr] = useState(false)

  const isAutopopulated = surgery?.autoPopulatedConsumables?.length > 0 || false

  useEffect(() => {
    const hasMissingSpongeCount = isAutopopulated
      ? false
      : rfSpongeAssetGroupCount.some((sponge) => {
          return (
            !sponge.accurateDeviceCount &&
            !sponge.deviceCountCorrectiveActionTaken
          )
        })

    setDisableSubmit(
      setSurgeryStatusSubmittedMutation.loading
        ? false
        : isSubmitDisabled || isFormSubmitted || hasMissingSpongeCount
    )
  }, [
    setSurgeryStatusSubmittedMutation.loading,
    isSubmitDisabled,
    isFormSubmitted,
    rfSpongeAssetGroupCount,
    isAutopopulated,
  ])

  const handleUpdateTrayAssignment = useCallback(async () => {
    if (dtmTrayIds.length === 0) {
      return
    }

    try {
      const trayId = dtmTrayIds[0]

      const assignResult = await assignTrayStatusAsCaseComplete(trayId)

      if (!assignResult) {
        throw new Error('Failed to assign tray status as case complete.')
      }
    } catch (error) {
      console.error(error)
    }
  }, [assignTrayStatusAsCaseComplete, dtmTrayIds])

  const handleSelectScrubTechnician = (e: CustomChangeEvent) => {
    setSelectedScrubTechnician(e.target.value as Option)
    setError(undefined)
  }

  const handleSubmitToEmr = (e: any) => {
    e.preventDefault()
    setSelectedScrubTechnician(null)
    if (showScrubTechnician) {
      setOpenScrubTechModal(true)
      setEvent(e)
    } else {
      handleValidation(e)
    }
  }

  const handleCloseScrubTechModal = () => {
    setOpenScrubTechModal(false)
    setDisableSubmit(false)
    setIsSubmittedToEmr(false)
    setError(undefined)
  }

  const handleSubmitScrubTech = () => {
    if (!selectedScrubTechnician) {
      setError('Please select a scrub technician')
      return
    }

    setOpenScrubTechModal(false)
    handleValidation(event)
  }

  useEffect(() => {
    if (isFormSubmitted && isFormValidated && !isSubmittedToEmr) {
      handleUpdateTrayAssignment()
      handleSubmitRecord()
      setIsSubmittedToEmr(true)
    }
  }, [
    isFormSubmitted,
    isFormValidated,
    isSubmittedToEmr,
    handleSubmitRecord,
    handleUpdateTrayAssignment,
  ])

  return {
    error,
    loading,
    isMobile,
    scrubTechs,
    disableSubmit,
    isAdventistUser,
    numberOfExpired,
    openScrubTechModal,
    undocumentedScanCount,
    selectedScrubTechnician,
    setSurgeryStatusSubmittedMutation,
    refetch,
    handleSubmitToEmr,
    handleSubmitScrubTech,
    handleCloseScrubTechModal,
    handleSelectScrubTechnician,
    FormValidationAlertToasters,
  }
}
