import { Typography } from '@mui/material'
import { TransformWrapper } from 'react-zoom-pan-pinch'

import { LoadingButton } from 'components/mui'
import RemoveScrewModal from './Modals/RemoveScrewModal/RemoveScrewModal'
import ConfirmChangesModal from './Modals/ConfirmChangesModal/ConfirmChangesModal'
import ReloadScrewModal from './Modals/ReloadScrewModal/ReloadScrewModal'
import HelpModal from './Modals/HelpModal/HelpModal'

import useTrayVisualizationLogic from './TrayVisualization.logic'

import './TrayVisualization.scss'
import classNames from 'classnames'
import FullScreenDialog from 'components/organisms/FullScreenDialog/FullScreenDialog'
import TrayProcedureEditMode from 'components/organisms/TrayProcedureEditMode/TrayProcedureEditMode'
import TrayMapSelector from 'components/organisms/TrayMapSelector/TrayMapSelector'
import { BackOrButton } from 'components/BackOrButton'
import { ArrowBack } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

const TrayVisualization = () => {
  const { surgeryId } = useParams()
  const {
    selectedScrew,
    trayHeight,
    modalOpen,
    selectedStatus,
    setSelectedStatus,
    confirmChangesModalOpen,
    setConfirmChangesModalOpen,
    isConfirmScrewRemovalModalOpen,
    isHelpModalOpen,
    setIsHelpModalOpen,
    isScrewUsed,
    isScrewUsedAndWasted,
    isScrewLoadedFromSPD,
    handleStatusChange,
    handleConfirm,
    handleSaveChangesCancellation,
    handleReloadScrew,
    handleReloadScrewModalClose,
    handleHelpModalClose,
    handleConfirmChangesModalClose,
    handleRemoveScrewModalClose,
    isInEditMode,
    setIsInEditMode,
    trayType,
    setSelectedScrew,
    getInitialPlateCount,
    handlePlateUsage,
    mapContent,
    onClickActions,
    selectedPlate,
    handlePlateStatusChange,
    setSelectedPlate,
    setModalOpen,
    trayDetails,
  } = useTrayVisualizationLogic()

  if (!trayDetails) {
    return <div>Loading tray details...</div>
  }

  return (
    <div
      className={classNames('dtm-tray-visualization_container', {
        'with-plates': trayType !== 'stryker screw caddy',
      })}
      style={{ height: trayHeight }}
    >
      <div className="header-buttons">
        <BackOrButton
          variant="outlined"
          className="back_button"
          sx={{ width: 'auto' }}
          size="small"
          fallbackUrl={`/surgeries/${surgeryId}/assigned-digital-trays`}
          fallbackChildren={
            <>
              <ArrowBack sx={{ mr: 0.5 }} /> BACK
            </>
          }
        >
          <ArrowBack sx={{ mr: 0.5 }} /> BACK
        </BackOrButton>
        <button
          className="help-button"
          onClick={() => setIsHelpModalOpen(true)}
        >
          ?
        </button>
      </div>
      <Typography variant="h2">Confirm Utilized Hardware</Typography>

      <div className="tray-map_wrapper">
        {mapContent && (
          <TrayMapSelector
            trayType={trayType}
            mapContent={mapContent}
            isInOR={true}
            isScrewEnabled={isScrewLoadedFromSPD}
            isScrewImplanted={isScrewUsed}
            isScrewWasted={isScrewUsedAndWasted}
            hideButton={true}
          />
        )}
      </div>

      <div className="buttons-container">
        <div className="left-container">
          <LoadingButton
            disabled={false}
            loading={false}
            variant="outlined"
            onClick={() => setIsInEditMode(true)}
            className="view-modify-tray_button"
          >
            Modify Tray Content
          </LoadingButton>
        </div>

        <div className="right-container">
          <LoadingButton
            disabled={false}
            loading={false}
            variant="contained"
            onClick={() => setConfirmChangesModalOpen(true)}
          >
            Confirm
          </LoadingButton>
        </div>
      </div>

      <FullScreenDialog
        open={isInEditMode}
        onClose={() => setIsInEditMode(false)}
      >
        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
          pinch={{
            step: 10,
          }}
          wheel={{
            step: 10,
          }}
          smooth
          doubleClick={{
            disabled: true,
          }}
        >
          <TrayProcedureEditMode
            trayType={trayType}
            mapContent={mapContent}
            onClose={() => setIsInEditMode(false)}
            onClickActions={onClickActions}
            isScrewEnabled={isScrewLoadedFromSPD}
            isScrewImplanted={isScrewUsed}
            isScrewWasted={isScrewUsedAndWasted}
            handlePlateUsage={handlePlateUsage}
            getPlateCount={getInitialPlateCount}
            setSelectedPlate={setSelectedPlate}
            setModalOpen={setModalOpen}
            setSelectedScrew={setSelectedScrew}
          />
        </TransformWrapper>
      </FullScreenDialog>

      <RemoveScrewModal
        trayType={trayType}
        open={modalOpen}
        onClose={handleRemoveScrewModalClose}
        selectedScrew={selectedScrew}
        selectedPlate={selectedPlate}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleStatusChange={
          selectedPlate ? handlePlateStatusChange : handleStatusChange
        }
      />
      <ConfirmChangesModal
        open={confirmChangesModalOpen}
        onClose={handleConfirmChangesModalClose}
        handleConfirm={handleConfirm}
        handleSaveChangesCancellation={handleSaveChangesCancellation}
      />
      <ReloadScrewModal
        open={isConfirmScrewRemovalModalOpen}
        onClose={handleReloadScrewModalClose}
        selectedScrew={selectedScrew}
        handleReloadScrew={handleReloadScrew}
      />
      <HelpModal open={isHelpModalOpen} onClose={handleHelpModalClose} />
    </div>
  )
}

export default TrayVisualization
