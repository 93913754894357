// Services
import { useAdminNewPatient } from 'lib/services/api/reports-service/patients/admin-new-patients'

// Types
import { IAdmitNewPatient, IAdmitNewPatientForm } from './AdmitNewPatient.types'

// Other
import { useForm } from 'react-hook-form'
import { enqueueSnackbar } from 'notistack'
import moment from 'moment'

const useAdmitNewPatientLogic = ({
  handleAdmittingNewPatient,
}: IAdmitNewPatient) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IAdmitNewPatientForm>({
    defaultValues: {
      firstName: null,
      lastName: null,
      mrn: null,
      dateOfBirth: null,
    },
    mode: 'onChange',
  })

  const { mutateAsync: create, isLoading: isLoadingAdmitPatient } =
    useAdminNewPatient()

  const onSubmit = (data: IAdmitNewPatientForm) => {
    if (!data) return

    const { dateOfBirth, firstName, lastName, mrn, gender } = data

    create({
      firstName: firstName as string,
      lastName: lastName as string,
      mrn: mrn as string,
      dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD') as string,
      gender: gender?.id as string,
    })
      .then((res) => {
        handleAdmittingNewPatient(res)
        if (res) {
          enqueueSnackbar('New Patient admitted successfully.', {
            variant: 'success',
          })
        } else {
          enqueueSnackbar(
            'Something went wrong while admitting the patient, Please try again.',
            {
              variant: 'error',
            }
          )
        }
      })
      .catch((err: any) =>
        enqueueSnackbar(
          err.response?.data?.detail ||
            'Something went wrong while admitting the patient, Please try again.',
          {
            variant: 'error',
          }
        )
      )
  }

  const handleOnSubmit = () => {
    handleSubmit(onSubmit)()
  }

  return {
    errors,
    control,
    isLoadingAdmitPatient,
    handleOnSubmit,
  }
}

export default useAdmitNewPatientLogic
