import { ChangeEvent, FormEvent, useEffect, useState } from 'react'

// Components
import { SelectChangeEvent } from '@mui/material'

// Context
import { useImplantSites } from 'lib/context/ImplantSitesContext'
import { useUndocumentedAssetsContext } from 'lib/context/UndocumentedAssetsContext'

// Types
import {
  ValidationErrorMap,
  FormField,
  BatchDispositionModalLogicProps,
} from './BatchDispositionModal.types'

// Other
import { z } from 'zod'
import {
  DispositionData,
  ASSET_TYPES,
  DISPOSITIONS,
  WASTED_REASONS,
  EXPLANTED_REASONS,
} from 'common/disposition'
import { UndocumentedScan } from 'common/types'
import { useParams } from 'react-router-dom'
import { useEditTrayItemJSONDetailsFunction } from 'lib/apollo/hooks'
import { BetterIDTrayScrew } from 'views/SPDLayout/SPD/SPD.types'
import { getZodError } from 'common/utils'

export const initialBatchDispositionModalFormState = {
  assetTray: '',
  assetType: '',
  implantStatus: undefined,
  implantSite: '',
  wastedReason: undefined,
  siteLocation: undefined,
}

export const formatValidationErrors = (
  error: z.ZodError<DispositionData>
): ValidationErrorMap => {
  const formattedErrors = error.format()
  const errorMap: ValidationErrorMap = {}
  for (const field in formattedErrors) {
    if (field in initialBatchDispositionModalFormState) {
      const errorMessage = formattedErrors[field as FormField]?._errors?.[0]
      errorMap[field as FormField] = errorMessage
    }
  }
  return errorMap
}

export const useBatchDispositionModalLogic = ({
  activeTray,
  assetType,
  onSave,
  registerTrayScanHandler,
  isAssociatedProduct,
  setAssociatedModal,
  handleAssociatedSubmitInitial,
  dispositionStatus,
  isNonAssociatedHardwareSelected,
  selectedScans,
  isManualAddition,
}: BatchDispositionModalLogicProps) => {
  // --------------------  Implant Sites --------------------
  const { implantSites } = useImplantSites()
  const implantSiteList = implantSites.map((implantSite) => implantSite.name)

  // DTM update SPD data
  const { surgeryId } = useParams()
  const { undocumentedScans } = useUndocumentedAssetsContext()
  const { editTrayItemJSONDetails } = useEditTrayItemJSONDetailsFunction()

  // --------------------  Form Validation --------------------
  const [formState, setFormState] = useState<Partial<DispositionData>>({
    ...initialBatchDispositionModalFormState,
    implantStatus: 'IMPLANTED',
    assetType: assetType,
    assetTray: activeTray,
  })

  const [validationErrors, setValidationErrors] = useState<ValidationErrorMap>(
    {}
  )
  const isSiteLocationEnabled =
    (formState.implantStatus === 'IMPLANTED' ||
      formState.implantStatus === 'EXPLANTED') &&
    (formState.implantSite === 'Other' || formState.implantSite === 'Mouth')

  const dispositionSchema = z
    .object({
      assetTray: z.string().optional(),
      assetType: z.enum(ASSET_TYPES),
      implantStatus: z.enum(DISPOSITIONS).optional(),
      implantSite: z.string().optional(),
      siteLocation: z.string().optional().nullish(),
      wastedReason: z.enum(WASTED_REASONS).optional(),
      explantedReason: z.enum(EXPLANTED_REASONS).optional().nullish(),
      explantedReasonNote: z.string().optional().nullish(),
      count: z
        .string()
        .optional()
        .transform((val) => (val ? Number(val) : undefined)),
    })
    .superRefine((data, ctx) => {
      if (
        data.assetType !== 'consumable' &&
        isManualAddition &&
        data.implantStatus === 'EXPLANTED' &&
        (!data.explantedReason || data.explantedReason?.length < 1)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Explant reason cannot be empty',
          path: ['explantedReason'],
        })
      }
      if (
        data.assetType !== 'consumable' &&
        isManualAddition &&
        data.implantStatus === 'EXPLANTED' &&
        data.explantedReason === 'OTHER' &&
        (!data.explantedReasonNote || data.explantedReasonNote?.length < 1)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Other reason cannot be empty',
          path: ['explantedReasonNote'],
        })
      }
      if (
        data.assetType === 'consumable' &&
        (!data.count || data.count === 0)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            data.count === 0
              ? 'Number of Items used cannot be 0'
              : 'Number of Items used cannot be empty',
          path: ['count'],
        })
      }
      if (!data.assetType) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Product Type cannot be empty',
          path: ['assetType'],
        })
      }
      if (
        data.assetType !== 'consumable' &&
        (!data.implantStatus || data.implantStatus.length < 1)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Disposition cannot be empty',
          path: ['implantStatus'],
        })
      }
      if (
        data.assetType !== 'consumable' &&
        !data.implantStatus &&
        !data.implantSite
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Explant site cannot be empty',
          path: ['implantSite'],
        })
      }
      if (
        ((data.assetType !== 'consumable' &&
          data.implantStatus === 'IMPLANTED') ||
          data.implantStatus === 'EXPLANTED') &&
        !data.implantSite
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            data.implantStatus === 'IMPLANTED'
              ? 'Implant site cannot be empty'
              : 'Explant site cannot be empty',
          path: ['implantSite'],
        })
      }
      if (
        data.assetType !== 'consumable' &&
        data.implantStatus === 'WASTED' &&
        !data.wastedReason
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Wasted reason cannot be empty',
          path: ['wastedReason'],
        })
      }
      if (
        data.assetType !== 'consumable' &&
        (data.implantSite === 'Mouth' || data.implantSite === 'Other') &&
        !data.siteLocation
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            data.implantStatus === 'IMPLANTED'
              ? 'Implant location cannot be empty'
              : 'Explant location cannot be empty',
          path: ['siteLocation'],
        })
      }
    })

  // --------------------  Hooks --------------------
  useEffect(() => {
    handleChangeFormData({
      target: {
        name: 'assetType',
        value: assetType,
      },
    })
    handleChangeFormData({
      target: {
        name: 'implantStatus',
        value: 'IMPLANTED',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // should only run upon modal open or else it resets values on every render

  useEffect(() => {
    registerTrayScanHandler &&
      registerTrayScanHandler((scan) => {
        setFormState((prev) => ({
          ...prev,
          assetTray: scan,
        }))
      })
  }, [setFormState, registerTrayScanHandler])

  useEffect(() => {
    setValidationErrors({})
  }, [formState])

  useEffect(() => {
    if (isAssociatedProduct) {
      setFormState((prev) => ({
        ...prev,
        implantStatus: 'ASSOCIATED_ASSET',
      }))
    }
  }, [assetType, isAssociatedProduct])

  useEffect(() => {
    if (dispositionStatus) {
      setFormState((prevFormState) => ({
        ...prevFormState,
        implantStatus: dispositionStatus,
      }))
    }
  }, [dispositionStatus])

  // --------------------  Handles --------------------
  const handleChangeFormData = (
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string> | any
  ) => {
    const { name, value } = e.target

    if (
      formState.implantSite === 'Mouth' &&
      name === 'siteLocation' &&
      value.length > 4
    ) {
      return
    }

    const resetValues: Partial<DispositionData> = {}

    if (name === 'assetType') {
      resetValues.assetType = value
      resetValues.implantStatus = undefined
      resetValues.implantSite = undefined
      resetValues.wastedReason = undefined
      resetValues.explantedReason = null
      resetValues.explantedReasonNote = null
      resetValues.siteLocation = undefined
    }

    if (name === 'implantStatus') {
      resetValues.implantStatus = value
      resetValues.implantSite = undefined
      resetValues.wastedReason = undefined
      resetValues.explantedReason = null
      resetValues.explantedReasonNote = null
      resetValues.siteLocation = undefined
    }

    if (name === 'implantSite') {
      resetValues.implantSite = value
      resetValues.wastedReason = undefined
      if (!isManualAddition) {
        resetValues.explantedReason = null
        resetValues.explantedReasonNote = null
      }
      resetValues.siteLocation = undefined
    }

    if (name === 'explantedReason') {
      resetValues.explantedReason = value
      resetValues.explantedReasonNote = null
    }

    setFormState((prev: any) => ({
      ...prev,
      ...resetValues,
      [name]: value,
    }))
  }

  const handleAssociatedSubmit = (e: any) => {
    e.preventDefault()
    const result = dispositionSchema.safeParse(formState)

    if (isNonAssociatedHardwareSelected && handleAssociatedSubmitInitial) {
      setAssociatedModal && setAssociatedModal(false)
      handleAssociatedSubmitInitial(e)
    } else if (result.success) {
      onSave(result.data)
    }
  }

  const areThereDTMScrews = undocumentedScans.some((scan) => scan.isDTMScrew)

  const updateTray = async (trayId: string, screws: BetterIDTrayScrew[]) => {
    const input = {
      id: Number(trayId),
      productDetails: JSON.stringify({ screws }),
    }

    try {
      await editTrayItemJSONDetails(input)
    } catch (error) {
      console.error('Failed to edit the JSON data of the tray item', error)
    }
  }

  const groupByTrayId = (
    undocumentedScans: UndocumentedScan[]
  ): Map<string, BetterIDTrayScrew[]> => {
    const map = new Map<string, BetterIDTrayScrew[]>()

    const dtmScrewScans = undocumentedScans.filter(
      (scan) =>
        scan.isDTMScrew &&
        selectedScans?.some((selectedScan) => selectedScan._id === scan._id)
    )

    for (const scan of dtmScrewScans) {
      const trayId = scan.dtmScrewData?.trayId
      if (trayId) {
        const trayScrews = dtmScrewScans
          .filter(
            (scan) => scan.dtmScrewData && scan.dtmScrewData.trayId === trayId
          )
          .map((screwScan) => ({
            x: screwScan.dtmScrewData?.column as number,
            row: screwScan.dtmScrewData?.row as number,
            label: screwScan.dtmScrewData?.label as string,
            column: screwScan.dtmScrewData?.size as number,
          }))

        if (!map.has(trayId)) {
          map.set(trayId, [])
        }

        for (const screw of trayScrews) {
          map.get(trayId)!.push(screw)
        }
      }
    }
    return map
  }

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    e.preventDefault()

    const result = dispositionSchema.safeParse(formState)
    if (!result.success) {
      setValidationErrors(getZodError(result.error))
      return
    }

    if (surgeryId && areThereDTMScrews) {
      try {
        const mapOfTrayScrews = groupByTrayId(undocumentedScans)

        mapOfTrayScrews.forEach((trayScrews, trayId) =>
          updateTray(trayId, trayScrews)
        )
      } catch (error) {
        console.error('Failed to update SPD data', error)
      }
    }

    onSave(result.data)
  }

  return {
    formState,
    handleChangeFormData,
    handleSubmit,
    implantSiteList,
    handleAssociatedSubmit,
    isSiteLocationEnabled,
    validationErrors,
  }
}
